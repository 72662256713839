import * as BABYLON from 'babylonjs';
import {Manager} from '../Manager';
import * as Structure from '../Structure';
import {ViewElement} from '../ViewElement';
import ElementTools from '../../lib/ElementTools';
import {TheModal} from './TheModal';

export abstract class AbstractViewWithModal extends ViewElement {
	constructor(protected manager: Manager, element: HTMLElement) {
		super(manager, element);
		TheModal.getInstance(manager).add(this);
	}

	display(node: Structure.Node, manager: Manager, activeStyleClass: string) {
		super.display(node, manager, activeStyleClass);
		TheModal.getInstance(manager).display(node, manager, activeStyleClass);
	}

	hide(node: Structure.Node, manager: Manager, activeStyleClass: string) {
		TheModal.getInstance(manager).hide(node, manager, activeStyleClass, () => {
			// When the modal is done closing
			super.hide(node, manager, activeStyleClass);
		});
	}
}