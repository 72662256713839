import * as BABYLON from 'babylonjs';

/**
 * This class contains static easing 
 */
export default class AnimationEasing {
	private static _FastInMaxOut: BABYLON.EasingFunction;
	private static _MaxOut: BABYLON.EasingFunction;
	private static _Out: BABYLON.EasingFunction;
	private static _InOut: BABYLON.EasingFunction;
	private static _In: BABYLON.EasingFunction;

	static get Linear(): BABYLON.EasingFunction {
		return undefined;
	}

	static get FastInMaxOut(): BABYLON.EasingFunction {
		if (AnimationEasing._FastInMaxOut === undefined) {
			AnimationEasing._FastInMaxOut = new BABYLON.BezierCurveEase(0.001, 0.4, 0, 1); // TOOD First arg should be 0 but there is an error in BABYLON. Bug report: https://github.com/BabylonJS/Babylon.js/issues/2628
		}
		return AnimationEasing._FastInMaxOut;
	}

	static get MaxOut(): BABYLON.EasingFunction {
		if (AnimationEasing._MaxOut === undefined) {
			AnimationEasing._MaxOut = new BABYLON.BezierCurveEase(0.001, 0, 0, 1); // TOOD First arg should be 0 but there is an error in BABYLON. Bug report: https://github.com/BabylonJS/Babylon.js/issues/2628
		}
		return AnimationEasing._MaxOut;
	}

	static get Out(): BABYLON.EasingFunction {
		if (AnimationEasing._Out === undefined) {
			AnimationEasing._Out = new BABYLON.BezierCurveEase(0.4, 0, 0.2, 1);
		}
		return AnimationEasing._Out;
	}

	static get InOut(): BABYLON.EasingFunction {
		if (AnimationEasing._InOut === undefined) {
			AnimationEasing._InOut = new BABYLON.BezierCurveEase(0.8, 0, 0.2, 1);
		}
		return AnimationEasing._InOut;
	}

	static get In(): BABYLON.EasingFunction {
		if (AnimationEasing._In === undefined) {
			AnimationEasing._In = new BABYLON.BezierCurveEase(0.6, 0.0, 0.6, 1);
		}
		return AnimationEasing._In;
	}
}