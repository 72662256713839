import {Manager, ViewObserver, ViewEvent, ViewChangeEvent, DataLoadedEvent} from './Manager';
import {Node, Link} from './Structure';
import {ViewElement, Modal} from './ViewElement';
import GoogleAnalytics from '../lib/GoogleAnalytics';

export default class StateControl implements ViewObserver {
    private ignoreNext: boolean = false;

    private baseHref: string;

    constructor(private manager: Manager) {
        window.onpopstate = this.statePopped.bind(this);
        this.baseHref = (<HTMLBaseElement> document.getElementsByTagName("base")[0]).href;
    }

    loadState() {
        if (!this.enter(document.location.href)) {
            // Did not enter so go to root
            this.manager.enter(this.manager.universeNode);
        }
    }

	notify(event: ViewEvent): void {
        if (event instanceof ViewChangeEvent) {
            // View change
            let node: Node = event.entering;

            // Modals replace current history entry instead of making new one
            let replaceState: boolean = (node !== undefined && node.getView() instanceof Modal);

            // Store state change
            let url: string = this.getURL(node);
            const title: string = node.getLabel();
            this.storeState(url, title, replaceState);
		}
    }

    private statePopped(event: PopStateEvent) {
        let url: string = document.location.href;
        
        // TODO: Temp hack. When pushed back in browser would create new 
        //      history entry making it so you could not move forward
        this.ignoreNext = true;
        this.enter(url);
    }

    private storeState(url: string, title: string, replaceState: boolean = false) {
        GoogleAnalytics.trackPage(url, title);
        document.title = `Clearfield Connection - ${title}`;

        if (this.ignoreNext) {
            this.ignoreNext = false;
            return;
        }

        if (replaceState) {
            window.history.replaceState({}, title, url);
        } else {
            window.history.pushState({}, title, url);
        }
    }

    enter(url: string): boolean {
        let node: Node = this.getNode(url);
        if (node !== null) {
            this.manager.enter(node);
            return true;
        }
        return false;
    }

    getNode(url: string): Node {
        let hashIndex: number = url.indexOf('#');
        let hash: string;
        if (hashIndex !== -1) {
            hash = url.substring(hashIndex + 1);
            url = url.substring(0, hashIndex);
        }

        let queryIndex: number = url.indexOf('?');
        if (queryIndex !== -1) {
            url = url.substr(0, queryIndex);
        }

        let startIndex = url.indexOf(this.baseHref);
        if (startIndex === -1) {
            startIndex = 0;
        } else {
            startIndex = this.baseHref.length;
        }

        let suffixIndex = url.lastIndexOf('.html');
        if (suffixIndex !== -1) {
            url = url.substring(startIndex, suffixIndex);
        }

        let name = url.replace(/\//g, '.');
        
        // Find node for path part of url
        let node: Node = this.manager.getStructureNode(name);

        // Find child node based on hash
        if (hash !== undefined && node !== null) {

            let potentialChildNameEndsWith = hash;
            if (potentialChildNameEndsWith.length > 0) {
                if (potentialChildNameEndsWith[0] === '.') {
                    // This changes '.a' to 'a.b.c', where 'a.b' is name
                    potentialChildNameEndsWith = name + hash;
                }
                potentialChildNameEndsWith = ':' + potentialChildNameEndsWith;
            }

            for (let child of node.children) {
                let index = child.name.indexOf(potentialChildNameEndsWith);
                if (index !== -1 && index === child.name.length - potentialChildNameEndsWith.length) {
                    // Matches node name
                    node = child;
                    break;
                }

                let childView = child.getView();
                if (childView !== null && childView.getId() === hash) {
                    // Matches view name
                    node = child;
                    break;
                }
            }
        }

        return node;
    }

    getURL(node: Node) {
        let url: string;

        let name: string;
        let hash: string;
        const view = node.getView();
        if (view !== null && view.isPartial()) {
            name = node.parent.name;
            hash = view.getId();
        } else if (node instanceof Link) {
            name = node.parent.name;
            hash = node.name;

            // Links start with "Link:" or potentialy "Link:x" where 'x' is a number. We can remove this part.
            let colon = hash.lastIndexOf(':');
            hash = hash.substr(colon + 1);

            let indexOfParentInLink = hash.indexOf(name);
            if (indexOfParentInLink === 0 && hash[indexOfParentInLink + name.length] === '.') {
                // Because link starts with parent name in its name we can shorten the value and make it more user friendly
                hash = hash.substr(name.length);
            }
        } else {
            name = node.name;
        }

        url = name.replace(/\./g, '/');
        url += '.html';
        if (window.location.search !== undefined) {
            url += window.location.search;
        }
        if (hash !== undefined) {
            url += '#' + hash;
        }

        return url;
    }
}