
/**
 * This class contains static easing 
 */
export default class ElementTools {
	
	// TODO Capitalize static or not? Babylon does, but does Plaudit? I did in AnimationEasing.
	static isSVGElement(elem: Element) {
		return (elem instanceof SVGElement);
		/* TODO It appears this 'instanceof' check fails when used on an svg inside
		* of an <object />. activeItem.ownerSVGElement !== undefined may be a good
		* option... Or better check for Class instead
		*/
	}

	/* TODO: rename these and this class. Think of this as a mini-toolbox and make it so the array version of classlist editing can do single classes too. */
	static clearClassList(elem: Element) {
		let classList: DOMTokenList = elem.classList;
		let len: number = classList.length;
		if (len > 0) {
			let classArray = new Array(len);
			for (let i = 0; i < len; i++) {
				classArray[i] = classList[i];
			}
			ElementTools.removeClassList(elem, classArray);
		}
	}
	static removeClassList(elem: Element, classArray: string[]) {
		if (classArray !== undefined && classArray.length > 0) {
			let classList = elem.classList;
			classList.remove.apply(classList, classArray);
		}
	}

	static addClassList(elem: Element, classArray: string[]) {
		if (classArray !== undefined && classArray.length > 0) {
			var classList = elem.classList;
			classList.add.apply(classList, classArray);
		}
	}

	static setClassList(elem: Element, classArray: string[]) {
		ElementTools.clearClassList(elem);
		ElementTools.addClassList(elem, classArray);
	}

	/**
	 * Finds closest ancestor or self that matches selector string.
	 * TODO If we use sizzle we don't need this... wait don't we still?
	 * 		Anything else we don't need? ... But maybe get rid of or document
	 * 		that this does not use sizzle. Or just make it use sizzle.
	 */
	static findClosest(startElem: Element, selectorString: string) {
		let curElem: Element = startElem;
		do {
			if (curElem.matches(selectorString)) {
				return curElem
			}
		} while(curElem = curElem.parentNode as Element); // TODO It is probably possible to hit a Node that is not an Element. E.g. DocumentFragment
	}


	/**
	 * Removes all classes from elem that start with the namePrefix.
	 */
	static removePrefixedClasses(elem: Element, namePrefix: string) {
		var classList = elem.classList;
		var classArray = new Array(classList.length);
		for (var i = 0, len = classList.length; i < len; i++) {
			var className = classList[i];
			if (className.indexOf(namePrefix) === 0) {
				classArray.push(className);
			}
		}
		ElementTools.removeClassList(elem, classArray);
	}


	private static _transformStyleName: string;
	static getTransformStyleName(): string {
		if (ElementTools._transformStyleName === undefined) {
			ElementTools._transformStyleName = ElementTools.getVendorStyleName('transform');
		}
		return ElementTools._transformStyleName;
	}
	static setTransform(elem: Element, value: string): boolean {
		// TODO Something like https://github.com/epistemex/transformation-matrix-js  maybe a great addition. Looks like it is now here: https://github.com/leeoniya/transformation-matrix-js ... or http://glmatrix.net/  and https://developer.mozilla.org/en-US/docs/Web/API/DOMMatrix and http://paperjs.org/reference/matrix/
		if (elem !== null) {
			if (elem instanceof HTMLElement) {
				(elem as HTMLElement).style[ElementTools.getTransformStyleName()] = value;
			} else {
				elem.setAttribute('transform', value);
			}
			return true;
		}
		return false;
	}
	static getTransform(elem): boolean {
		// TODO This should be using getComputedStyle shouldn't it?
		if (elem !== null) {
			if (this.isSVGElement(elem)) {
				elem.getAttribute('transform');
			} else {
				return elem.style[ElementTools.getTransformStyleName()];
			}
			return true;
		}
		return false;
	}

	static getOffsetSum(elem: HTMLElement): {top: number, left: number} {
		var top = 0, left = 0, curElem = elem;
		while(curElem) {
			top = top + curElem.offsetTop;
			left = left + curElem.offsetLeft;
			curElem = curElem.offsetParent as HTMLElement; // TODO always an HTMLElement? Not necassarily.
		}
		return {top: top, left: left};
	};

	private static cssPrefixes: Array<string> = ['Webkit', 'Moz', 'ms'];
	private static  emptyStyle: CSSStyleDeclaration = document.createElement('div').style;
	static getVendorStyleName(name: string): string | null {
		// Shortcut for names that are not vendor prefixed
		if (name in ElementTools.emptyStyle) {
			return name;
		}

		// Check for vendor prefixed names
		let capName: string = name[0].toUpperCase() + name.slice(1),
			i: number = ElementTools.cssPrefixes.length,
			prefixedName: string;
		while (i--) {
			prefixedName = ElementTools.cssPrefixes[i] + capName;
			if (prefixedName in ElementTools.emptyStyle) {
				return prefixedName;
			}
		}
		
		// Can't find property
		return null;
	};
}

