//import * as BABYLON from 'babylonjs';
import {Manager, ViewObserver, ViewEvent, ViewChangeEvent, DataLoadedEvent} from './Manager';
import * as Structure from './Structure';
import {ViewElement, TwoWorld, World, Place, Product} from './ViewElement';
import NodeStructureHelper from './NodeStructureHelper';

export interface AutoTourConfig {
	enabled: boolean;
	idleDuration: number;
	progressEvery: number;
}
export class AutoTour {
	private on: boolean = false;
	private config: AutoTourConfig;

	private idleDurationForNext: number;
	private idleTimerId: number;
	private autoProgressTimerId: number;

	constructor(private manager: Manager, config: AutoTourConfig) {
		this.config = config;

		// Method called when user has not done anything for idleDuration
		this.autoProgress = this.autoProgress.bind(this);

		// Add listener that resets timer when user interacts
		// TODO: debounce
		this.onUserInteraction = this.onUserInteraction.bind(this);

		this.setOn(config.enabled);
	}

	isOn(): boolean {
		return this.on;
	}

	getIdleDuration(): number {
		return this.config.idleDuration;
	}

	setOn(on: boolean): boolean {
		let handler = this.onUserInteraction;

		if (on) {
			if (this.on === false) {
				// Start
				this.resetIdleTimer();

				window.addEventListener('mousemove', handler);
				window.addEventListener('keydown', handler);
				window.addEventListener('touchstart', handler);
				window.addEventListener('resize', handler);
			}
		} else {
			if (this.on === true) {
				// Stop
				this.cancelIdleTimer();
				this.cancelAutoProgress();

				window.removeEventListener('mousemove', handler);
				window.removeEventListener('keydown', handler);
				window.removeEventListener('touchstart', handler);
				window.removeEventListener('resize', handler);
			}
		}

		this.on = on;

		return on;
	}

	onUserInteraction(): void {
		this.cancelAutoProgress();
		this.resetIdleTimer();
	}

	cancelIdleTimer() {
		if (this.idleTimerId !== undefined) {
			window.clearTimeout(this.idleTimerId);
		}
	}
	/**
	 * Resets idle duration timer.
	 * @param idleDuration If a number is provided this duration will be used until we start autoprogress, after which point we will reset back to using the config's idle duration.
	 */
	resetIdleTimer(idleDuration?: number) {
		if (idleDuration !== undefined) {
			this.idleDurationForNext = idleDuration;
		}

		this.cancelIdleTimer();
		this.idleTimerId = window.setTimeout(this.autoProgress, this.idleDurationForNext !== undefined ? this.idleDurationForNext : this.config.idleDuration);
	}

	cancelAutoProgress() {
		if (this.autoProgressTimerId !== undefined) {
			window.clearTimeout(this.autoProgressTimerId);
		}
	}

	autoProgress() {
		this.idleDurationForNext = undefined;
		this.resetIdleTimer();

		// Move to next node
		const current: Structure.Node = this.manager.getCurrentNode();
		let next: Structure.Node;
		if (current !== undefined) {
			// Find next node
			next = NodeStructureHelper.DetermineNavigation(current).next;
		}
		if (next === undefined) {
			// If no next node then send to two world view
			next = this.manager.universeNode;
		}
		this.manager.enter(next, true);

		// Set timer to move on to next
		this.autoProgressTimerId = window.setTimeout(this.autoProgress, this.config.progressEvery);
	}
}