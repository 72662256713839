import * as BABYLON from 'babylonjs';
import AnimationEasing from './AnimationEasing';
import {TimelineContext} from './AnimationTimeline';
import {Property} from './properties/Property';
import {Target} from './targets/Target';

export {Property} from './properties/Property';
export {Target} from './targets/Target';


/**
 * Value
 * Must provide on of the following combinations:
 * <li>start and end - Will use values provided to animate from current to either start or end based on set direction
 * <li>end - Will animation to end form current position.
 * <li>delta - Will animation to start or end, but will detrmine start by using current value of the target when first animated, and the end being start plus deleta.
 */
export interface ValueKeys {
	delta?: any;
	start?: any;
	end?: any;
}

/* TODO, finish or not? I was going to use in the cassette animation to figure out direction to move toward camera and to get a certain distance form camera.
export class DynamicValueKeys implements ValueKeys {
	public delta: any;
	public start: any;
	public end: any;

	constructor(options?: {valueKeys?: ValueKeys, start: ()=> any, end: ()=> any}) {
		if (options !== undefined) {

			// valueKeys
			if (options.valueKeys !== undefined) {
				for (let key in options.valueKeys) {
					if (options.valueKeys.hasOwnProperty(key)) {
						this[key] = options.valueKeys[key];
					}
				}
			}

			TODO Use the start and end methods. How do I make this a generic too? And how would I specify the type to be the same as a Property class?
				This may not be needed. Instead, I think we should just put the cassette empty in an empty.


		}
	}
}
*/


/**
 * Version, Direction, and Position
 */
export enum Direction {
	toStart,
	toEnd
}
export enum PositionMode {
	startOrigin,
	endOrigin,
	difference
}
export interface Position {
	relativeToName?: string;
	percent?: number;		// Position relative to animation referenced by "relativeToName". From 0 to 1. Defaults to 0. Ths is ignored if relativeToName is not set.
	offset?: number;		// In ms. Defaults to 0. If relativeToName is undefined, then this will be relative to the 0 of the overall timeline.
	mode?: PositionMode;		// Default is PositionMode.startOrigin. Modes allow for positioning start to start of relative, end to end of relative or difference between the current duration and relatives duration (think CSS's background positioning works with percentages)
}
function clonePositionAndDefault(orgPos: Position): Position {
	if (orgPos === undefined) {
		return {
			relativeToName: undefined,
			percent:		0,
			offset:			0,
			mode:			PositionMode.startOrigin
		}
	}

	return {
		relativeToName: orgPos.relativeToName,
		percent:		orgPos.percent !== undefined ? orgPos.percent : 0,
		offset:			orgPos.offset !== undefined ? orgPos.offset : 0,
		mode:			orgPos.mode !== undefined ? orgPos.mode : PositionMode.startOrigin
	}
}
export interface Version {
	position?: Position;
}
export interface AnimationVersion extends Version {
	speed?: number | ((distance: number) => number);			// Multiplied by the distance to determine duration (caculated using Property.distanceFunction) moving to determine duration which will then be converted to number of frames.
	duration?: number | ((distance: number) => number);	
	minDuration?: number;
	maxDuration?: number;
	easing?: BABYLON.EasingFunction; // Defaults to AnimationEasing.out
	direction?: Direction;			// Defaults to Direction.toEnd
}

export class DynamicVersion implements AnimationVersion {
	public speed: number | ((distance: number) => number);
	public duration: number | ((distance: number) => number);
	public minDuration: number;
	public maxDuration: number;
	public easing: BABYLON.EasingFunction;
	public direction: Direction;
	public position: Position;

	private _update: (data: AnimationUpdateData) => boolean;

	constructor(version: AnimationVersion, update: (data: AnimationUpdateData) => boolean) {
		for (let key in version) {
			if (version.hasOwnProperty(key)) {
				this[key] = version[key];
			}
		}
		this._update = update;
	}

	/**
	 * Passed data after some information is caculated when the animations is setup.
	 * Method has the opportunity to make changes to the passed information.
	 * Changes are not permenante (e.g. if an instance is configured for Easing.Out and
	 * then during update switched to Easing.In, the next time update is called it
	 * will be Easing.Out)
	 * 
	 * @return
	 */
	public update(data: AnimationUpdateData): boolean {
		if (this._update !== undefined) {
			return this._update(data);
		}
		return true;
	}
}

export interface AnimationUpdateData {
	duration: number,
	easing: BABYLON.EasingFunction,
	position: Position,
	timelineContext: TimelineContext,
	distance: number,
};


/**
 * AbstractEntry
 */
export abstract class AbstractEntry {
	public static readonly DefaultValueName = "default";
	
	private _name: string;
	private versions: {[key: string]: Version} = {};
	private _activeVersionName: string;

	protected _timelineContext: TimelineContext;

	constructor(
		name: string,
		defaultVersion: Version
	) {
		this._name = name;
		this.addVersion(AbstractEntry.DefaultValueName, defaultVersion, true);
	}

	public addVersion(versionName: string, version: Version, makeActive: boolean = false): this {
		this.versions[versionName] = version;
		this.setActiveVersion(versionName);
		return this;
	}
	public setActiveVersion(versionName: string): this {
		this._activeVersionName = versionName;
		return this;
	}

	public get activeVersionName(): string {
		return this._activeVersionName;
	}

	public get name(): string {
		return this._name;
	}


	public get positionRelativeToName(): string {
		if (this.position === undefined) {
			return undefined;
		}
		return this.position.relativeToName;
	}

	public getActiveVersion(): Version {
		let version = this.versions[this.activeVersionName];
		if (version === undefined) {
			console.warn(`This does not contain a version for the current active version ("${this.activeVersionName}"). Returning default version.`, this);
			version = this.versions[AbstractEntry.DefaultValueName];
		}
		return version;
	}

	public get position(): Position {
		return this.getActiveVersion().position;
	}
	
	public abstract build(timelineContext?: TimelineContext): any;

	/**
	 * Returns information about the last built animation.
	 * You must call build() first.
	 */
	public getTimelineContext(): TimelineContext {
		return this._timelineContext;
	}


}


/**
 * Entry
 */
export class Entry extends AbstractEntry {
	private fps: number = 100;
	private cachedAnimation: BABYLON.Animation;

	private _target: Target;
	private _property: Property;
	private _valueKeys: ValueKeys;

	constructor(
		name: string,
		target: Target,
		property: Property,
		valueKeys: ValueKeys,
		defaultVersion: AnimationVersion
	) {
		super(name, defaultVersion);

		this._target = target;
		this._property = property;
		this._valueKeys = valueKeys;
	}
	
	/**
	 * Build Babylon.Animation based on this entry instance.
	 */
	public build(timelineContext?: TimelineContext): BABYLON.Animation {
		if (this.cachedAnimation === undefined) {
			this.cachedAnimation = new BABYLON.Animation(
				this.name,
				this._property.key, // <- Target Property (e.g. "rotation")
				this.fps,
				this._property.dataType,
				BABYLON.Animation.ANIMATIONLOOPMODE_CYCLE,
				false // <- Blending
			);
		}

		const {keys, easing} = this.createKeysAndEasing(timelineContext);
		this.cachedAnimation.setEasingFunction(easing);

		if (keys !== undefined) {
			this.cachedAnimation.setKeys(keys);
			return this.cachedAnimation;
		} else {
			// there is no animation so return undefined
			return undefined;
		}
	}

	public get targetProperty(): Property {
		return this._property;
	}

	public get targetObject(): any {
		return this._target.getObject();
	}
	public get targetName(): string {
		return this._target.getName();
	}

	public get target(): Target {
		return this._target;
	}

	public addVersion(versionName: string, version: AnimationVersion, makeActive: boolean = false): this {
		return super.addVersion(versionName, version, makeActive);
	}

	// Override
	public getActiveVersion(): AnimationVersion {
		return super.getActiveVersion();
	}

	/* ====== Private methods and properties ===== */
	private timeInMsToFrame(time: number): number {
		return (time / 1000) * this.fps;
	}

	private getSpeed(distance: number): number {
		const speed = this.getActiveVersion().speed;
		if (speed === undefined) {
			return undefined;
		}
		if (typeof speed === "function") {
			return speed(distance);
		}
		return speed;
	}
	private getDuration(distance: number): number {
		const duration = this.getActiveVersion().duration;
		if (duration === undefined) {
			return undefined;
		}
		if (typeof duration === "function") {
			return duration(distance);
		}
		return duration;
	}
	private get easing(): BABYLON.EasingFunction {
		const easing = this.getActiveVersion().easing;
		if (easing !== undefined) {
			return easing;
		}
		return AnimationEasing.Out;
	}
	private get direction(): Direction {
		return this.getActiveVersion().direction;
	}
	

	private get targetPropertiesCurrentValue(): any {
		return this._target.getPropertyValue(this._property);
	}

	private createKeysAndEasing(timelineContext?: TimelineContext): {keys: {frame: number, value: any}[], easing: BABYLON.EasingFunction} {
		this._target.init(this._property);
		this._property.init(this._valueKeys, this._target);
		
		// Determine start and end values
		const startValue = this.targetPropertiesCurrentValue;
		const endValue = this.direction === Direction.toStart ? this._valueKeys.start : this._valueKeys.end;

		// Update if Version is a DynamicVersion
		const activeVersion = this.getActiveVersion();
		const distance = this._property.calcDistance(startValue, endValue);

		let _duration = this.getDuration(distance);
		if (_duration === undefined) {
			_duration = distance / this.getSpeed(distance);
		}

		if (activeVersion.minDuration !== undefined && _duration < activeVersion.minDuration) {
			_duration = activeVersion.minDuration;
		}
		if (activeVersion.maxDuration !== undefined && _duration > activeVersion.maxDuration) {
			_duration = activeVersion.maxDuration;
		}

		let data: AnimationUpdateData = {
			duration: _duration,
			easing: this.easing,
			position: clonePositionAndDefault(this.position),
			timelineContext: timelineContext,
			distance: distance,
		};
		let shouldContinue: boolean = true;
		if (activeVersion instanceof DynamicVersion) {
			shouldContinue = activeVersion.update(data);
		}

		let keys: {frame: number, value: any}[];
		let startFrame: number;
		let endFrame: number;

		if (shouldContinue !== false) {

			// ---------------------- Working in Milleseconds -------------------------
			// Determine start time
			let startTime: number = 0;
			if (data.position !== undefined && timelineContext !== undefined && timelineContext.duration !== undefined) {
				if (data.position.mode === PositionMode.difference) {
					// Mode: Difference (think how CSS background images work)
					startTime = (timelineContext.duration - data.duration) * data.position.percent;
				} else if (data.position.mode === PositionMode.endOrigin) {
					// Mode: End as origin
					startTime = timelineContext.duration - (timelineContext.duration * data.position.percent) - data.duration;
				} else {
					// Mode: Start as origin
					startTime = timelineContext.duration * data.position.percent;
				}
			}

			// Add offset
			if (data.position !== undefined && data.position.offset !== undefined) {
				startTime += data.position.offset;
			}

			// ---------------------- Converting time to frames -------------------------
			// Caculate start frame and end frame
			const frameDelta = this.timeInMsToFrame(data.duration);
			startFrame = this.timeInMsToFrame(startTime);
			if (timelineContext !== undefined && timelineContext.lowFrame !== undefined) {
				startFrame += timelineContext.lowFrame;
			}
			if (startFrame < 0) {
				startFrame = 0;
				// console.warn(`Calculated start frame for "${this.name}" animation was less than 0. Value has been set to 0.`);
			}
			endFrame = startFrame + frameDelta;

			if (endFrame <= startFrame) {
				// NOTE: It is likely we should not be animating at all but because of limitations within BABYLON's JS code
				// we have no choice. If we give it one frame then BABYLON errors, and we also can't attach an animation
				// event unless there is an animation object.
				// In AnimationTimeline, I do exclude any animations that do not change the value or have an event attached.
				endFrame = startFrame + 1;
			}

			// Build keys object
			keys = [
				{frame: startFrame, value: startValue},
				{frame: endFrame, value: endValue}
			];

		
			// ---------------------- Save data and return -------------------------
			// Store for public reference
			this._timelineContext = {
				lowFrame: startFrame,
				highFrame: endFrame,
				duration: data.duration
			};

			return {
				keys: keys,
				easing: data.easing
			};

		} else {
			this._timelineContext = {
				lowFrame: undefined,
				highFrame: undefined,
				duration: 0
			};

			return {
				keys: undefined,
				easing: undefined
			};
		}


	}

}

/**
 * EventEntry
 */
export class EventEntry extends AbstractEntry {
	private cachedAnimationEvent: BABYLON.AnimationEvent;
	private _action: () => void;

	constructor(
		name: string,
		action: () => void,
		defaultVersion: Version
	) {
		super(name, defaultVersion);

		this._action = action;
	}

	/**
	 * Build Babylon.AnimationEvent based on this entry instance.
	 */
	public build(timelineContext?: TimelineContext): BABYLON.AnimationEvent {
		if (timelineContext === undefined || timelineContext.lowFrame === undefined || timelineContext.highFrame === undefined) {
			console.error("Can not build EventEntry for animation because no context was provided. In BABYLON the AnimationEvent but be attached to Animation so it must have a context to attach to.", this, timelineContext);
			return;
		}

		// Determine frame
		let frame: number;
		const timelineContextFrameTotal = timelineContext.highFrame - timelineContext.lowFrame;
		const percentOfContextFrames = this.position !== undefined && this.position.percent !== undefined ? 
			timelineContextFrameTotal * this.position.percent
				: 0;
		
		if (this.position === undefined || this.position.mode === undefined || this.position.mode === PositionMode.startOrigin) {
			// Mode: Start as origin
			frame = timelineContext.lowFrame + percentOfContextFrames;
		} else if (this.position.mode === PositionMode.difference) {
			// Mode: Difference. Same as start since an EventEntry as a 0 frame duration.
			frame = timelineContext.lowFrame + percentOfContextFrames;
		} else {
			// Mode: End as origin
			frame = timelineContext.highFrame - percentOfContextFrames;
		}

		// Add offset. Offset is in ms so timelineContext needs to have a duration greater than 0.
		if (
			this.position !== undefined && this.position.offset !== undefined
			&& timelineContext.duration !== undefined && timelineContext.duration !== 0
		) {
			const msPerFrame = timelineContext.duration / timelineContextFrameTotal;
			frame += this.position.offset / msPerFrame;
		}

		// Make sure not less than zero
		if (frame < timelineContext.lowFrame) {
			frame = timelineContext.lowFrame;
		} else if (frame > timelineContext.highFrame) {
			frame = timelineContext.highFrame;
		}

		// Build / update BABYLON.AnimationEvent
		if (this.cachedAnimationEvent === undefined) {
			this.cachedAnimationEvent = new BABYLON.AnimationEvent(
				frame,
				this._action,
				false	// execute only once
			);
		} else {
			this.cachedAnimationEvent.frame = frame;
			this.cachedAnimationEvent.action = this._action;
		}
		
		// Store for public reference
		this._timelineContext = {
			lowFrame: frame,
			highFrame: frame,
			duration: 0
		};

		return this.cachedAnimationEvent;
	}
}




