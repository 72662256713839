import * as BABYLON from 'babylonjs';
import {Manager} from '../Manager';
import * as Structure from '../Structure';
import {AbstractWorld} from './AbstractWorld';

export class TwoWorld extends AbstractWorld {
	protected _mesh: BABYLON.AbstractMesh;
	
	constructor(manager: Manager, element: HTMLElement) {
		super(manager, element);
	}

	protected getMeshMarker(): BABYLON.AbstractMesh {
		if (this._mesh === undefined) {
			this._mesh = this.manager.getScene().getMeshByName(this.element.id);
		}
		return this._mesh;
	}
}
