import * as BABYLON from 'babylonjs';
import {MeshTarget} from './MeshTarget';
import NodeStructureHelper from '../../view/NodeStructureHelper';


export class WorldAwareMeshTarget extends MeshTarget {
	private _worldName: string;

	constructor(meshName: string, scene: BABYLON.Scene) {
		super(meshName, scene);
		this._targetNode = undefined;
	}

	public setWorldName(worldName: string): void {
		this._targetNode = undefined;
		this._worldName = worldName;
	}

	public getObject(): BABYLON.AbstractMesh {
		if (this._targetNode === undefined) {
			const targetMesh = NodeStructureHelper.GetMesh(this._scene, this._worldName, this._nodeName);
			if (targetMesh !== undefined && targetMesh !== null) {
				this._targetNode = targetMesh;
			} else {
				console.warn(`Target mesh "${this._nodeName}" of world "${this._worldName}" for animation is not found is scene.`);
				return;
			}
		}
		return this._targetNode as BABYLON.AbstractMesh;
	}
}