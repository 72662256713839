import * as BABYLON from 'babylonjs';
import {AbstractNodeTarget} from './AbstractNodeTarget';


export class TargetCameraTarget extends AbstractNodeTarget {
	constructor(camera: BABYLON.TargetCamera);
	constructor(cameraName: string, scene: BABYLON.Scene);
	constructor(nameOrCamera: any, scene?: BABYLON.Scene) {
		super(nameOrCamera, scene);
	}
	public getObject(): BABYLON.TargetCamera {
		if (this._targetNode === undefined) {
			const targetMesh = this._scene.getCameraByName(this._nodeName);
			if (targetMesh !== undefined && targetMesh !== null) {
				this._targetNode = targetMesh;
				this._nodeName = undefined;
				this._scene = undefined;
			} else {
				console.warn(`Target camera "${this._nodeName}" for animation is not found is scene.`);
				return;
			}
		}
		return this._targetNode as BABYLON.TargetCamera;
	}
}

