import * as BABYLON from 'babylonjs';
import {AbstractNodeTarget} from './AbstractNodeTarget';


export class MeshTarget extends AbstractNodeTarget {
	constructor(mesh: BABYLON.AbstractMesh);
	constructor(meshName: string, scene: BABYLON.Scene);
	constructor(nameOrMesh: any, scene?: BABYLON.Scene) {
		super(nameOrMesh, scene);
	}

	public getObject(): BABYLON.AbstractMesh {
		if (this._targetNode === undefined) {
			const targetMesh = this._scene.getMeshByName(this._nodeName);
			if (targetMesh !== undefined && targetMesh !== null) {
				this._targetNode = targetMesh;
				this._nodeName = undefined;
				this._scene = undefined;
			} else {
				console.warn(`Target mesh "${this._nodeName}" for animation is not found is scene.`);
				return;
			}
		}
		return this._targetNode as BABYLON.AbstractMesh;
	}
}