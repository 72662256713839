import * as BABYLON from 'babylonjs';

/**
 * This class keeps the camera's rotation (in Euler) and rotationQuaternion in sync.
 */
export default class EnhancedFreeCamera extends BABYLON.FreeCamera {

	private _rotationEuler: BABYLON.Vector3;
	private _rotationEulerSynced: boolean;

	private _rotationQuaternion: BABYLON.Quaternion;
	private _rotationQuaternionSynced: boolean;
	
	// TODO This is not perfect because the objects for quanternion or euler can be modified  without the cache reset (e.g. rotation.x change would not clear cache). Could wrap I guess, or use a proxy but the support is not quite there (https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Proxy)

	set rotationQuaternion(quanternion: BABYLON.Quaternion) {
		this._rotationQuaternion = quanternion;
		this._rotationEulerSynced = false;
	}
	get rotationQuaternion(): BABYLON.Quaternion {
		if (this._rotationQuaternion === undefined) {
			this._rotationQuaternion = new BABYLON.Quaternion();
		}
		if (this._rotationQuaternionSynced !== true && this._rotationEuler !== undefined) {
			BABYLON.Quaternion.RotationYawPitchRollToRef(
				this._rotationEuler.y, this._rotationEuler.x, this._rotationEuler.z,
				this._rotationQuaternion
			);
			this._rotationQuaternionSynced = true;
		}
		return this._rotationQuaternion;
	}


	set rotation(rotation: BABYLON.Vector3) {
		this._rotationEuler = rotation;
		this._rotationQuaternionSynced = false;
	}
	get rotation(): BABYLON.Vector3 {
		if (this._rotationEuler === undefined) {
			this._rotationEuler = new BABYLON.Vector3(0, 0, 0);
		}
		if (this._rotationEulerSynced !== true && this._rotationQuaternion !== undefined) {
			this._rotationQuaternion.toEulerAnglesToRef(this._rotationEuler);
			this._rotationEulerSynced = true;
		}
		return this._rotationEuler;
	}


}