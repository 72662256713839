let windowQueryParameters: {[key: string]: string};

export default function GetQueryParameters(search?: string): {[key: string]: string} {
	if (search === undefined) {
		if (windowQueryParameters === undefined) {
			if (window.location.search === undefined) {
				windowQueryParameters = {};
			} else {
				windowQueryParameters = GetQueryParameters(window.location.search);
			}
		}

		return windowQueryParameters;
	} else {
		let params: {[key: string]: string} = {};

		if (search !== undefined && search.length !== 0) {
			let paramsArray = window.location.search.substr(1).split('&')

			for (let i = 0; i < paramsArray.length; i++) {
				let param = paramsArray[i].split('=', 2);
				if (param.length < 1)
					continue;
				params[decodeURIComponent(param[0])] = param.length === 1 ? '' : decodeURIComponent(param[1].replace(/\+/g, ' '));
			}
		}

		return params;
	}
}