import {Manager, ViewObserver, ViewEvent, ViewChangeEvent, DataLoadedEvent} from './Manager';
import {Node, Link} from './Structure';
import {ViewElement, World, TwoWorld} from './ViewElement';
import StateControl from './StateControl';
import {Clamp} from '../lib/NumberUtil';

export default class LocalNavigation implements ViewObserver {
    static containerStyleClass: string = 'localNavigation';
    static isChangingOpenStateStyleClass: string = 'localNavigation--isChangingOpenState';
    static isOpenStyleClass: string = 'localNavigation--isOpen';

    static logoStyleClass: string = 'clearfieldLogo';
    static headerStyleClass: string = 'localNavigation__header';
    static closeButtonStyleClass: string = 'localNavigation__close';
    static backButtonStyleClass: string = 'localNavigation__back';
    static backButtonLabelStyleClass: string = 'localNavigation__backLabel';
    static bodyStyleClass: string = 'localNavigation__body';

    private logo: HTMLAnchorElement;
    private menuContainer: HTMLDivElement;
    private bodyContainer: HTMLDivElement;
    private backButton: HTMLButtonElement;
    private backButtonSpan: HTMLSpanElement;

    private backToNode: Node;
    private nodeNameToHTMLElement: {[key: string]: HTMLElement};

    private _setBodyHeight_rAF: number;
    private scrollRafId: number;

    constructor(private manager: Manager, private stateControl: StateControl, private activeStyleClass: string,
        private ancestorOfActiveStyleClass: string,
        private autoCloseAtWidth: number
    ) {
        this.logoClickHandler = this.logoClickHandler.bind(this);
        this.backToClickHandler = this.backToClickHandler.bind(this);
        this.toggleBody = this.toggleBody.bind(this);
        this.smartSetBodyOpenState = this.smartSetBodyOpenState.bind(this);
    }

	notify(event: ViewEvent): void {
        if (event instanceof ViewChangeEvent) {
            let manager = event.manager;

            let leaving = event.leaving;
            let entering = event.entering;
            let enteringView: ViewElement = entering === null ? undefined : entering.getView();

            let isNavVisible = true;
            let isLinksVisible = true;
            let isLogoVisible = false;
            if (enteringView instanceof TwoWorld) {
                // Start
                isNavVisible = false;
                isLogoVisible = true;
                this.backToNode = undefined;
            } else if (enteringView instanceof World) {
                isLogoVisible = true;
                isLinksVisible = false;
                this.backButtonSpan.innerText = `Two City View`;
                this.backToNode = this.stateControl.getNode('universe');
            } else {

                this.backToNode = entering.parent;
                do {
                    if (this.backToNode.getView() instanceof World) {
                        this.backButtonSpan.innerText = `Full City View`;
                        break;
                    } else if (this.backToNode.parent.getView() instanceof World) {
                        this.backButtonSpan.innerText = `Back Out`;
                        break;
                    }

                    this.backToNode = this.backToNode.parent;
                } while (this.backToNode !== null || this.backToNode !== undefined);
            }

            this.logo.classList.toggle('clearfieldLogo--isVisible', isLogoVisible);
            this.menuContainer.classList.toggle('localNavigation--isVisible', isNavVisible);
            this.menuContainer.classList.toggle('localNavigation--hideLinks', !isLinksVisible);

            if (this.isBodyOpen() && isNavVisible && isLinksVisible) {
                // Call to trigger height of menu to be calculated. Need to weight one frame first so their is a height to measure.
                requestAnimationFrame(() => {
                    this.setBodyOpen(true);
                });
            }

            // Change current active node. Change style class
            this.setActiveNode(entering);

        } else if (event instanceof DataLoadedEvent) {
            this.create(event.manager);
        }
    }

    private logoClickHandler(event: MouseEvent) {
        event.preventDefault();
        this.manager.enter(this.manager.universeNode);
    }
    private backToClickHandler(event: MouseEvent) {
        event.preventDefault();

        if (this.backToNode !== undefined) {
            this.manager.enter(this.backToNode);
        }
    }

    private setActiveNode(node: Node) {
        let currentActiveNodesNodeList: NodeListOf<Element> = this.bodyContainer.querySelectorAll('.' + this.activeStyleClass + ', .' + this.ancestorOfActiveStyleClass);
        let currentActiveNodes: Element[] = [];
        for (let i=0; i < currentActiveNodesNodeList.length; i++) {
            currentActiveNodes.push(currentActiveNodesNodeList.item(i));
        }

        let current: HTMLElement;
        if (node !== undefined) {
            if (this.nodeNameToHTMLElement.hasOwnProperty(node.name)) {
                current = this.nodeNameToHTMLElement[node.name];
            }
            if (current === undefined && node instanceof Link) {
                let linkToNode = node.getLinkTo();
                current = this.nodeNameToHTMLElement[linkToNode.name];
            }
            if (current !== undefined) {
                current.classList.add(this.activeStyleClass);
                let index = currentActiveNodes.indexOf(current);
                if (index !== -1) {
                    currentActiveNodes.splice(index, 1);
                }

                // Add active class to ancestors
                let parent = current.parentElement;
                while (parent.tagName === 'LI' || parent.tagName === 'UL') {
                    if (parent.tagName === 'LI') {
                        parent.classList.add(this.ancestorOfActiveStyleClass);
                        parent.classList.remove(this.activeStyleClass);

                        index = currentActiveNodes.indexOf(parent);
                        if (index !== -1) {
                            currentActiveNodes.splice(index, 1);
                        }
                    }
                    parent = parent.parentElement;
                }
            }
        }

        // Remove active class from previous
        for (let i=0; i < currentActiveNodes.length; i++) {
            currentActiveNodes[i].classList.remove(this.activeStyleClass);
            currentActiveNodes[i].classList.remove(this.ancestorOfActiveStyleClass);
        }

        if (current !== undefined) {
            let anchor = current.querySelector('a');

            // Ensure node is within viewable area
            // If not in view scroll to it
            const containerTop = this.bodyContainer.offsetTop;
            const containerScroll = this.bodyContainer.scrollTop;
            const containerHeight = this.bodyContainer.clientHeight;

            const top = anchor.offsetTop - containerTop;
            const bottom = top + anchor.clientHeight;
            if (
                top < containerScroll // Above
                || bottom - containerScroll > containerHeight // Below
            ) {
                const timePerPixel = 3;
                const scrollTo = top - containerHeight / 2;
                const distance = scrollTo - containerScroll;
                const totalTime = Math.min(timePerPixel * Math.abs(distance), 1500);

                // Cancel any previous scroll
                if (this.scrollRafId !== undefined) {
                    window.cancelAnimationFrame(this.scrollRafId);
                }

                // Start scroll RaF
                let startTime: number;
                const rafScrollFunc = (timestamp) => {
                    if (startTime === undefined)
                        startTime = timestamp;

                    let percent = (timestamp - startTime) / totalTime;
                    if (percent > 1)
                        percent = 1;

                    const newScrollTop = containerScroll + (distance * percent);
                    this.bodyContainer.scrollTop = newScrollTop;

                    if (percent !== 1 && newScrollTop >= 0) {
                        this.scrollRafId = window.requestAnimationFrame(rafScrollFunc);
                    } else {
                        this.scrollRafId = undefined;
                    }
                };
                this.scrollRafId = window.requestAnimationFrame(rafScrollFunc);
            }
        }
    }


    private menuClick(event: MouseEvent) {
        let target: HTMLElement = <HTMLElement>event.target;
        while (target) {
            if (target instanceof HTMLAnchorElement)
                break;
            target = target.parentElement;
        }

        if (!(target instanceof HTMLAnchorElement))
            return;

        event.preventDefault();
        this.stateControl.enter(target.href);
    }

    create(manager: Manager) {
        this.nodeNameToHTMLElement = {};

        this.logo = document.querySelector('.' + LocalNavigation.logoStyleClass) as HTMLAnchorElement;
        // Disabled because client wants the URL to link to the homepage of their website. this.logo.addEventListener('click', this.logoClickHandler);

        this.backButton = document.querySelector('.' + LocalNavigation.backButtonStyleClass) as HTMLButtonElement;
        this.backButtonSpan = this.backButton.querySelector('.' + LocalNavigation.backButtonLabelStyleClass) as HTMLSpanElement;
        this.backButton.addEventListener('click', this.backToClickHandler);

        let closeButton = document.querySelector('.' + LocalNavigation.closeButtonStyleClass) as HTMLButtonElement;
        closeButton.addEventListener('click', this.toggleBody);

        // Menu items
        this.bodyContainer = document.querySelector('.' + LocalNavigation.bodyStyleClass) as HTMLDivElement;
        let menuUL: HTMLUListElement = this.createMenuFor(manager.universeNode);
        this.bodyContainer.appendChild(menuUL);

        // Store ref, add click event, and add to document
        this.menuContainer = document.querySelector('.' + LocalNavigation.containerStyleClass) as HTMLDivElement;
        this.menuContainer.addEventListener('click', this.menuClick.bind(this));

        // Clear height on body height change for collapse and expand. And set state to start.
        this.bodyContainer.addEventListener('transitionend', this.onBodyOpenTransitionEnd.bind(this), false);

        // Open/close menu
        this.smartSetBodyOpenState();
        window.addEventListener('resize', this.smartSetBodyOpenState); // TODO: debounce
    }

    smartSetBodyOpenState()  {
        const vpWidth = document.documentElement.clientWidth;
        if (vpWidth < this.autoCloseAtWidth) {
            // If screen small then close menu
            this.setBodyOpen(false);
        } else {
            this.setBodyOpen(true);
        }
    }
    createMenuFor(node: Node): HTMLUListElement {
        let menuUL = document.createElement('ul');
        this.addNodes(menuUL, node.children);
        return menuUL;
    }
    private addNodes(ul: HTMLUListElement, nodes: Node[]) {
        let includeAnnotationLabels: boolean;
        let showCategories: boolean = true;
        let categories:{[key: string]: Link[]} = {};
        for (let node of nodes) {
            if (!(node instanceof Link)) {
                continue;
            }
            let name = undefined;
            let view = node.getView();
            if (view !== undefined && view !== null) {
                name = view.getCategory();
            }
            if (name === undefined) {
                name = "Additional Products";
                showCategories = false;
            }

            if (categories[name] === undefined) {
                categories[name] = [node];
            } else {
                categories[name].push(node);
            }

            if (node.getAnnotationLabel() !== undefined) {
                includeAnnotationLabels = true;
            }
        }

        for (let category in categories) {
            if (showCategories) {
                // Include category headers only if all nodes are categorized.
                let li = document.createElement('li');
                li.classList.add('category');
                li.innerText = category;
                ul.appendChild(li);
            }

            let categoryNodes: Link[] = categories[category];
            for (let linkNode of categoryNodes) {
                let node = linkNode.getLinkTo();

                let li = document.createElement('li');
                li.classList.add('node');

                // TODO: This can be removed. Only have it here for debugging.
                li.setAttribute('data-name', linkNode.name);

                let view: ViewElement = node.getView();
                if (view !== null) {
                    let className: string = view.constructor['name'];
                    li.classList.add('node--to' + className);
                }

                let anchor = document.createElement('a');
                anchor.href = this.stateControl.getURL(node);
                li.appendChild(anchor);
                
                let label = linkNode.getLabel();
                if (includeAnnotationLabels) {
                    let annotationLabel = linkNode.getAnnotationLabel();
                    if (annotationLabel === undefined) {
                        annotationLabel = '+';
                    }

                    let labelSpan = document.createElement('span');
                    labelSpan.classList.add('node--annotationLabel');
                    labelSpan.innerText = annotationLabel;
                    anchor.appendChild(labelSpan);
                }

                let nameSpan = document.createElement('span');
                nameSpan.innerText = label;
                anchor.appendChild(nameSpan);

                if (node.children.length !== 0) {
                    let myUL = document.createElement('ul');
                    this.addNodes(myUL, node.children);
                    li.appendChild(myUL);
                }

                ul.appendChild(li);

                this.nodeNameToHTMLElement[node.name] = li;
            }
        }
    }

    private toggleBody(): void {
        this.setBodyOpen(!this.menuContainer.classList.contains(LocalNavigation.isOpenStyleClass));
    }
    private isBodyOpen(): boolean {
        return this.menuContainer.classList.contains(LocalNavigation.isOpenStyleClass);
    }
    public setBodyOpen(shouldOpen: boolean): void {
        if (shouldOpen === true) {
            this.menuContainer.classList.add(LocalNavigation.isOpenStyleClass);
            this.setBodyHeight(this.bodyContainer.scrollHeight);
        } else {
            this.menuContainer.classList.remove(LocalNavigation.isOpenStyleClass);
            this.setBodyHeight(0);
        }
    }
    private onBodyOpenTransitionEnd() {
        this.menuContainer.classList.remove(LocalNavigation.isChangingOpenStateStyleClass);
        if (this.bodyContainer.style.height !== '0px') {
            this.bodyContainer.style.height = null;
        }
    }

    private setBodyHeight(newHeight: number): void {
        let heightDelta = Math.abs(newHeight - this.bodyContainer.clientHeight);

        if (heightDelta !== 0) {
            this.menuContainer.classList.add(LocalNavigation.isChangingOpenStateStyleClass);

            // Set height to computed current height, because a height is needed to animate from
            const currentHeight = this.bodyContainer.offsetHeight;
            this.bodyContainer.style.height = currentHeight + 'px';

            // Set speed of transition (if support by browser, otherwise it will fallback to what is in the CSS)
            const speed = (newHeight > currentHeight) ? 1 : 0.5;
            const duration = Clamp(heightDelta * speed, 200, 500);
            this.bodyContainer.style.transitionDuration = duration + 'ms';

            // Update height
            cancelAnimationFrame(this._setBodyHeight_rAF);
            this._setBodyHeight_rAF = requestAnimationFrame(() => {
                this.bodyContainer.style.height = newHeight + 'px';
            });
        } else {
            // No change so call our transition end right away
            this.onBodyOpenTransitionEnd();
        }
    }
}