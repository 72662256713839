import * as BABYLON from 'babylonjs';
import {Manager} from '../Manager';
import * as Structure from '../Structure';
import {ViewElement, TwoWorld, World, Place, Product} from '../ViewElement';
import ElementTools from '../../lib/ElementTools';
import NodeStructureHelper from '../NodeStructureHelper';
import GetQueryParameters from '../../lib/GetQueryParameters';

/**
 * A single instance of this class is used by all instances of Modal.
 * This class is responsible for dealing with the single visual modeal we display
 * the information inside of.
 */
export class TheModal {
	static MinimizedStyleClass: string = 'modal--isMinimized';
	static MaximizingStyleClass: string = 'modal--isMaximizing';
	static MinimizingStyleClass: string = 'modal--isMinimizing';

	static ActiveClass: string = 'modal--isActive';

	private static instance: TheModal;

	private vpPadding: number = 20;
	private isActive: boolean = false;

	private modalElement: HTMLElement;
	private modalBodyElement: HTMLElement;
	private modalFooterElement: HTMLElement;

	private previousButton: HTMLButtonElement;
	private nextButton: HTMLButtonElement;
	
	private currentNode: Structure.Node;
	private previousNode: Structure.Node;
	private nextNode: Structure.Node;

	private defaultFirstLevelModalsClosed: boolean;
	private previousMinimizedState:{[key: string]: boolean} = {};

	private nextHideComplete: () => void;

	static getInstance(manager: Manager): TheModal {
		if (TheModal.instance === undefined) {
			// Single shared instance
			TheModal.instance = new TheModal(manager);
		}
		return TheModal.instance;
	}

	private constructor(private manager: Manager) {
		let pageHref = window.location.href;

		let params = GetQueryParameters();
		this.defaultFirstLevelModalsClosed = params.sales !== undefined;

		this.modalElement = document.getElementById('modal');
		this.modalBodyElement = this.modalElement.querySelector('.modal__body') as HTMLElement;
		this.modalFooterElement = this.modalElement.querySelector('.modal__footer') as HTMLElement;
		this.previousButton = this.modalElement.querySelector('.modal__previous') as HTMLButtonElement;
		this.nextButton = this.modalElement.querySelector('.modal__next') as HTMLButtonElement;

		// Hook up left and right buttons to send to previous and next modals
		let keyHandler = (event: KeyboardEvent) => {
			if (event.altKey === true || event.shiftKey === true || event.ctrlKey === true) {
				// Modifier key is down, do note navigate tour.
				return;
			}
			if (event.key === 'ArrowLeft' || event.keyCode == 37) {
				// left
				this.previous();
			} else if (event.key === 'ArrowRight' || event.keyCode == 39) {
				// right
				this.next();
			}
		};
		document.body.addEventListener('keyup', keyHandler);

		// Listener that calls hideNextComplete
		this.onModalTransitionEnd = this.onModalTransitionEnd.bind(this);
		this.modalElement.addEventListener('transitionend', this.onModalTransitionEnd);
		
		// Close when click 'X' or push esc
		let closerElement: HTMLAnchorElement = this.modalElement.querySelector('.modal__closer') as HTMLAnchorElement;
		closerElement.addEventListener('click', this.toggleClick.bind(this));
		window.addEventListener('keydown', this.onKeyDown.bind(this), false);
		
		// Prevoius and next buttons
		this.previousButton.addEventListener('click', this.previousButtonClick.bind(this));
		this.nextButton.addEventListener('click', this.nextButtonClick.bind(this));
	}

	private onModalTransitionEnd(event: TransitionEvent): void {
		if (event.target === this.modalElement) {
			this.cleanStyles();
			this.hideComplete();
		}
	}

	private cleanStyles(all: boolean = false): void {
		this.modalElement.classList.remove(TheModal.MinimizingStyleClass, TheModal.MaximizingStyleClass);
		if (all === true) {
			this.modalElement.classList.remove(TheModal.MinimizedStyleClass, TheModal.ActiveClass);
		}
	}

	add(view: ViewElement): void {
		// Move element inside of modal container div

		// Marks as being a modal
		(view as any)._isATheModal = true;

		this.getModalBodyElement().appendChild(view.element);
	}

	getModalBodyElement(): HTMLElement {
		return this.modalBodyElement;
	}

	display(node: Structure.Node, manager: Manager, activeStyleClass: string) {
		this.cleanStyles(true);

		// Ensure previous hide finishes. It won't get triggered when move
		// directly from one modal to another so we have to do this.
		this.hideComplete();

		this.currentNode = node;
		let view = node.getView();

		this.modalElement.classList.add(TheModal.ActiveClass);
		this.isActive = true;

		// Next / Previous Buttons
		this.previousNode = undefined;
		this.nextNode = undefined;
		let isPlaceModal: boolean = false;
		let nextVariation: string = undefined;
		if (view instanceof Place) {
			isPlaceModal = true;
		} else if (node.parent !== undefined) {
			let navResult = NodeStructureHelper.DetermineNavigation(node);

			let onNodeChildOfPlace = node.parent !== undefined && node.parent.getView() instanceof Place;
			if (onNodeChildOfPlace) {
				// On child of 'Place', so no previous link
				nextVariation = 'startTour';
				navResult.previous = undefined;
			} else if (navResult.next !== undefined && navResult.next.parent !== undefined
				&& (
					navResult.next.parent.getView() instanceof Place
					|| navResult.next.getView() instanceof Place
				)
			) {
				// On last node within a child of Place so
				//	change next to send back to Place
				nextVariation = 'backOut';
			
				// Make next send back to Place
				let parent = node.parent;
				while (parent !== undefined) {
					if (parent.getView() instanceof Place) {
						//nextVariation = 'backOut';
						navResult.next = parent;
						break;
					}
					parent = parent.parent;
				}
			}

			this.previousNode = navResult.previous;
			this.nextNode = navResult.next;

			// Very likely user will go to the next modal so let's tell it to prepare.
			// This way any lazy loaded images are loaded ahead of time.
			if (this.nextNode !== undefined) {
				let nextView = this.nextNode.getView();
				if (nextView !== null) {
					nextView.prepareToDisplay();
				}
			}
		}

		let isPreviousNodeDisabled = this.previousNode === undefined;
		let isNextNodeDisabled = this.nextNode === undefined;

		this.previousButton.classList.toggle('modal__previous--disabled', isPreviousNodeDisabled);
		this.nextButton.classList.toggle('modal__next--disabled', isNextNodeDisabled);

		this.nextButton.setAttribute('data-variation', nextVariation !== undefined ? nextVariation : '');

		this.modalFooterElement.classList.toggle('modal__footer--disabled',
			isNextNodeDisabled && isPreviousNodeDisabled
		);

		// Add class indicating if we should center the modal
		this.modalElement.classList.toggle('modal--center', isPlaceModal);

		// Restore previous state
		let prevMinState: boolean = this.previousMinimizedState[node.name];
		if (prevMinState !== undefined) {
			this.toggleMinimize(prevMinState);
		} else {
			if (this.isTrackState(node)) {
				prevMinState = this.defaultFirstLevelModalsClosed;
				this.toggleMinimize(prevMinState);
			} else if (this.isMinimized()) {
				this.maximize();
			}
		}
	}

	private isTrackState(node: Structure.Node): boolean {
		let firstLevelInsideWorld: boolean = (node.parent !== undefined && node.parent.getView() instanceof World);
		return firstLevelInsideWorld;
	}

	hide(node: Structure.Node, manager: Manager, activeStyleClass: string, onComplete?: () => void) {
		this.cleanStyles(true);

		if (this.currentNode !== undefined && this.currentNode.getView() instanceof Place) {
			// Minimize the place modal when leaving a section.
			this.minimize();
		}

		// Finish any previous hide that was in progress.
		this.hideComplete();

		// Set next hide
		this.nextHideComplete = onComplete;

		this.currentNode = undefined;
		this.modalElement.classList.remove(TheModal.ActiveClass);
		this.isActive = false;
	}

	private hideComplete() {
		if (this.nextHideComplete !== undefined) {
			this.nextHideComplete();
			this.nextHideComplete = undefined;
		}
	}

	private onKeyDown(event: KeyboardEvent) {
		if (this.currentNode === undefined)
			return;

		if (event.defaultPrevented) {
			return; // Should do nothing if the key event was already consumed.
		}
		if (event.key === 'Escape' || event.key === 'Esc' || event.keyCode === 27) {
			this.toggle(true);
		}
	}

	private toggleClick(event: MouseEvent) {
		event.preventDefault();
		this.toggle();
	}
	private toggle(forceClose?: boolean) {
		if (forceClose === true || !this.isMinimized()) {
			this.minimize();
		} else {
			this.maximize();
		}
	}

	public isMinimized(): boolean {
		return this.modalElement.classList.contains(TheModal.MinimizedStyleClass);
	}
	public toggleMinimize(minimize?: boolean) {
		if (minimize === undefined) {
			minimize = !this.isMinimized();
		}
		if (minimize) {
			this.minimize();
		} else {
			this.maximize();
		}
	}
	private trackIfMinimized(minimized: boolean) {
		if (this.isTrackState(this.currentNode)) {
			this.previousMinimizedState[this.currentNode.name] = minimized;
		}
	}
	public minimize() {
		this.modalElement.classList.add(TheModal.MinimizingStyleClass);
		this.modalElement.classList.add(TheModal.MinimizedStyleClass);
		this.trackIfMinimized(true);
	}
	public maximize() {
		if (this.isMinimized()) {
			this.modalElement.classList.add(TheModal.MaximizingStyleClass);
			this.modalElement.classList.remove(TheModal.MinimizedStyleClass);
		}
		this.trackIfMinimized(false);
	}

	private previousButtonClick(event: MouseEvent) {
		event.preventDefault();
		this.previous();
	}
	private previous() {
		if (this.previousNode !== undefined) {
			this.manager.enter(this.previousNode, true);
		}
	}
	private nextButtonClick(event: MouseEvent) {
		event.preventDefault();
		this.next();
	}
	private next() {
		if (this.nextNode !== undefined) {
			this.manager.enter(this.nextNode, true);
		}
	}
}