import {Manager, DataLoadedEvent} from './Manager';
import * as Structure from './Structure';
import * as BABYLON from 'babylonjs';
import * as ViewElement from './ViewElement';
import VisualControl from './VisualControl';

export class Loader {
	
	load(manager: Manager) : void {
		this.loadPartsFromHTML(manager);
		this.loadStructureFromModel(manager);
	}

	/**
	 * Create Parts by parsing the HTML of the page.
	 * 
	 * @param manager 
	 */
	private loadPartsFromHTML(manager: Manager): void {
		let layouts : NodeListOf<HTMLElement> = document.querySelectorAll('.part') as NodeListOf<HTMLElement>;
		for (let i=0; i < layouts.length; i++) {
			let layout: HTMLElement = layouts.item(i);
			let layoutType: String = layout.dataset.type;
			if (layoutType.length > 0) {
				let className = layoutType.charAt(0).toUpperCase() + layoutType.substring(1);
				
				new ViewElement[className](manager, layout);
			}
		}
	}

	/**
	 * Traverse Scene looking for structure data, most specificially Links, and creates
	 * a hierarchy out of it.
	 * 
	 * @param manager 
	 */
	private loadStructureFromModel(manager: Manager): void {
		let meshes: BABYLON.AbstractMesh[] = manager.getScene().meshes;
		let mesh;
		for (let i = 0; i < meshes.length; i++) {
			mesh = meshes[i];
			if (mesh.name !== undefined) {
				let node = manager.addStructureNode(mesh);
			}
		}
		this.hookupParents(manager);

		// Output structure, this is just for testing
		const DEBUG: boolean = false;
		if (DEBUG) {
			console.groupCollapsed('----------------- Structure -------------------')
			this.outputNode(manager.universeNode, 0);
			console.groupEnd();

			console.groupCollapsed('----------------- Modals that don\'t have cameras -------------------')
			this.cameraIssues(manager.universeNode);
			console.groupEnd();
		}
	}
	private hookupParents(manager: Manager): void {
		let nodes: {[key: string]: Structure.Node} = manager.getStructureNodes();

		for (let name in nodes) {
			let node = nodes[name];

			if (node === manager.universeNode) {
				// home does not have a parent
				continue;
			}

			// Ensure parent/child relationship setup correctly
			let parentNode: Structure.Node;
			if (node.mesh !== undefined) {
				let useNameAncestors: boolean = true;
				if (node.mesh.parent !== undefined && node.mesh.parent instanceof BABYLON.AbstractMesh) {
					// Get parent and/or create it
					parentNode = manager.addStructureNode(<BABYLON.AbstractMesh>node.mesh.parent, Structure.Node);

					if (parentNode !== null && parentNode !== undefined) {
						useNameAncestors = false;
					}
				}

				if (useNameAncestors) {
					// Determine structure based on name. e.g. for "sfh.cross-connect-cabinet" look for parent "sfh"
					if (!(node instanceof Structure.Link)) {
						let nameSelfToAncstores = new Structure.NameSelfToAncestors(node.name);
						parentNode = nameSelfToAncstores.run((name: string) => {
							if (name === node.name) {
								// Do not make self the parent
								return undefined;
							}
							return nodes[name];
						}, undefined);
					}

					if (parentNode === undefined) {
						// Parent should be root
						parentNode = manager.universeNode;
					}
				}
			}

			if (parentNode !== undefined && parentNode !== null) {
				parentNode.addChild(node);
			} else {
				console.error('Could not find a parent for: ', node.name, ' Even though its mesh does have a parent mesh: ', node.mesh.parent.name, ' Which means the parent does not have content.');
			}
		}
	}
	

	private outputNode(node: Structure.Node, depth: number): void {
		let dashes: string = '-';
		for (let i:number = depth; i > 0; i--) {
			dashes += '-';
		}
		dashes += ' ';

		if (depth <= 2) {
			console.group(dashes, node.getLabel(), ' = ', node.name);
		} else {
			console.log(dashes, node.getLabel(), ' = ', node.name);
		}

		depth++;
		for (let child of node.children) {
			this.outputNode(child, depth);
		}
		depth--;

		if (depth <= 2) {
			console.groupEnd();
		}
	}
	private cameraIssues(node: Structure.Node): void {
		let camera = VisualControl.getTargetCamera(node, false, false);
		if (camera === undefined) {
			let view = node.getView();
			if (view !== null && view.isPartial()) {
				console.log(node.name);
			}
		}

		for (let child of node.children) {
			this.cameraIssues(child);
		}
	}
}