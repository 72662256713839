import * as BABYLON from 'babylonjs';
import * as Structure from './Structure';
import NodeStructureHelper from './NodeStructureHelper';
import VisualControl from './VisualControl';

import AnimationTimeline from '../animation/AnimationTimeline';
import {AbstractEntry as AbstractAnimEntry, Entry as AnimEntry, EventEntry as AnimEventEntry, ValueKeys as AnimValueKeys, Position as AnimPosition, Direction as AnimDirection, PositionMode as AnimPositionMode, DynamicVersion as AnimDynamicVersion, AnimationVersion as AnimVersion, AnimationUpdateData as AnimUpdateData} from '../animation/AnimationEntry';
import * as MeshProperties from '../animation/properties/MeshProperties';
import {WorldAwareMeshTarget} from '../animation/targets/WorldAwareMeshTarget';
import AnimationEasing from '../animation/AnimationEasing';

interface EntryNode {
	animations?: AbstractAnimEntry[],
	meshesEnable?: {[key: string]: boolean}
	children?: {[key: string]: EntryNode}
}

export default class WorldAnimationData {
	private static AnimEntries: EntryNode;
	private static InternalMeshes: {[key: string]: BABYLON.AbstractMesh} = {};

	private static AnimationsPlayed: AbstractAnimEntry[] = [];
	private static MeshesEnableList: {[key: string]: {mesh: BABYLON.AbstractMesh, enable: boolean}} = {};

	private static AnimationInStateName: string = AbstractAnimEntry.DefaultValueName;
	public static AnimationOutStateName: string = "out";

	private static _inited: boolean = false;

	private static Init(scene: BABYLON.Scene): void {
		if (this._inited === true) {
			return
		}

		// =========================== Prepare animations ===========================
		// ------------------------- Area: SFH ------------------------
		const sfhGroundFieldsmartMakwa = AnimFactory.Ground("sfh-ground-cutaway.fieldsmart-makwa", scene);
		const sfhGroundCrossConnectCabinet = AnimFactory.Ground("sfh-ground-cutaway.cross-connect-cabinet", scene);
		const sfhGroundYourxPedestal = AnimFactory.Ground("sfh-ground-cutaway.yourx-pedestal", scene);
		const sfhGroundYourxTerminalVault = AnimFactory.Ground("sfh-ground-cutaway.yourx-terminal-vault", scene);
			
		const sfhHouseCutaway = AnimFactory.FadeAwayWall("sfh.house.cutaway", scene);
		const sfhHouse2Cutaway = AnimFactory.FadeAwayWall("sfh.house.cutaway.001", scene);

		// Ground Fieldsmartk Makwa (ground fades, equipment rotates up and wires on bottom down, and lid opens on case)
		const sfhFieldSmartMakwa: AnimEntry[] = [sfhGroundFieldsmartMakwa];
		const sfhFieldsmartMakwaVaultRotateUpAnimName = "sfh.makwa";
		const sfhFieldsmartMakwaVaultLidLeftOutStatePosition = {
				relativeToName: sfhFieldsmartMakwaVaultRotateUpAnimName,
				percent: 0.65,
			};
		const sfhFieldsmartMakwaVaultLidLeft: AnimEntry[] = AnimFactory.SlideGroundLidAndRotate(
			"sfh.fieldsmart-makwa.vault-lid", scene,
			new BABYLON.Vector3(0, 0, -3.5),
			undefined, // Rotate Delta
			undefined, // Duration
			sfhFieldsmartMakwaVaultLidLeftOutStatePosition
		);
		sfhFieldSmartMakwa.push(...sfhFieldsmartMakwaVaultLidLeft);

		const sfhFieldsmartMakwaVaultLidRight: AnimEntry[] = AnimFactory.SlideGroundLidAndRotate(
			"sfh.fieldsmart-makwa.vault-lid.001", scene,
			new BABYLON.Vector3(0, 0, 3.5),
			undefined, // Rotate Delta
			undefined, // Duration
			sfhFieldsmartMakwaVaultLidLeftOutStatePosition
		);
		sfhFieldSmartMakwa.push(...sfhFieldsmartMakwaVaultLidRight);
		
		const sfhFieldsmartMakwaVaultRotateUpSpeed = 1 / 5000;
		const sfhFieldsmartMakwaVaultRotateDownSpeed = 1 / 3000;
		const sfhFieldsmartMakwaVaultRotateUpEasing = AnimationEasing.Out;
		const sfhFieldsmartMakwaVaultRotateDownEasing = AnimationEasing.Out;
		const sfhFieldsmartMakwaVaultRotateUp =
		new AnimEntry(
			sfhFieldsmartMakwaVaultRotateUpAnimName,
			new WorldAwareMeshTarget("sfh.makwa", scene),
			MeshProperties.rotation,
			{delta: new BABYLON.Vector3(Math.PI * -0.5, 0, 0)},
			{
				speed: sfhFieldsmartMakwaVaultRotateUpSpeed,
				easing: sfhFieldsmartMakwaVaultRotateUpEasing,
				position: {
					relativeToName: sfhFieldsmartMakwaVaultLidLeft[0].name,
					percent: 0.5,
				}
			}
		).addVersion(WorldAnimationData.AnimationOutStateName, {
			direction: AnimDirection.toStart,
			speed: sfhFieldsmartMakwaVaultRotateDownSpeed,
			easing: sfhFieldsmartMakwaVaultRotateDownEasing,
			position: AnimFactory.CommonPositions.startCameraMove
		});
		sfhFieldSmartMakwa.push(sfhFieldsmartMakwaVaultRotateUp);
		
		const sfhFieldsmartMakwaUpWireDynVer = new AnimDynamicVersion(
			{	// Version (default)
				speed: 1, // Temp
				easing: sfhFieldsmartMakwaVaultRotateUpEasing,
				position: {
					relativeToName: sfhFieldsmartMakwaVaultRotateUp.name,
					percent: 0,
				}
			}, (data: AnimUpdateData): boolean => {
				data.duration = data.timelineContext.duration;
				return true;
			}
		);
		const sfhFieldsmartMakwaDownWireDynVer = new AnimDynamicVersion(
			{	// Version (default)
				speed: 1, // Temp
				easing: sfhFieldsmartMakwaVaultRotateDownEasing,
				position: {
					relativeToName: sfhFieldsmartMakwaVaultRotateUp.name,
					percent: 0,
				}
			}, (data: AnimUpdateData): boolean => {
				data.duration = data.timelineContext.duration;
				return true;
			}
		);
		["sfh.makwa.bottomWire1-BezierCurve", "sfh.makwa.bottomWire2-BezierCurve"].forEach((name: string) => {
			const wireAnim = new AnimEntry(
				name + ".rotation",
				new WorldAwareMeshTarget(name, scene),
				MeshProperties.rotationQuaternion,
				{end: new BABYLON.Quaternion(0,0,0,1)}, // ValueKeys
				sfhFieldsmartMakwaUpWireDynVer // default version
			).addVersion(
				WorldAnimationData.AnimationOutStateName,
				sfhFieldsmartMakwaDownWireDynVer
			);
			sfhFieldSmartMakwa.push(wireAnim);
		});


		const sfhYourXTapLidRemovalLidMove: BABYLON.Vector3 = new BABYLON.Vector3(-1.5, 0, 0.1);
		const sfhYourXTapLidRemovalSpeed: number = Math.abs(sfhYourXTapLidRemovalLidMove.length()) / 1200;
		const sfhYourXTapLidRemoval: AnimEntry = 
			new AnimEntry(
				"sfh.craftsmart-tap.lid",
				new WorldAwareMeshTarget("sfh.craftsmart-tap.lid", scene),
				MeshProperties.position,
				{delta: sfhYourXTapLidRemovalLidMove},
				{
					speed: sfhYourXTapLidRemovalSpeed,
					easing: AnimationEasing.InOut,
					position: AnimFactory.CommonPositions.afterCameraMove
				}
			).addVersion(WorldAnimationData.AnimationOutStateName, {
				direction: AnimDirection.toStart,
				speed: sfhYourXTapLidRemovalSpeed * 1.5,
				easing: AnimationEasing.Out,
				position: AnimFactory.CommonPositions.startCameraMove
			});

		const sfhCraftsmartTapLidPositionMove: BABYLON.Vector3 = new BABYLON.Vector3(1.5, 0, 1);
		const sfhCraftsmartTapLidPositionSpeed: number = Math.abs(sfhCraftsmartTapLidPositionMove.length()) / 1200;
		const sfhCraftsmartTapLidPositionName = "sfh.fieldshield-yourx-tap.lid.position";
		const sfhCraftsmartTapLidPosition: AnimEntry = 
			new AnimEntry(
				sfhCraftsmartTapLidPositionName,
				new WorldAwareMeshTarget("sfh.fieldshield-yourx-tap.lid", scene),
				MeshProperties.position,
				{delta: sfhCraftsmartTapLidPositionMove},
				{
					speed: sfhCraftsmartTapLidPositionSpeed,
					easing: AnimationEasing.InOut,
					position: AnimFactory.CommonPositions.afterCameraMove
				}
			).addVersion(WorldAnimationData.AnimationOutStateName, {
				direction: AnimDirection.toStart,
				speed: sfhCraftsmartTapLidPositionSpeed * 1.5,
				easing: AnimationEasing.Out,
				position: AnimFactory.CommonPositions.startCameraMove
			});

		const sfhCraftsmartTapLidrotationVersion_default = new AnimDynamicVersion(
				{
					speed: 1, // This is overriden by the update() method passed below
					easing: AnimationEasing.InOut,
					position: { relativeToName: sfhCraftsmartTapLidPositionName }
				},
				(data: AnimUpdateData): boolean => {
					data.duration = data.timelineContext.duration; // Match the duration of the position move.
					return true;
				}
			);
		const sfhCraftsmartTapLidRotation: AnimEntry = 
			new AnimEntry(
				"sfh.fieldshield-yourx-tap.lid.rotation",
				new WorldAwareMeshTarget("sfh.fieldshield-yourx-tap.lid", scene),
				MeshProperties.rotation,
				{delta: new BABYLON.Vector3(0, (Math.PI * 0.3), 0)},
				sfhCraftsmartTapLidrotationVersion_default
			).addVersion(WorldAnimationData.AnimationOutStateName, {
				direction: AnimDirection.toStart,
				speed: 1 / 3000,
				easing: AnimationEasing.Out,
				position: AnimFactory.CommonPositions.startCameraMove
			});


		// Cassettes
		const sfhCabinetPonBlueCassette: AnimEntry[] = AnimFactory.CassetteRemoval(
				"sfh.cabinet-pon.blue",
				"sfh.cabinet-pon.blue.lid",
				scene
			);
			
		const sfhFieldsmartMakwaBlackCassette: AnimEntry[] = AnimFactory.CassetteRemoval(
				"sfh.fieldsmart-makwa.black-cassette",
				"sfh.fieldsmart-makwa.black-cassette.lid",
				scene,
				new BABYLON.Vector3(-0.11, 0, 0), // positionDelta (back-front, to side, up-down)
				new BABYLON.Vector3(0, Math.PI * -0.4, 0), // rotationDelta
				new BABYLON.Vector3(0.009, 0, 0), // lidPositionDelta
				new BABYLON.Vector3(0, 0.2, Math.PI * -0.33), // lidRotationDelta
				undefined, // rotateAfterPosition
				undefined, // cassetteRemovalPositionName
				undefined, // anims
				{	// positionAnimPosition
					relativeToName: sfhFieldsmartMakwaVaultRotateUpAnimName,
					percent: 0.8
				}
			);
		
		const sfhCrossConnectCabinetCassetteBlue: AnimEntry[] = AnimFactory.CassetteRemoval(
				"sfh.cross-connect-cabinet.blue",
				"sfh.cross-connect-cabinet.blue.lid",
				scene,
				new BABYLON.Vector3(0, 0, 0.07), // positionDelta
				new BABYLON.Vector3(Math.PI * 0.5, 0, 0), // rotationDelta
			);

		const sfhGroundAreasFadeOut: AnimEntry[] = AnimFactory.FadeAwayChildren("sfh-ground-areas", scene);


		// ------------------------- Area: MDU ------------------------
		const mduGround = AnimFactory.Ground("mdu-ground-cutaway", scene, -0.042);
		const mduApartmentCutaway = AnimFactory.FadeAwayWall("apartment.cutaway", scene);
		const mduApartmentBackwallCutaway = AnimFactory.FadeAwayWall("MDU.apartment.wall-wire.cutaway", scene,{
			relativeToName: mduApartmentCutaway.name,
			percent: 0.75
		});

		// Cassette: Indoor 26 Blue Mpo
		const mduFdpIndoor36BlueMpoCassette: AnimEntry[] = [];
		const mduFdpIndoor36BlueMpoCassetteCaseRotateDegree: number = 0 - Math.PI * (1 / 5);
		const mduFdpIndoor36BlueMpoCassetteRemovalName: string = "mdu.fdp-indoor-36.blue-mpo.cassetteRemovalPosition"
		AnimFactory.CassetteCaseRotate(
			"mdu.fdp-indoor-36.hinge",
			scene,
			new BABYLON.Vector3(0, mduFdpIndoor36BlueMpoCassetteCaseRotateDegree, 0),
			mduFdpIndoor36BlueMpoCassetteRemovalName,
			mduFdpIndoor36BlueMpoCassette,
		);
		AnimFactory.CassetteRemoval(
			"mdu.fdp-indoor-36.blue-mpo",
			"mdu.fdp-indoor-36.blue-mpo.lid",
			scene,
			new BABYLON.Vector3(0, 0, -5.5),
			new BABYLON.Vector3(Math.PI * 0.5, Math.PI, mduFdpIndoor36BlueMpoCassetteCaseRotateDegree),
			undefined, // lidPositionDelta
			undefined, // lidRotationDelta
			true, // rotateAfterPosition
			mduFdpIndoor36BlueMpoCassetteRemovalName,
			mduFdpIndoor36BlueMpoCassette,
			{
				relativeToName: mduFdpIndoor36BlueMpoCassette[0].name, // Rotation of case
				percent: 0.75,
				offset: 0
			}
		);

		// Next two are in the same case.
		const mduFdpIndoor144CassettesCaseRotateDegree: number = 0 - Math.PI * (1 / 5);
		const mduFdpIndoor144CassettesValueKeys: AnimValueKeys = {delta: new BABYLON.Vector3(0, mduFdpIndoor144CassettesCaseRotateDegree, 0)};

		// Cassette: Indoor 144 Blue
		const mduFdpIndoor144BlueCassette: AnimEntry[] = [];
		const mduFdpIndoor144BlueCassetteRemovalName: string = "mdu.fdp-indoor-144.blue.cassetteRemovalPosition"
		AnimFactory.CassetteCaseRotate(
			"mdu.fdp-indoor-144.hinge",
			scene,
			mduFdpIndoor144CassettesValueKeys,
			mduFdpIndoor144BlueCassetteRemovalName,
			mduFdpIndoor144BlueCassette,
		);
		AnimFactory.CassetteRemoval(
			"mdu.fdp-indoor-144.blue",
			"mdu.fdp-indoor-144.blue.lid",
			scene,
			new BABYLON.Vector3(0, 0, -4),
			new BABYLON.Vector3(Math.PI * 0.5, 0, mduFdpIndoor144CassettesCaseRotateDegree),
			new BABYLON.Vector3(0.012, 0.028, 0),
			undefined, // lidRotationDelta
			true, // rotateAfterPosition
			mduFdpIndoor144BlueCassetteRemovalName,
			mduFdpIndoor144BlueCassette,
			{
				relativeToName: mduFdpIndoor144BlueCassette[0].name, // Rotation of case
				percent: 0.75,
				offset: 0
			}
		);

		// Cassette: Indoor 144 Blue Mpo
		const mduFdpIndoor144BlueMpoCassette: AnimEntry[] = [];
		// TODO The hinge animation is the same as above.. CAUSING ISSUE! COmpare code and make sure there are no other issues first.
		const mduFdpIndoor144BlueMpoCassetteRemovalName: string = "mdu.fdp-indoor-144.blue-mpo.cassetteRemovalPosition"
		AnimFactory.CassetteCaseRotate(
			"mdu.fdp-indoor-144.hinge",
			scene,
			mduFdpIndoor144CassettesValueKeys,
			mduFdpIndoor144BlueMpoCassetteRemovalName,
			mduFdpIndoor144BlueMpoCassette,
		);
		AnimFactory.CassetteRemoval(
			"mdu.fdp-indoor-144.blue-mpo",
			"mdu.fdp-indoor-144.blue-mpo.lid",
			scene,
			new BABYLON.Vector3(0, 0, -4),
			new BABYLON.Vector3(Math.PI * 0.5, 0, mduFdpIndoor144CassettesCaseRotateDegree),
			new BABYLON.Vector3(0.012, 0.028, 0),
			undefined, // lidRotationDelta
			true, // rotateAfterPosition
			mduFdpIndoor144BlueMpoCassetteRemovalName,
			mduFdpIndoor144BlueMpoCassette,
			{
				relativeToName: mduFdpIndoor144BlueMpoCassette[0].name, // Rotation of case
				percent: 0.75,
				offset: 0
			}
		);
		
		// Cassette: MDU Cabinet Pon Blue
		const mduCabinetPonBlueCassette: AnimEntry[] = AnimFactory.CassetteRemoval(
				"mdu.cabinet-pon.blue",
				"mdu.cabinet-pon.blue.lid",
				scene
			);
		


		// ------------------------- Area: CO ------------------------
		const coCutaway: AnimEntry = AnimFactory.FadeAwayWall("co.cutaway", scene);

		// Cassette: co.fieldsmart-standard.blue
		const coFieldsmartStandardBlueCassette: AnimEntry[] = AnimFactory.CassetteRemoval(
				"co.fieldsmart-standard.blue",
				"co.fieldsmart-standard.blue.lid",
				scene,
				// Axises of this one are setup wrong so I'm just modifying movement a little
				new BABYLON.Vector3(0, 0, 0.11),
			);

		// Cassette: co.fxds-frame.blue
		const coFxdsFrameBlueCassette: AnimEntry[] = AnimFactory.CassetteRemoval(
				"co.fxds-frame.blue",
				"co.fxds-frame.blue.lid",
				scene
			);

		// Cassette: co.fxhd-frame.blue
		const coFxhdFrameBlueCassette: AnimEntry[] = AnimFactory.CassetteRemoval(
				"co.fxhd-frame.blue",
				"co.fxhd-frame.blue.lid",
				scene,
				new BABYLON.Vector3(-0.002, 0.005, 0.1), // postion delta
				new BABYLON.Vector3(Math.PI * 0.5, 0, 0), // rotation delta
				new BABYLON.Vector3(-0.014, 0.01, 0), // lid position delta
				new BABYLON.Vector3(0, -0.2, Math.PI * 0.25), // lidRotationDelta
			);


		// ------------------------- Area: BC ------------------------
		const bcCutaway: AnimEntry = AnimFactory.FadeAwayWall("business.cutaway", scene);
		const bcCutaway2: AnimEntry = AnimFactory.FadeAwayWall("business.cutaway2", scene);
		
		const bcGroundAreasFadeOut: AnimEntry[] = AnimFactory.FadeAwayChildren("bc-ground-areas", scene);

		// Cassettes
		const bcFieldsmartStandardBlue: AnimEntry[] = AnimFactory.CassetteRemoval(
				"bc.fieldsmart-standard.blue",
				"bc.fieldsmart-standard.blue.lid",
				scene,
			);

		
		const bcCabinetPonBlue: AnimEntry[] = AnimFactory.CassetteRemoval(
				"bc.cabinet-pon.blue",
				"bc.cabinet-pon.blue.lid",
				scene,
				new BABYLON.Vector3(-0.005, 0, 0.04) // positionDelta
			);


		// ------------------------- Area: CT ------------------------
		// Cassettes
		const ctFieldsmartBlueMpoCassette: AnimEntry[] = AnimFactory.CassetteRemoval(
				"ct.fieldsmart.blue-mpo",
				"ct.fieldsmart.blue-mpo.lid",
				scene,
			);

		const ctFieldsmartBlueCassette: AnimEntry[] = AnimFactory.CassetteRemoval(
				"ct.fieldsmart.blue",
				"ct.fieldsmart.blue.lid",
				scene,
			);
		
		const ctCranFrameBlue: AnimEntry[] = AnimFactory.CassetteRemoval(
				"ct.cran-frame.blue",
				"ct.cran-frame.blue.lid",
				scene,
				new BABYLON.Vector3(0, 0, 0.05), // postion delta
				new BABYLON.Vector3(Math.PI * 0.5, 0, 0), // rotation delta
			);

		const ctCranFrameBlueMpo: AnimEntry[] = AnimFactory.CassetteRemoval(
				"ct.cran-frame.blue-mpo",
				"ct.cran-frame.blue-mpo-lid",
				scene,
				new BABYLON.Vector3(0, 0, 0.05), // postion delta
				new BABYLON.Vector3(Math.PI * 0.5, 0, 0), // rotation delta
			);

		const ctGroundAreasFadeOut: AnimEntry[] = AnimFactory.FadeAwayChildren("ct-ground-areas", scene);

		// ------------------------- Area: HFC ------------------------
		// Cassettes
		const hfcHccCassette: AnimEntry[] = AnimFactory.CassetteRemoval(
				"hfc.hcc.blue",
				"hfc.hcc.blue.lid",
				scene,
			);
		
		

		
		// =========================== Create list of animations ===========================
		this.AnimEntries = {
			meshesEnable: {
				// "ground": false,
				// CO
				// "co": false,
				"co-internal": false,
				"Cube.4262": false,
				"Cube.4267": false,
				"Cube.4268": false,

				// MDU
				// "mdu": false,
				"mdu-internal": false,
				"mdu.fdp-indoor-144.hinge": false,
				"mdu.fdp-indoor-36.hinge": false,

				// HFC
				// "hfc": false,
				"hfc-internal": false,
				"BezierCurve.973": false,
				"BezierCurve.2065": false,
				"BezierCurve.2066": false,
				"BezierCurve.2064": false,
				"BezierCurve.1025": false,
				"BezierCurve.986": false,
				"BezierCurve.975": false,
				"Cylinder.143": false,
				"BezierCurve.987": false,
				"Plane.059": false,
				"BezierCurve.988": false,
				"Cube.3161": false,
				"BezierCurve.2068": false,
				"BezierCurve.2067": false,
				"BezierCurve.2069": false,
				"BezierCurve.2070": false,
				"BezierCurve.2073": false,
				"BezierCurve.2074": false,
				"BezierCurve.2072": false,
				"BezierCurve.2071": false,

				// CT
				"ct-internal": false,
				"ct-ground-areas": false,
				"BezierCurve.1276": false,
				"BezierCurve.1281": false,
				"BezierCurve.1535": false,
				"BezierCurve.1537": false,
				"BezierCurve.1536": false,
				"BezierCurve.1543": false,
				"BezierCurve.1544": false,
				"BezierCurve.1539": false,
				"BezierCurve.1541": false,
				"BezierCurve.1538": false,
				"BezierCurve.1540": false,
				"BezierCurve.1542": false,
				"BezierCurve.964": false,
				"BezierCurve.962": false,
				"BezierCurve.960": false,
				"BezierCurve.953": false,
				"BezierCurve.955": false,
				"BezierCurve.961": false,
				"BezierCurve.959": false,
				"BezierCurve.957": false,
				"BezierCurve.956": false,
				"Cube.5402": false,
				"Cube.5403": false,

				// BC
				"bc-internal": false,
				"bc-ground-areas": false,
				"splice-case-lid": false,
				"bc.scd-wall-box": false,
				"bc.fieldsmart": false,
				"BezierCurve.949": false,
				"bc.scd-wall-box-for-xpak": false,

				// SFH
				// "sfh": false,
				"sfh-internal": false,
				"sfh-ground-areas": false,
				"sfh-powerLine-1-BezierCurve": false,
				"sfh-powerLine-2-BezierCurve": false,
				"sfh-powerLine-3-BezierCurve": false,
				"sfh-powerLine-4-BezierCurve": false,
				"sfh-powerLine-5-BezierCurve": false,
				"sfh-powerLine-6-BezierCurve": false,
				"sfh-powerLine-7-BezierCurve": false,
				"sfh-powerLine-8-BezierCurve": false,
				"sfh-powerLine-9-BezierCurve": false,
				"sfh-powerLine-10-BezierCurve": false,
				"sfh-powerLine-11-BezierCurve": false,
				"sfh-powerLine-12-BezierCurve": false,
				"sfh.cabinet-pon-cable-1-BezierCurve": false,
				"sfh.cabinet-pon-cable-2-BezierCurve": false,
				"sfh.cross-connect-cabinet.craftsmart-vault": false,
				"sfh.fieldsmart-makwa.vault": false,
				"sfh.yourx-terminal-vault.001": false,
				"sfh.craftsmart-tap.cable-BezierCurve": false,
				"sfh.fieldshield-yourx-tap.cable-1-BezierCurve": false,
				"sfh.fieldshield-yourx-tap.cable-2-BezierCurve": false,
				"sfh.yourx-terminal-pedestal.lid": false,
				"sfh.yourx-terminal-pedestal.001": false,
			},
			children: {

				"sfh": {
					meshesEnable: {
						"sfh-internal": true,
						"sfh-ground-areas": true,
						"sfh-powerLine-1-BezierCurve": true,
						"sfh-powerLine-2-BezierCurve": true,
						"sfh-powerLine-3-BezierCurve": true,
						"sfh-powerLine-4-BezierCurve": true,
						"sfh-powerLine-5-BezierCurve": true,
						"sfh-powerLine-6-BezierCurve": true,
						"sfh-powerLine-7-BezierCurve": true,
						"sfh-powerLine-8-BezierCurve": true,
						"sfh-powerLine-9-BezierCurve": true,
						"sfh-powerLine-10-BezierCurve": true,
						"sfh-powerLine-11-BezierCurve": true,
						"sfh-powerLine-12-BezierCurve": true,
						"sfh.cabinet-pon-cable-1-BezierCurve": true,
						"sfh.cabinet-pon-cable-2-BezierCurve": true,
						"sfh.cross-connect-cabinet.craftsmart-vault": true,
						"sfh.fieldsmart-makwa.vault": true,
						"sfh.yourx-terminal-vault.001": true,
						"sfh.craftsmart-tap.cable-BezierCurve": true,
						"sfh.fieldshield-yourx-tap.cable-1-BezierCurve": true,
						"sfh.fieldshield-yourx-tap.cable-2-BezierCurve": true,
						"sfh.yourx-terminal-pedestal.lid": true,
						"sfh.yourx-terminal-pedestal.001": true,
					},
					children: {
						"fieldsmart-makwa": {
							animations: [...sfhFieldSmartMakwa, ...sfhGroundAreasFadeOut],
							children: {
								"black-cassette": {
									animations: sfhFieldsmartMakwaBlackCassette
								}
							}
						},
						"cross-connect-cabinet": {
							animations: [
								sfhGroundCrossConnectCabinet,
								...sfhGroundAreasFadeOut,
								AnimFactory.Door("sfh.cross-connect-cabinet.door-left", scene, true),
								AnimFactory.Door("sfh.cross-connect-cabinet.door-right", scene, false),
							],
							children: {
								"blue": {
									animations: sfhCrossConnectCabinetCassetteBlue
								}
							}
						},
						"yourx-aerial-terminal": {
							animations: [
								AnimFactory.Door("sfh.yourx-aerial-terminal-lid", scene, false, BABYLON.Axis.X)
							]
						},
						"yourx-pedestal": {
							animations: [
								sfhGroundYourxPedestal,
								...sfhGroundAreasFadeOut,
								AnimFactory.PedestalLidRemoval("sfh.yourx-terminal-pedestal.lid", scene, 1.5)
							]
						},
						"yourx-terminal-vault": {
							animations: [sfhGroundYourxTerminalVault, ...sfhGroundAreasFadeOut]
						},
						"cabinet-pon" : {
							animations: [AnimFactory.Door("sfh.cabinet-pon.cabinet.door", scene, true), ...sfhGroundAreasFadeOut],
							children: {
								"blue": {
									animations: [...sfhCabinetPonBlueCassette]
								}
							}
						},
						"yourx-tap" : {
							animations: [sfhHouseCutaway, sfhYourXTapLidRemoval, ...sfhGroundAreasFadeOut]
						},
						"craftsmart-tap" : {
							animations: [sfhHouse2Cutaway, sfhCraftsmartTapLidPosition, sfhCraftsmartTapLidRotation, ...sfhGroundAreasFadeOut]
						},
					}
				},

				"bc": {
					meshesEnable: {
						"bc-internal": true,
						"bc-ground-areas": true,
						"splice-case-lid": true,
						"bc.scd-wall-box": true,
						"bc.fieldsmart": true,
						"BezierCurve.949": true,
						"bc.scd-wall-box-for-xpak": true,
					},
					animations: [bcCutaway, bcCutaway2],
					children: {
						"cabinet": {
							animations: [
								AnimFactory.Door("bc.cabinet.door", scene, true),
							],
							children: {
								"blue": {
									animations: AnimFactory.CassetteRemoval(
										"bc.cabinet.blue",
										"bc.cabinet.blue.lid",
										scene,
										new BABYLON.Vector3(0, 0, 0.02), // positionDelta (back-front, to side, up-down)
										undefined, // rotationDelta
										undefined, // lidPositionDelta
										undefined, // lidRotationDelta
										undefined, // rotateAfterPosition
										undefined, // cassetteRemovalPositionName
										undefined, // anims
										{	// positionAnimPosition
											relativeToName: sfhFieldsmartMakwaVaultRotateUpAnimName,
											percent: 0.8
										}
									)
								}
							}
						},
						"cabinet-pon": {
							animations: [
								AnimFactory.Door("bc.cabinet-pon.door", scene, true),
							],
							children: {
								"blue": {
									animations: bcCabinetPonBlue
								}
							}
						},
						"scd-wall-box": {
							animations: [
								AnimFactory.Door("bc.scd-wall-box-for-cassette.lid", scene, true),
								...bcGroundAreasFadeOut
							],
							children: {
								"blue-mpo": {
									animations: AnimFactory.CassetteLidRemoval(
										"bc.scd-wall-box.blue-mpo.lid", scene,
										{percent: 0},
										undefined, // lidPositionAnimName
										new BABYLON.Vector3(-0.015, 0.02, 0), // Position delta
										new BABYLON.Vector3(0, -0.2, Math.PI * 0.33), // Rotation delta
									)
								}
							}
						},
						"scd-wall-box-for-xpak": {
							animations: [
								AnimFactory.Door("cover.002", scene, false),
								...bcGroundAreasFadeOut
							]
						},
						"fieldsmart": {
							animations: [
								AnimFactory.Ground("bc-ground-cutaway", scene),
								AnimFactory.PedestalLidRemoval("bc.fieldsmart-pon-pedistal-cover", scene),
								...bcGroundAreasFadeOut
							]
						},
						"fieldsmart-standard": {
							animations: bcGroundAreasFadeOut,
							children: {
								"blue": {
									animations: bcFieldsmartStandardBlue
								}
							}
						},							
						"splice-case": {
							animations: [
								/*
								...AnimFactory.SlideGroundLidAndRotate(
									"splice-case-lid", scene,
									new BABYLON.Vector3(0, 0, 2.28), new BABYLON.Vector3(0, (Math.PI * 0.15), 0)
								),
								*/
								...bcGroundAreasFadeOut
							]
						},
					}
				},

				"co": {
					meshesEnable: {
						"co-internal": true,
						"Cube.4262": true,
						"Cube.4267": true,
						"Cube.4268": true,
					},
					animations: [coCutaway],
					children: {
						"fieldsmart-fec": {
							animations: [
								AnimFactory.Door("co.fieldsmart-fec.left-door", scene, true),
								AnimFactory.Door("co.fieldsmart-fec.right-door", scene, false),
							]
						},
						"fxhd-frame": {
							animations: [
								AnimFactory.Door("co.fxhd-frame.left-door", scene, true),
								AnimFactory.Door("co.fxhd-frame.right-door", scene, false),
							],
							children: {
								"blue": {
									animations: coFxhdFrameBlueCassette
								}
							}
						},
						"fxds-frame": {
							animations: [
								AnimFactory.Door("co.fxds-frame.left-door", scene, true),
								AnimFactory.Door("co.fxds-frame.right-door", scene, false),
							],
							children: {
								"blue": {
									animations: coFxdsFrameBlueCassette
								}
							}
						},
						"fieldsmart-standard": {
							children: {
								"blue": {
									animations: coFieldsmartStandardBlueCassette
								}
							}
						},
					}
				},

				"ct": {
					meshesEnable: {
						"ct-internal": true,
						"ct-ground-areas": true,
						"BezierCurve.1276": true,
						"BezierCurve.1281": true,
						"BezierCurve.1535": true,
						"BezierCurve.1537": true,
						"BezierCurve.1536": true,
						"BezierCurve.1543": true,
						"BezierCurve.1544": true,
						"BezierCurve.1539": true,
						"BezierCurve.1541": true,
						"BezierCurve.1538": true,
						"BezierCurve.1540": true,
						"BezierCurve.1542": true,
						"BezierCurve.964": true,
						"BezierCurve.962": true,
						"BezierCurve.960": true,
						"BezierCurve.953": true,
						"BezierCurve.955": true,
						"BezierCurve.961": true,
						"BezierCurve.959": true,
						"BezierCurve.957": true,
						"BezierCurve.956": true,
						"Cube.5402": true,
						"Cube.5403": true,
					},
					animations: [
						AnimFactory.FadeAwayWall("hut001.cutaway", scene),
						AnimFactory.FadeAwayWall("hut.cutaway", scene),
					],
					children: {
						"small-cell-pole": {
							animations: [
								AnimFactory.Door("ct.small-cell-pole.lid", scene, true),
								...ctGroundAreasFadeOut
							],
							children: {
								"blue-mpo": {
									animations: AnimFactory.CassetteLidRemoval(
										"ct.small-cell-pole.blue-mpo.lid", scene,
										{percent: 0},
										undefined, // lidPositionAnimName
										new BABYLON.Vector3(-0.015, 0.02, 0), // Position delta
										new BABYLON.Vector3(0, -0.2, Math.PI * 0.33), // Rotation delta
									)
								},
							}
						},
						"fieldsmart": {
							animations: [
								AnimFactory.PedestalLidRemoval("bc.fieldsmart-pon-pedistal-cover.002", scene),
								AnimFactory.Ground("ct-ground-cutaway", scene),
								...ctGroundAreasFadeOut
							],
							children: {
								"blue-mpo": {
									animations: ctFieldsmartBlueMpoCassette
								},
								"blue": {
									animations: ctFieldsmartBlueCassette
								}
							}
						},
						"splice-case": {
							animations: [
								/*
								...AnimFactory.SlideGroundLidAndRotate(
									"ct.splice-case.lid", scene,
									new BABYLON.Vector3(0, 0, 2.28), new BABYLON.Vector3(0, (Math.PI * 0.15), 0)
								),
								*/
								AnimFactory.Ground("ct-ground-cutaway-2", scene),
								...ctGroundAreasFadeOut
							],
						},
						"cran-frame": {
							animations: ctGroundAreasFadeOut,
							children: {
								"blue": {
									animations: ctCranFrameBlue,
								},
								"blue-mpo": {
									animations: ctCranFrameBlueMpo
								},
							}
						},
					}
				},

				"mdu": {
					meshesEnable: {
						"mdu-internal": true,
						"mdu.fdp-indoor-144.hinge": true,
						"mdu.fdp-indoor-36.hinge": true,
					},
					animations: [
						mduGround,
						mduApartmentCutaway,
						mduApartmentBackwallCutaway
					],
					children: {
						"cabinet-pon": {
							animations: [AnimFactory.Door("mdu.cabinet-pon.door", scene, true)],
							children: {
								"blue": {
									animations: mduCabinetPonBlueCassette
								}
							}
						},
						"fdp-indoor-36": {
							animations: [
								AnimFactory.Door("mdu.fdp-indoor-36.left-door", scene, true),
								AnimFactory.Door("mdu.fdp-indoor-36.right-door", scene, false),
							],
							children: {
								"blue-mpo": {
									animations: mduFdpIndoor36BlueMpoCassette
								}
							}
						},
						"fdp-indoor-144": {
							animations: [
								AnimFactory.Door("mdu.fdp-indoor-144.left-door", scene, true),
								AnimFactory.Door("mdu.fdp-indoor-144.right-door", scene, false),
							],
							children: {
								"blue": {
									animations: mduFdpIndoor144BlueCassette
								},
								"blue-mpo": {
									animations: mduFdpIndoor144BlueMpoCassette
								}
							}
						},
						"fieldshield-strongfiber-reel": {
							animations: [AnimFactory.Door("cover.001", scene, false)]
						},
						"yourx-flex-box": {
							animations: [AnimFactory.Door("mdu.yourx-flex-box.door", scene, true)]
						},
					}
				},

				"hfc": {
					meshesEnable: {
						"hfc-internal": true,
						"BezierCurve.973": true,
						"BezierCurve.2065": true,
						"BezierCurve.2066": true,
						"BezierCurve.2064": true,
						"BezierCurve.1025": true,
						"BezierCurve.986": true,
						"BezierCurve.975": true,
						"Cylinder.143": true,
						"BezierCurve.987": true,
						"Plane.059": true,
						"BezierCurve.988": true,
						"Cube.3161": true,
						"BezierCurve.2068": true,
						"BezierCurve.2067": true,
						"BezierCurve.2069": true,
						"BezierCurve.2070": true,
						"BezierCurve.2073": true,
						"BezierCurve.2074": true,
						"BezierCurve.2072": true,
						"BezierCurve.2071": true,
					},
					children: {
						"hcc": {
							animations: [AnimFactory.Door("hfc.hcc.door", scene, true)],
							children: {
								"blue": {
									animations: hfcHccCassette
								}
							}
						}
					}
				},


			}
		};

		// Disable all those meshes set to be disabled at root
		const rootMeshList = this.AnimEntries.meshesEnable;
		for (let key in rootMeshList) {
			if (rootMeshList.hasOwnProperty(key)) {
				let meshName = key;
				let isEnable = rootMeshList[key];

				[NodeStructureHelper.WorldNames.telecom, NodeStructureHelper.WorldNames.cable].forEach((worldName: string) => {
					const mesh = NodeStructureHelper.GetMesh(
						scene, worldName, meshName, true
					);
					if (mesh !== undefined) {
						mesh.setEnabled(isEnable);
					}
				});
			}
		}
		
		this._inited = true;
	}

	private static DetermineEntryForNode(
		scene: BABYLON.Scene, path: string[], worldName: string, entryNode: EntryNode,
		animationEntries: AbstractAnimEntry[], meshesEnableList: {[key: string]: {mesh: BABYLON.AbstractMesh, enable: boolean}}
	): void {
		// Mesh enabling and disabling
		if (entryNode.meshesEnable !== undefined) {
			for (let key in entryNode.meshesEnable) {
				if (entryNode.meshesEnable.hasOwnProperty(key)) {
					const mesh = NodeStructureHelper.GetMesh(
						scene, worldName, key, true
					);
					if (mesh !== undefined) {
						meshesEnableList[mesh.name] = {
							mesh: mesh, 
							enable: entryNode.meshesEnable[key]
						};
					}
				}
			}
		}

		// Animations
		if (entryNode.animations !== undefined) {
			entryNode.animations.forEach((animEntry: AbstractAnimEntry) => {
				if (animationEntries.indexOf(animEntry) === -1) {
					animationEntries.push(animEntry);
				}
				if (animEntry instanceof AnimEntry && animEntry.target instanceof WorldAwareMeshTarget) {
					animEntry.target.setWorldName(worldName);
				}
				animEntry.setActiveVersion(WorldAnimationData.AnimationInStateName);
			});
		}

		// Children
		const currentPath: string = path.shift();
		if (entryNode.children !== undefined && currentPath !== undefined && entryNode.children[currentPath] !== undefined) {
			WorldAnimationData.DetermineEntryForNode(scene, path, worldName, entryNode.children[currentPath], animationEntries, meshesEnableList);
		}
	}

	public static OnAnimationsPlayedListComplete(): void {
		// Update list by removing those that are now in an out / off state
		const newList: AbstractAnimEntry[] = [];
		WorldAnimationData.AnimationsPlayed.forEach((animEntry: AbstractAnimEntry) => {
			if (animEntry.activeVersionName !== WorldAnimationData.AnimationOutStateName) {
				newList.push(animEntry);
			}
		});
		WorldAnimationData.AnimationsPlayed = newList;
		
		// Disable meshes that are going to disabled state.
		WorldAnimationData.UpdateMeshEnable(false);
	}

	public static UpdateMeshEnable(ifGoingTo: boolean):void {
		for (let key in WorldAnimationData.MeshesEnableList) {
			if (WorldAnimationData.MeshesEnableList.hasOwnProperty(key)) {
				const entry: {mesh: BABYLON.AbstractMesh, enable: boolean} = WorldAnimationData.MeshesEnableList[key];
				if (entry.enable === ifGoingTo && entry.mesh !== undefined && entry.mesh !== null) {
					entry.mesh.setEnabled(entry.enable);
				}
			}
		}
	}

	/**
	 * Add the needed aniations to the AnimationTimeline based on the passed Nodes
	 */
	public static AddAnimations(node: Structure.Node, timeline: AnimationTimeline, scene: BABYLON.Scene): void {
		WorldAnimationData.Init(scene);

// console.log("INTO:", node.nameNormalized);

		// Default on previously played animations to an out state
		WorldAnimationData.AnimationsPlayed.forEach((animEntry: AbstractAnimEntry) => {
			animEntry.setActiveVersion(WorldAnimationData.AnimationOutStateName);
		});

		// Build list
		const path: string[] = node.path.slice(); // Copy the array
		WorldAnimationData.MeshesEnableList = {};
		WorldAnimationData.DetermineEntryForNode(
			scene,
			path,
			node.worldName,
			this.AnimEntries,
			WorldAnimationData.AnimationsPlayed,
			WorldAnimationData.MeshesEnableList
		);

		// Enable meshes that are going to enabled.
		WorldAnimationData.UpdateMeshEnable(true);

		// Add animations to timeline
		timeline.add(WorldAnimationData.AnimationsPlayed);
	}

}



const CameraPositionAnimName = "activeCamera.position"; // TODO Should be equal to VisualControl.CameraPositionAnimName but that keeps erroring when building with TypeScript. Could not figure out why.

class AnimFactory {
	public static CommonPositions: {[key: string]: AnimPosition} = {
		startCameraMove: {
			relativeToName: CameraPositionAnimName,
			percent: 0
		},
		afterCameraMove: {
			relativeToName: CameraPositionAnimName,
			percent: 1
		},
		beforeCameraMoveFinishes: {
			relativeToName: CameraPositionAnimName,
			percent: 1,
			offset: -500
		},
		justAfterCameraMove: {
			relativeToName: CameraPositionAnimName,
			percent: 1,
			offset: 50
		}
	}

	public static Door(
		name: string,
		scene: BABYLON.Scene,
		isLeftDoor: boolean = true,
		axis: BABYLON.Vector3 = BABYLON.Axis.Y
	): AnimEntry {
		BABYLON.Axis.Y

		const deltaAmount = isLeftDoor === true
			? Math.PI * (2 / 3)
			: 0 - Math.PI * (2 / 3);

		let delta;
		if (axis === BABYLON.Axis.X) {
			delta = new BABYLON.Vector3(deltaAmount, 0, 0);
		} else if (axis === BABYLON.Axis.Y) {
			delta = new BABYLON.Vector3(0, deltaAmount, 0);
		} else {
			delta = new BABYLON.Vector3(0, 0, deltaAmount);
		}
		
		return new AnimEntry(
			name + ".doorRotation",
			new WorldAwareMeshTarget(name, scene),
			MeshProperties.rotation,
			{delta: delta},
			{
				speed: 1 / 2250,
				easing: AnimationEasing.In,
				position: AnimFactory.CommonPositions.beforeCameraMoveFinishes
			}
		).addVersion(WorldAnimationData.AnimationOutStateName, {
			direction: AnimDirection.toStart,
			speed: 1 / 2000,
			easing: AnimationEasing.Out,
			position: {
				relativeToName: CameraPositionAnimName,
				percent: 0.75,
				offset: -100
			}
		});
	}

	public static Ground(name: string, scene: BABYLON.Scene, moveYDelta: number = -0.0108): AnimEntry {
		const moveDelta = new BABYLON.Vector3(0, moveYDelta, 0);
		const speed: number = Math.abs(moveDelta.length()) / 600;
		return new AnimEntry(
				name + ".groundPosition",
				new WorldAwareMeshTarget(name, scene),
				MeshProperties.position,
				{delta: moveDelta},
				{
					speed: speed,
					easing: AnimationEasing.In,
					position: AnimFactory.CommonPositions.justAfterCameraMove
				}
			).addVersion(WorldAnimationData.AnimationOutStateName, {
				direction: AnimDirection.toStart,
				speed: speed * 1.5,
				easing: AnimationEasing.Out,
				position: AnimFactory.CommonPositions.startCameraMove
			});
	}

	public static FadeAwayWall(name: string, scene: BABYLON.Scene, animationPosition: AnimPosition = AnimFactory.CommonPositions.beforeCameraMoveFinishes): AnimEntry {
		return new AnimEntry(
			name + ".fadeAwayWall",
			new WorldAwareMeshTarget(name, scene),
			MeshProperties.visibility,
			{start: 1, end: 0},
			{
				speed: 1 / 1000,
				easing: AnimationEasing.Out,
				position: animationPosition
			}
		).addVersion(WorldAnimationData.AnimationOutStateName, {
			direction: AnimDirection.toStart,
			speed: 1 / 1000,
			easing: AnimationEasing.Out,
			position: AnimFactory.CommonPositions.startCameraMove
		});
	}

	public static FadeAwayChildren(parentName: string, scene: BABYLON.Scene): AnimEntry[] {
		const defaultVersion: AnimVersion = {
			speed: 1 / 600,
			easing: AnimationEasing.In,
			position: AnimFactory.CommonPositions.startCameraMove
		};
		const reverseVersion: AnimVersion = {
			direction: AnimDirection.toStart,
			speed: 1 / 1000,
			easing: AnimationEasing.Out,
			position: AnimFactory.CommonPositions.beforeCameraMoveFinishes
		}

		const anims: AnimEntry[] = [];

		// Need to get a list of children. To do that we are just choosing a world to use for this purpose
		const parentMesh: BABYLON.AbstractMesh = NodeStructureHelper.GetMesh(
			scene, NodeStructureHelper.WorldNames.telecom, parentName
		) || NodeStructureHelper.GetMesh(
			scene, NodeStructureHelper.WorldNames.cable, parentName
		);

		if (parentMesh !== undefined) {
			const children: BABYLON.Node[] = parentMesh.getChildren();
			if (children !== undefined && children !== null) {
				for (let i = 0, len = children.length; i < len; i++) {
					let child = children[i];
					let childOrgName = NodeStructureHelper.ParseMeshNameParts(child.name, true).orginalMeshName;
					anims.push(
						new AnimEntry(
							parentName + ".fadeAway_" + childOrgName,
							new WorldAwareMeshTarget(childOrgName, scene),
							MeshProperties.visibility,
							{start: 1, end: 0},
							defaultVersion
						).addVersion(WorldAnimationData.AnimationOutStateName, reverseVersion)
					);
				}
			}
		}

		return anims;
	}

	public static PedestalLidRemoval(name: string, scene: BABYLON.Scene, moveYDelta: number = 5, duration: number = 750): AnimEntry {
		const moveDelta = new BABYLON.Vector3(0, moveYDelta, 0);
		const speed: number = Math.abs(moveDelta.length()) / duration;
		return new AnimEntry(
				name + ".pedestalLidRemoval",
				new WorldAwareMeshTarget(name, scene),
				MeshProperties.position,
				{delta: moveDelta},
				{
					speed: speed,
					easing: AnimationEasing.In,
					position: AnimFactory.CommonPositions.justAfterCameraMove
				}
			).addVersion(WorldAnimationData.AnimationOutStateName, {
				direction: AnimDirection.toStart,
				speed: speed * 1.5,
				easing: AnimationEasing.Out,
				position: {
					relativeToName: CameraPositionAnimName,
					percent: 0.33,
					offset: 0
				}
			});
	}

	public static CassetteCaseRotate(
		name: string, scene: BABYLON.Scene,
		valueKeysOrRotationDelta: AnimValueKeys | BABYLON.Vector3 = (new BABYLON.Vector3(0, 0 - Math.PI * (1 / 5), 0)),
		outTimelinePositionName: string,
		anims: AnimEntry[] = [],
	): AnimEntry[] {

		let valueKeys: AnimValueKeys = valueKeysOrRotationDelta instanceof BABYLON.Vector3 ?
			{delta: valueKeysOrRotationDelta} : valueKeysOrRotationDelta;

		const rotation = new AnimEntry(
			name + ".cassetteCaseRotate",
			new WorldAwareMeshTarget(name, scene),
			MeshProperties.rotation,
			valueKeys,
			{
				speed: 1 / 4000,
				easing: AnimationEasing.Out,
				position: AnimFactory.CommonPositions.beforeCameraMoveFinishes
			}
		).addVersion(WorldAnimationData.AnimationOutStateName, {
			direction: AnimDirection.toStart,
			speed: 1 / 3500,
			easing: AnimationEasing.Out,
			position: {
				relativeToName: outTimelinePositionName,
				percent: 0.85
			}
		});

		anims.push(rotation);

		return anims;
	}

	public static CassetteLidRemoval(
		lidName:string, scene: BABYLON.Scene,
		timelinePositionRelativeName: AnimPosition = AnimFactory.CommonPositions.beforeCameraMoveFinishes,
		lidPositionAnimName: string = lidName + ".cassetteRemovalPosition",
		lidPositionDelta: BABYLON.Vector3 = (new BABYLON.Vector3(0.015, 0, 0)),
		lidRotationDelta: BABYLON.Vector3 = (new BABYLON.Vector3(0, 0.2, Math.PI * -0.33)),
		rotateAfterPosition: boolean = false,
		cassetteRemovalPositionName: string = name + ".cassetteRemovalPosition",
		anims: AnimEntry[] = []
	): AnimEntry[] {
		// Lid Remove
		// Cassette Position
		const lidPositionSpeed = Math.abs(lidPositionDelta.length()) / 800;

		let lidPositionVersion_out: AnimVersion;
		if (rotateAfterPosition === false) {
			lidPositionVersion_out = {
				direction: AnimDirection.toStart,
				speed: lidPositionSpeed,
				easing: AnimationEasing.InOut,
				position: AnimFactory.CommonPositions.startCameraMove
			};
		} else {
			lidPositionVersion_out = {
				direction: AnimDirection.toStart,
				speed: lidPositionSpeed * 3,
				easing: AnimationEasing.Out,
				position: AnimFactory.CommonPositions.startCameraMove
			};
		}

		let lidPosition: AnimEntry = new AnimEntry(
				lidPositionAnimName,
				new WorldAwareMeshTarget(lidName, scene),
				MeshProperties.position,
				{delta: lidPositionDelta}, // ValueKeys
				{	// Version (default)
					speed: lidPositionSpeed,
					easing: AnimationEasing.Out,
					position: timelinePositionRelativeName
				}
			).addVersion(WorldAnimationData.AnimationOutStateName, lidPositionVersion_out);
		anims.push(lidPosition);

		// Cassette Rotate 
		const lidrotationVersion_default = new AnimDynamicVersion(
				{
					speed: 1, // This is overriden by the update() method passed below
					easing: AnimationEasing.Out,
					position: { relativeToName: lidPosition.name, mode: AnimPositionMode.endOrigin }
				},
				(data: AnimUpdateData): boolean => {
					// Match the duration of the position move.
					data.duration = data.timelineContext.duration * 1;
					return true;
				}
			);
		const lidRotation: AnimEntry = 
			new AnimEntry(
				lidName + ".cassetteRemovalRotation",
				new WorldAwareMeshTarget(lidName, scene),
				MeshProperties.rotation,
				{delta: lidRotationDelta},
				lidrotationVersion_default
			).addVersion(WorldAnimationData.AnimationOutStateName, {
				direction: AnimDirection.toStart,
				speed: 1 / 3000,
				easing: AnimationEasing.Out,
				position: AnimFactory.CommonPositions.startCameraMove
			});
		anims.push(lidRotation);

		return anims;
	}

	public static CassetteRemoval(
		name: string, lidName:string, scene: BABYLON.Scene,
		positionDelta: BABYLON.Vector3 = (new BABYLON.Vector3(0, 0, 0.1)),
		rotationDelta: BABYLON.Vector3 = (new BABYLON.Vector3(Math.PI * 0.5, 0, 0)),
		lidPositionDelta: BABYLON.Vector3 = (new BABYLON.Vector3(0.015, 0, 0)),
		lidRotationDelta: BABYLON.Vector3 = (new BABYLON.Vector3(0, 0.2, Math.PI * -0.33)),
		rotateAfterPosition: boolean = false,
		cassetteRemovalPositionName: string = name + ".cassetteRemovalPosition",
		anims: AnimEntry[] = [],
		positionAnimPosition?: AnimPosition
	): AnimEntry[] {
		const rotationAnimName = name + ".cassetteRemovalRotation";
		const lidPositionAnimName = lidName + ".cassetteRemovalPosition";

		if (positionAnimPosition === undefined) {
			positionAnimPosition = {
				relativeToName: CameraPositionAnimName,
				percent: 0.75,
				offset: -200
			};
		}

		// Cassette Position
		const positionSpeed = Math.abs(positionDelta.length()) / 800;

		let postionVersion_out: AnimVersion;
		if (rotateAfterPosition === false) {
			postionVersion_out = {
				direction: AnimDirection.toStart,
				speed: positionSpeed,
				easing: AnimationEasing.InOut,
				position: AnimFactory.CommonPositions.startCameraMove
			};
		} else {
			postionVersion_out = {
				direction: AnimDirection.toStart,
				speed: positionSpeed * 2,
				easing: AnimationEasing.InOut,
				position: {
					relativeToName: rotationAnimName,
					percent: 0.75
				}
			};
		}
		
		const position = new AnimEntry(
				cassetteRemovalPositionName,
				new WorldAwareMeshTarget(name, scene),
				MeshProperties.position,
				{delta: positionDelta}, // ValueKeys
				{	// Version (default)
					speed: positionSpeed,
					easing: AnimationEasing.Out,
					position: positionAnimPosition
				}
			).addVersion(WorldAnimationData.AnimationOutStateName, postionVersion_out);
		anims.push(position);

		// Cassette Rotate 
		let rotationVersion_default: AnimVersion;
		if (rotateAfterPosition === false) {
			rotationVersion_default = new AnimDynamicVersion(
					{
						speed: 1, // This is overriden by the update() method passed below
						easing: AnimationEasing.InOut,
						position: { relativeToName: position.name, mode: AnimPositionMode.endOrigin }
					},
					(data: AnimUpdateData): boolean => {
						data.duration = data.timelineContext.duration * 0.8;
						return true;
					}
				);
		} else {
			rotationVersion_default = new AnimDynamicVersion(
					{
						speed: 1, // This is overriden by the update() method passed below
						easing: AnimationEasing.InOut,
						position: { relativeToName: position.name, mode: AnimPositionMode.startOrigin, percent: 0.9 }
					},
					(data: AnimUpdateData): boolean => {
						data.duration = data.timelineContext.duration * 0.8;
						return true;
					}
				);
		}

		let rotationVersion_out: AnimVersion;
		if (rotateAfterPosition === false) {
			rotationVersion_out = {
				direction: AnimDirection.toStart,
				speed: 1 / 3000,
				easing: AnimationEasing.Out,
				position: AnimFactory.CommonPositions.startCameraMove
			};
		} else {
			rotationVersion_out = {
				direction: AnimDirection.toStart,
				speed: 1 / 1500,
				easing: AnimationEasing.Out,
				position: {
					relativeToName: lidPositionAnimName,
					percent: 0.5
				}
			};
		}

		let rotation: AnimEntry = 
			new AnimEntry(
				rotationAnimName,
				new WorldAwareMeshTarget(name, scene),
				MeshProperties.rotation,
				{delta: rotationDelta},
				rotationVersion_default
			).addVersion(WorldAnimationData.AnimationOutStateName, rotationVersion_out);
		anims.push(rotation);

		// Lid removal
		AnimFactory.CassetteLidRemoval(
			lidName, scene,
			{ relativeToName: rotation.name, percent: 0.33 },
			lidPositionAnimName,
			lidPositionDelta,
			lidRotationDelta,
			rotateAfterPosition,
			cassetteRemovalPositionName,
			anims
		);

		return anims;
	}
	

	public static SlideGroundLidAndRotate(
		name: string, scene: BABYLON.Scene,
		moveDelta: BABYLON.Vector3,
		rotateDelta?: BABYLON.Vector3,
		duration: number = 1200,
		outStatePosition?: AnimPosition
	): AnimEntry[] {
		// Position
		const speed: number = Math.abs(moveDelta.length()) / duration;
		const position = new AnimEntry(
				name + ".slideGroundLidPosition",
				new WorldAwareMeshTarget(name, scene),
				MeshProperties.position,
				{delta: moveDelta},
				{
					speed: speed,
					easing: AnimationEasing.InOut,
					position: AnimFactory.CommonPositions.justAfterCameraMove
				}
			).addVersion(WorldAnimationData.AnimationOutStateName, {
				direction: AnimDirection.toStart,
				speed: speed * 1.5,
				easing: AnimationEasing.Out,
				position: outStatePosition !== undefined ? outStatePosition : AnimFactory.CommonPositions.startCameraMove
			});
		
		let anims = [position];
		
		// Rotate
		if (rotateDelta !== undefined) {
			const rotationVersion_default = new AnimDynamicVersion(
					{
						speed: 1, // This is overriden by the update() method passed below
						easing: AnimationEasing.InOut,
						position: { relativeToName: position.name }
					},
					(data: AnimUpdateData): boolean => {
						data.duration = data.timelineContext.duration; // Match the duration of the position move.
						return true;
					}
				);
			const rotation: AnimEntry = 
				new AnimEntry(
					name + ".slideGroundLidRotation",
					new WorldAwareMeshTarget(name, scene),
					MeshProperties.rotation,
					{delta: rotateDelta},
					rotationVersion_default
				).addVersion(WorldAnimationData.AnimationOutStateName, {
					direction: AnimDirection.toStart,
					speed: 1 / 3000,
					easing: AnimationEasing.Out,
					position: AnimFactory.CommonPositions.startCameraMove
				});
			anims.push(rotation);
		}
		
		return anims;
	}
}