import * as BABYLON from 'babylonjs';
import {Property, Target} from '../AnimationEntry';

const eulerKey = "rotation";
const quatKey = "rotationQuaternion";

export abstract class AbstractNodeTarget implements Target {
	// Next two are temporarily stored dTargetepending on constructor used
	protected _nodeName: string;
	protected _scene: BABYLON.Scene;

	protected _targetNode: BABYLON.Node;
	
	constructor(node: BABYLON.Node);
	constructor(name: string, scene: BABYLON.Scene);
	constructor(nodeOrMesh: any, scene?: BABYLON.Scene) {
		if (typeof nodeOrMesh === "string") {
			this._nodeName = nodeOrMesh;
			this._scene = scene;
		} else {
			this._targetNode = nodeOrMesh;
		}
	}

	public abstract getObject(): BABYLON.Node;
	
	public getName(): string {
		return this.getObject().name;
	}
	public init(property: Property): void {
		// Take care of babylon's issue with not supporting both rotation methods on a mesh, camera, etc.
		// This solution is not perfect but will fit basic needs. It simply inits the wanted property, but does not keep the two possible values in sync (best would be a lazy syncing of the two, in my opinion).
		const obj = this.getObject();
		if (obj !== undefined) {
			if (property.key === eulerKey) {
				const rotEuler: BABYLON.Vector3 = this.getPropertyValueForKey(eulerKey);
				const rotQuat: BABYLON.Quaternion = this.getPropertyValueForKey(quatKey);
				
				if (rotEuler === undefined || rotEuler === null) {
					obj[eulerKey] = BABYLON.Vector3.Zero();
				}
				if (
					rotEuler.x === 0 && rotEuler.y === 0 && rotEuler.z === 0 // Note: Babylon also sets to rotation to zeroed when quaternion is used
					&& (rotQuat !== undefined && rotQuat !== null)
				) {
					rotQuat.toEulerAnglesToRef(obj[eulerKey]);
				}

			} else if (property.key === quatKey) {
				const rotEuler: BABYLON.Vector3 = this.getPropertyValueForKey(eulerKey);
				const rotQuat: BABYLON.Quaternion = this.getPropertyValueForKey(quatKey);
				
				if (rotQuat === undefined || rotQuat === null) {
					obj[quatKey] = new BABYLON.Quaternion();
				}
				if (
					rotEuler !== undefined && rotEuler !== null &&
					!(rotEuler.x === 0 && rotEuler.y === 0 && rotEuler.z === 0)
				) {
					BABYLON.Quaternion.RotationYawPitchRollToRef(
						rotEuler.y, rotEuler.x, rotEuler.z,
						obj[quatKey]
					);
				}

			}
		} else {
			console.warn("Could not init target because target is undefined.", this, obj);
		}
	}
	public getPropertyValue(property: Property): any {
		return this.getPropertyValueForKey(property.key);
	}
	public getPropertyValueForKey(propertyKey: string): any {
		const obj = this.getObject();
		if (obj === undefined) {
			return undefined;
		}
		return obj[propertyKey];
	}
}