import * as BABYLON from 'babylonjs';
import * as AnimationEntry from './AnimationEntry';
import BabylonHelper from '../lib/BabylonHelper';

interface AnimationEntryNode {
	entry: AnimationEntry.AbstractEntry,
	children: AnimationEntryNode[]
}

export interface TimelineContext {
	lowFrame: number,
	highFrame: number,
	duration: number
}

/* TODO - Future enhancements
- Make it so an entry can animation multiple properties (as long as they use the same easing, duration, etc.).
- Make it possible to make animations relative to the entire timeline. Would need to make a second pass through. Each one would be relative to the timeline at time of processing since each one could extend the timeline... Could prioritize those that extend the overall timeline, maybe.
- Add a group or folder feature. This could be used to group individual animations together. Other groups and animations can be configured to relative to them.
- Make it so individual groups, individual animations or all can be stopped on an animation. When new animations start running they should leave the currently running animations alone. New items should be able to be relative to existing running animations (and groups), but the running ones should not recalculate.
- Add ability to trigger events. 
- Add ability for multiple key frames (not just start and end)
- Add ability to use tanget, curves and other path stuff.
- Make it so there are listeners for completetion of individual animations or groups and  for all complete.
*/

/**
 * AnimationTimeline
 */
export default class AnimationTimeline {
	private enteriesByName: {[key: string]: AnimationEntryNode};
	private runningAnimatables: BABYLON.Animatable[];
	private callOnCompleteCount: number;
	private skipOnComplete: boolean;

	public speedRatio: number = 1;

	private unfroozen: BABYLON.Node[] = new Array();

	constructor(
		private scene: BABYLON.Scene
	)  {
		this.clear(); // Set starting values.
	}

	/**
	 * Add to the timeline
	 */
	public add(entries: AnimationEntry.AbstractEntry | AnimationEntry.AbstractEntry[]): void {
		if (Array.isArray(entries)) {
			// Adding multiple
			for (let i = 0, len = entries.length; i < len; i++) {
				let entry = entries[i];
				this.enteriesByName[entry.name] = {entry: entry, children: []};
			}
		} else {
			// Adding one ("entries" is actually just "entry")
			this.enteriesByName[entries.name] = {entry: entries, children: []};
		}
	}

	/**
	 * Stop any previous animation
	 */
	public stop(skipOnComplete: boolean = true) {
		if (skipOnComplete === true) {
			this.skipOnComplete = true;
		}

		for (let i = 0, len = this.runningAnimatables.length; i < len; i++) {
			if (this.runningAnimatables[i] !== undefined) {
				this.runningAnimatables[i].stop();
			}
		}

		this.clear();
	}

	public run(onComplete?: () => void) {
		// Build dependency tree and determine roots (those that have no dependencies / parents)
		// TODO Is it possible to end up with an infinite loop? I think so, stop it how?
		const rootEntryNodes: AnimationEntryNode[] = [];
		let animCount: number = 0;
		for (let name in this.enteriesByName) {
			if (this.enteriesByName.hasOwnProperty(name)) {
				const current = this.enteriesByName[name];
				const relativeToName = current.entry.positionRelativeToName;
				if (relativeToName === undefined) {
					rootEntryNodes.push(current);
				} else {
					const parent = this.enteriesByName[relativeToName];
					if (parent !== undefined) {
						parent.children.push(current);
					} else {
						rootEntryNodes.push(current);
						console.warn(`Animation with name "${name}" has "relativeToName" of "${relativeToName}" specified but an animation entry config by that name could not be found.`);
					}
				}
				animCount++;
			}
		}

		// Continue and if there are animations otherwise just call the onComplete function
		if (animCount > 0) {

			// Build animations
			const builtAnimationsByTarget: {[name: string]: {target: BABYLON.Node, animations: BABYLON.Animation[]}} = {};
			let highestFrame: number = 0;
			for (let i = 0, len = rootEntryNodes.length; i < len; i ++) {
				const node: AnimationEntryNode = rootEntryNodes[i];
				const currentHigh = this.buildEntryToRef(node, builtAnimationsByTarget);
				if (currentHigh > highestFrame) {
					highestFrame = currentHigh;
				}
			}

			// Begin animations
			for (let key in builtAnimationsByTarget) {
				if (builtAnimationsByTarget.hasOwnProperty(key)) {
					const {target, animations} = builtAnimationsByTarget[key];

					const animatable = this.scene.beginDirectAnimation(
						target, animations,
						0, highestFrame,
						false, // <- loop
						this.speedRatio,
						// onAnimationEnd
						() => {
							this.checkIfComplete(onComplete);
						}
					);
					this.runningAnimatables.push(animatable);
				}
			}
			if (this.runningAnimatables.length !== 0) {
				this.callOnCompleteCount = this.runningAnimatables.length;
			} else {
				if (onComplete !== undefined) onComplete();
				this.clear();
			}

		} else {
			if (onComplete !== undefined) onComplete();
			this.clear();
		}
	}

	private checkIfComplete(onComplete?: () => void): void {
		if (this.callOnCompleteCount === undefined)
			return;

		this.callOnCompleteCount--;
		if (this.callOnCompleteCount === 0) {
			if (this.skipOnComplete !== true && onComplete !== undefined) {
				// Freeze meshes again
				let unfroozenNode: BABYLON.Node;
				while (unfroozenNode = this.unfroozen.pop()) {
					BabylonHelper.SetFreezeWorldMatrix(unfroozenNode, true);
				}

				// We made it! Last animation.
				onComplete();
			}
			this.clear();
		}
	}
	private clear(): void {
		this.enteriesByName = {};
		this.runningAnimatables = [];
		this.callOnCompleteCount = undefined;
		this.skipOnComplete = false;
	}

	/**
	 * Build animations, and add them to the "builtAnimationsByTarget" parameter.
	 * This is done when the animation is ran.
	 * The BABYLON.Animation objects are stored on the "_cachedAnimation" of AnimationEntry.
	 * If the BABYLON.Animation was already built, its values are updated.
	 * 
	 * @returns The highest frame number of the built animation. If there are children (dependants), then the returned number is the highest of all animations 
	 */
	private buildEntryToRef(
		entryNode: AnimationEntryNode,
		builtAnimationsByTarget: {[name: string]: {target: BABYLON.Node, animations: BABYLON.Animation[]}},
		parentTimelineContext?: TimelineContext,
		parentAnimation?: BABYLON.Animation,
		isBuildChildren: boolean = true
	): number {
		const entry: AnimationEntry.AbstractEntry = entryNode.entry;
		let animation: BABYLON.Animation;
		let targetName;

		// Build entry (animation or event)
		if (entry instanceof AnimationEntry.Entry) {
			BabylonHelper.SetFreezeWorldMatrix(entry.targetObject, false);
			this.unfroozen.push(entry.targetObject);
			animation = entry.build(parentTimelineContext);
		} else if (entry instanceof AnimationEntry.EventEntry) {
			const animationEvent: BABYLON.AnimationEvent = entry.build(parentTimelineContext);
			if (animationEvent !== undefined && parentAnimation !== undefined) {
				parentAnimation.addEvent(animationEvent);
			} else {
				console.error("Could not add animation event to timeline because either the parent animation or result of building the event object are undefined.", parentAnimation, animationEvent)
			}
		} else {
			console.error("Type of entry for timeline not recognized.");
		}

		// Get timeline context
		const timelineContext = entry.getTimelineContext();
		
		// Build children
		let highestFrameOfChildren: number = 0;
		if (isBuildChildren === true && entryNode.children !== undefined) {
			const children = entryNode.children;
			for (let i = 0, len = children.length; i < len; i ++) {
				const currentHigh = this.buildEntryToRef(
					children[i],
					builtAnimationsByTarget,
					timelineContext,
					animation !== undefined ? animation : parentAnimation,
					isBuildChildren
				);
				if (currentHigh > highestFrameOfChildren) {
					highestFrameOfChildren = currentHigh;
				}
			}
		}
		
		// Add animation to list (if it does anything)
		if (animation !== undefined && entry instanceof AnimationEntry.Entry) {
			const keys = animation.getKeys();
			const keysLength = keys.length;
			if (keysLength > 1) {

				let valueChange: number = 0;
				let prevValue: any  = keys[0].value;
				for (let i = 1; i < keysLength; i++) {
					valueChange += entry.targetProperty.calcDistance(prevValue, keys[i].value);
				}
				
				const events = animation["_events"]; // Private property
				
				// Add animation to list if the value changes, or if there is an event attached to it.
				if (valueChange > 0 || events.length > 0) {
					// Add to the built animations list
					if (builtAnimationsByTarget[entry.targetName] === undefined) {
						builtAnimationsByTarget[entry.targetName] = {
							target: entry.targetObject,
							animations: [animation]
						};
					} else {
						builtAnimationsByTarget[entry.targetName].animations.push(animation);
					}
				}
			}
		}

		return timelineContext.highFrame > highestFrameOfChildren ? timelineContext.highFrame : highestFrameOfChildren;
	}

}

