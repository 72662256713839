import * as BABYLON from 'babylonjs';
import {Manager, ViewObserver, ViewEvent, ViewChangeEvent, DataLoadedEvent} from './Manager';
import * as ViewElement from './ViewElement';
import ElementTools from '../lib/ElementTools';
import * as NumberUtil from '../lib/NumberUtil';
import Helper from '../lib/BabylonHelper';
import {default as NodeStructureHelper, MeshNameParts} from './NodeStructureHelper';
import debounce from '../lib/debounce';

class NameList {
	values: string[];
	constructor(name: string) {
		this.values = [];

		if (name !== undefined) {
			// e.g. 'Link:0:a.b.c
			this.values.push(name);

			let index: number = name.lastIndexOf(':');
			if (index !== -1) {
				// e.g. 'a.b.c'
				this.values.push(name.substr(index + 1));
			}

			// e.g. 'b.c.', 'c'
			while ((index = name.indexOf('.', index + 1)) !== -1) {
				this.values.push(name.substr(index + 1));
			}
		}
	}

	run(func: (name: string) => any, defaultValue: any) {
		for (let name of this.values) {
			let result = func(name);
			if (result !== null && result !== undefined) {
				return result;
			}
		}
		return defaultValue;
	}
}

export class NameSelfToAncestors {
	values: string[];

	constructor(name: string) {
		this.values = [];

		if (name !== undefined) {
			this.values.push(name);

			let index: number = name.lastIndexOf(':');
			if (index !== -1) {
				name = name.substr(index + 1);
				this.values.push(name);
			}

			index = name.length;
			while ((index = name.lastIndexOf('.', index - 1)) !== -1) {
				this.values.push(name.substr(0, index));
			}
		}
	}

	run(func: (name: string) => any, defaultValue: any) {
		for (let name of this.values) {
			let result = func(name);
			if (result !== null && result !== undefined) {
				return result;
			}
		}
		return defaultValue;
	}
}

export class Node implements ViewObserver {
    parent: Node;
	children: Node[] = [];
	private _active: boolean = true;
	private _enabled: boolean = true;

	// TODO: These should likely be combined. Also potentially merge in NameSelfToAncestors 
	names: NameList;
	private _meshNameParts: MeshNameParts;

	constructor(
		protected manager: Manager,
		public mesh: BABYLON.AbstractMesh,
		public config: string
	) {
		this.names = new NameList(config);
		if (mesh !== undefined) {
			this._enabled = mesh.isEnabled();
		} else {
			this._enabled = false;
		}
	}

	protected setupAfterAllData() {
	}
	
	getLabel(): string {
		let linkTo: ViewElement.ViewElement = this.getView();
		if (linkTo !== null) {
			return linkTo.getName();
		}
		return this.name;
	}
	getAnnotationLabel(): string {
		return undefined;
	}

	get name(): string {
		return this.names.values.length > 0 ? this.names.values[0] : undefined;
	}
	set name(value: string) {
		this.names = new NameList(value);
		this._meshNameParts = undefined;
	}

	private initMeshNameParts(): void {
		if (this._meshNameParts === undefined) {
			this._meshNameParts = NodeStructureHelper.ParseMeshNameParts(this.name, true);
		}
	}
	/**
	 * Returns the name without the world's portion
	 */
	get nameNormalized(): string {
		this.initMeshNameParts();
		return this._meshNameParts.orginalMeshName;
	}
	get path(): string[] {
		this.initMeshNameParts();
		return this._meshNameParts.path;
	}
	get worldName(): string {
		this.initMeshNameParts();
		return this._meshNameParts.worldName;
	}



	getView(): ViewElement.ViewElement {
		return this.names.run((name: string) => {
			return this.manager.getView(name);
		}, null);
	}

	isEnabled(): boolean {
		return this._enabled;
	}
	setEnabled(value: boolean): void {
		this._enabled = value;
	}
	isActive(): boolean {
		return this._active;
	}
	setActive(value: boolean): void {
		this.setEnabled(true);
		this._active = value;
	}

    addChild(node: Node): void {
        if (node.parent !== undefined) {
			// Remove from any previous parent
            node.parent.removeChild(node);
		}

		// Set parent
		node.parent = this;
		// Add as child of self, ensuring children sorted based on node.name
		if (this.children.length === 0) {
			this.children.push(node);
		} else {
			const nodeName: string = node.name;
			let index = this.children.length;
			while (index > 0 && this.children[index - 1].name > nodeName) {
				index--;
			}
//			console.log('insert', nodeName, index);
			this.children.splice(index, 0, node);
		}
    }
    removeChild(node: Node): void {
        let index: number = this.children.indexOf(node);
        if (index !== -1) {
            this.children.splice(index, 1);
        }
        node.parent = undefined;
	}
	childIndexOf(node: Node): number {
		return this.children.indexOf(node);
	}
	findLinkToNode(node: Node): Link {
		if (node instanceof Link)
			return node;
		let index = this.findIndexOfLinkTo(node);
		if (index === undefined)
			return undefined;
		return this.children[index] as Link;
	}
	getPreviousNode(startWith: Node, predicate?:(node: Node) => boolean, useLinkToMe: boolean = true): Node {
		return this.find(startWith, -1, predicate, useLinkToMe);
	}
	getNextNode(startWith: Node, predicate?:(node: Node) => boolean, useLinkToMe: boolean = true): Node {
		return this.find(startWith, 1, predicate, useLinkToMe);
	}
	private findIndexOfLinkTo(node: Node): number {
		// Look for index of Link that sends ot startWith
		for (let i = 0; i < this.children.length; i++) {
			let child = this.children[i];
			if (child instanceof Link && child.getLinkTo() === node) {
				return i
			}
		}
		return undefined;
	}
	private find(startWith?: Node, step: number = 1, predicate?:(node: Node) => boolean, useLinkToMe: boolean = true): Node {
		let index;
		if (startWith === undefined) {
			if (step < 0) {
				index = this.children.length - 1;
			} else {
				index = 0;
			}
		} else {
			if (useLinkToMe === true && !(startWith instanceof Link)) {
				index = this.findIndexOfLinkTo(startWith);
			}
			if (index === undefined) {
				// Look for self
				index = this.children.indexOf(startWith);
			}
			if (index === -1 && step < 0) {
				index = this.children.length;
			} else {
				index += step; 
			}
		}
		for (; index >= 0 && index < this.children.length; index = index + step) {
			var node = this.children[index];
			if (predicate === undefined || predicate(node)) {
				return node;
			}
		}
		return undefined;
	}

	notify(event: ViewEvent): void {
		if (event instanceof DataLoadedEvent) {
			this.setupAfterAllData();

			// We do not care to be notified about anything else so let's unregister
			event.manager.unregisterObserver(this);
		}
	}
}

export class Link extends Node {
	private isSetup: boolean;

	private linkToNames: NameList;			// List of potential names from specific to broad. e.g.: single-family-homes.cabinet-pon-288, cabinet-pon-288
	
	private buttonContainerElem: HTMLElement;

	constructor(manager: Manager, mesh: BABYLON.AbstractMesh, config: string) {
		super(manager, mesh, config);
		this.linkToNames = new NameList(config);
	}
	protected setupAfterAllData() {
		super.setupAfterAllData();

		if (this.isSetup !== true) {
			// Create HTML label that can be clicked on. Position updated when Babylon rerenders.
			this.createLabelHtml();
			this.isSetup = true;
		}
	}

	setEnabled(value: boolean): void {
		super.setEnabled(value);
		if (this.mesh != undefined) {
			this.mesh.setEnabled(value);
		}
		if (this.buttonContainerElem !== undefined) {
			if (value === true) {
				this.buttonContainerElem.classList.add("isVisible");
			} else {
				this.buttonContainerElem.classList.remove("isVisible");
			}
			this.positionLabel();
		}
	}

	setActive(value: boolean): void {
		super.setActive(value);
		if (this.buttonContainerElem !== undefined) {
			if (value === true) {
				this.buttonContainerElem.classList.add("isActive");
				this.positionLabel();
			} else {
				this.buttonContainerElem.classList.remove("isActive");
			}
		}
	}

	/**
	 * Returns the Node representing the place in the model to send to.
	 * A value is always returned. If we are not moving where we are than
	 * return self. (e.g. if opening a Link to a modal we don't move around
	 * within the model)
	 */
	getLinkTo(): Node {
		let myName: string = this.name;
		return this.linkToNames.run((name:string) => {
			if (name === myName)
				return undefined;
			return this.manager.getStructureNode(name);
		}, this);
	}
	/**
	 * Overides in order to return the ViewElement for the Node returned by getLinkTo()
	 */
	 getView(): ViewElement.ViewElement {
		let linkTo = this.getLinkTo();

		if (linkTo !== this)
			return linkTo.getView();

		return this.linkToNames.run((name:string) => {
			return this.manager.getView(name);
		}, null);
	}

	/**
	 * Text to be used on label/annotations.
	 */
	getAnnotationLabel(): string {
		let linkTo: ViewElement.ViewElement = this.getView();

		if (linkTo !== null && linkTo instanceof ViewElement.Modal) {
			// Use [A], [B], etc with arrow
			let charCount = 0;
			if (this.parent !== undefined) {
				for (let i=0; i < this.parent.children.length; i++) {
					let child = this.parent.children[i];
					if (child === this)
						break;
					if (child instanceof Link) {
						let childView = child.getView();
						if (childView instanceof ViewElement.Modal) {
							charCount++;
						}
					}
				}
			}
			return String.fromCharCode(65 + charCount);
		}

		return undefined;
	}

	private createLabelHtml(): void {
		if (this.buttonContainerElem !== undefined) {
			return;
		}

		let label = this.getLabel();
		let annotationLabel: string = this.getAnnotationLabel();
		if (annotationLabel === undefined) {
			annotationLabel = label;
		}
		const linkTo: ViewElement.ViewElement = this.getView();
		const styleClass = "linkNode";
		let modifierStyleClass: string;

		if (linkTo instanceof ViewElement.World) {
			modifierStyleClass = "world";
		} else if (linkTo instanceof ViewElement.Place || linkTo instanceof ViewElement.Product) {
			let showLabels: boolean = false;
			if (this.parent !== undefined) {
				// If the parent is a World then we show labels
				let parentView = this.parent.getView();
				showLabels = parentView instanceof ViewElement.World;
			}

			if (showLabels) {
				modifierStyleClass = "placeSection";
			} else {	
				modifierStyleClass = "place";
			}
		} else if (linkTo instanceof ViewElement.Modal) {
			modifierStyleClass = "modal";
		}
		
		// Create container div for button
		const containerElem = document.createElement("div");
		containerElem.classList.add(styleClass);
		if (modifierStyleClass !== undefined) {
			containerElem.classList.add(styleClass + "--" + modifierStyleClass);
		}

		// Create button
		const buttonElem = document.createElement("div"); // "Button" didn't work in Firefox. aaah firefox.
		buttonElem.setAttribute('data-title', label);
		buttonElem.classList.add("linkNode__button");
		buttonElem.addEventListener("click", () => {
			this.manager.enter(this, true);
		}, false);
		containerElem.appendChild(buttonElem);

		// Create label in button
		const labelElem = document.createElement("span");
		labelElem.classList.add("linkNode__buttonLabel");
		labelElem.innerText = annotationLabel;
		buttonElem.appendChild(labelElem)

		// Create label in button
		const buttonIconElem = document.createElement("span");
		buttonIconElem.classList.add("linkNode__buttonIcon");
		buttonElem.appendChild(buttonIconElem)

		// Add to document
		/* TODO Do not add to document here. Instead have a document fragement passed to this
			which each Link Node gets which is after all are setup is then added to the document. */
		document.getElementById("LinkNodes").appendChild(containerElem);
		this.buttonContainerElem = containerElem;

		// Update position when the screen rerenders
		const scene: BABYLON.Scene = this.manager.getScene();
		//const positionLabelFunc = debounce(this.positionLabel.bind(this), 250);
		const positionLabelFunc = this.positionLabel.bind(this);
		scene.onAfterRenderObservable.add(positionLabelFunc);
	}
	private positionLabel(): void {
		if (this.isEnabled() === true) {
			// Enabled so position
			const pos = Helper.MeshPositionToViewPortSpace(this.mesh, false, this.manager.getScene());
			if (pos.isBehind === true) {
				this.buttonContainerElem.style.visibility = "hidden";
			} else {
				const visibility = this.buttonContainerElem.style.visibility;
				if (visibility !== undefined && visibility.length !== 0) {
					this.buttonContainerElem.style.visibility = "";
				}
				const x = NumberUtil.DecimalPoint(pos.x, 1);
				const y = NumberUtil.DecimalPoint(pos.y, 1);
				ElementTools.setTransform(this.buttonContainerElem, `translate(${x}px, ${y}px)`);
			}
		}
	}

} 
