import {Manager} from '../Manager';
import {AbstractViewWithModal} from './AbstractViewWithModal';

/**
 * Modal data
 */
export class Modal extends AbstractViewWithModal {
	constructor(protected manager: Manager, element: HTMLElement) {
		super(manager, element);
	}

	isPartial(): boolean {
		return true;
	}
}