export function DecimalPoint(num: number, length: number = 2): number { 
	let len = Math.pow(10, length);
	return Math.round(num * len) / len;
}

export function HalfDecimal(num: number): number { 
	return Math.round(num * 2) / 2;
}

export function Clamp(value: number, min?: number, max?: number): number {
	if (min !== undefined && value <= min) {
		return min;
	} else if (max !== undefined && value >= max) {
		return max;
	}
	return value;
}

export function ProportionalClamp(
		value: number,
		inputLow: number, inputHigh: number,
		outputLow: number, outputHigh: number
	): number {
	const ratio = (outputHigh - outputLow) / (inputHigh - inputLow);
	const valBeforeClamp = (value - inputLow) * ratio + outputLow;
	const isOHighMax = outputHigh >= outputLow;
	const min = isOHighMax ? outputLow : outputHigh;
	const max = isOHighMax ? outputHigh : outputLow;
	return Clamp(valBeforeClamp, min, max);
}
