
import * as BABYLON from 'babylonjs';

const nodeSetEnabled = BABYLON.Node.prototype.setEnabled;
BABYLON.Node.prototype.setEnabled = function(value: boolean): void {
	nodeSetEnabled.call(this, value);
	if (instance !== undefined) {
		instance.clearCache();
	}
}

let instance: ActiveMeshCandidateProvider;

export default class ActiveMeshCandidateProvider {
	public readonly checksIsEnabled = true;

	private rootNode: BABYLON.Mesh;
	private filteredChildren: BABYLON.ISmartArrayLike<BABYLON.AbstractMesh>;

	private constructor() {}

	public static getInstance() {
		if (instance === undefined) {
			instance = new ActiveMeshCandidateProvider();
		}
		return instance;
	}

	public getMeshes(scene: BABYLON.Scene): BABYLON.ISmartArrayLike<BABYLON.AbstractMesh> {
		if (this.filteredChildren === undefined && this.rootNode !== undefined) {
			this.filteredChildren = {
				data: [],
				length: 0
			};
			this.filterChildrenToRef(this.rootNode.getChildren(), this.filteredChildren.data);
			this.filteredChildren.length = this.filteredChildren.data.length;
		}
		return this.filteredChildren;
	}

	public clearCache(): void {
		this.filteredChildren = undefined;
	}

	private filterChildrenToRef(nodes: BABYLON.Node[], results: BABYLON.AbstractMesh[]) {
		var node, isEnabled;
		for (var i = 0, len = nodes.length; i < len; i++) {
			node = nodes[i];
			isEnabled = node.isEnabled(false);
			if (isEnabled === true) {
				if (node instanceof BABYLON.AbstractMesh) {
					results.push(node);
				}
				this.filterChildrenToRef(node.getChildren(), results);
			}
		}
	}

	public setRootNode(node: BABYLON.Mesh): void {
		this.rootNode = node;
	}
}