import * as BABYLON from 'babylonjs';
import {Main, MainConfig} from './Main';
import GetQueryParameters from './lib/GetQueryParameters';

// Config
const params = GetQueryParameters();
const standalone: boolean = window.matchMedia('(display-mode: standalone)').matches || params.mode === 'standalone';
const config: MainConfig = {
	autoTour: {
		enabled: (params.idle || params.auto !== undefined ? true : false),
		idleDuration: params.idle !== undefined ? parseInt(params.idle) : 60000,
		progressEvery: params.auto !== undefined && params.auto !== '' ? parseInt(params.auto) : 20000
	}, 
	offline: {
		enabled: (standalone && 'serviceWorker' in navigator)
	},
	toolbar: {
		enabled: standalone
	}
};


const main: Main = new Main(config);
window.addEventListener('DOMContentLoaded', () => {
	const canvas: HTMLCanvasElement = document.getElementById('renderCanvas') as HTMLCanvasElement;

	// Check browser
	const oldBrowserFooterMsgElem = document.getElementsByClassName('optimizedForModernBrowser').item(0);
	const oldBrowserModalElem = document.getElementsByClassName('modal--optimizedForModernBrowser').item(0);

	const webGlVer = getWebGlVersion(canvas);
	const ua: string = navigator.userAgent.toLowerCase();
	const isSupportedBrowser =
		(webGlVer >= 2)
		|| (webGlVer >= 1 && (
			ua.indexOf('edge/') !== -1
			|| ua.indexOf('chrome/') !== -1
			|| ua.indexOf('firefox/') !== -1
			|| ua.indexOf('safari/') !== -1
		)
	);

	if (isSupportedBrowser) {
		oldBrowserFooterMsgElem.parentElement.removeChild(oldBrowserFooterMsgElem);
		start(canvas, oldBrowserModalElem);
	} else {
		// Browser has no WebGL support or has WebGL 1 support
		oldBrowserFooterMsgElem.classList.add('optimizedForModernBrowser--recommendOther');
		oldBrowserModalElem.classList.add('modal--isActive');

		if (webGlVer >= 1) {
			oldBrowserModalElem.classList.add('optimizedForModernBrowser--canContinue');
			const closeButtons = document.getElementsByClassName('optimizedForModernBrowserModal__closer');
			const startFunc = (event: MouseEvent) => {
				event.preventDefault();
				start(canvas, oldBrowserModalElem);
			};
			for (var i = 0; i < closeButtons.length; i++) {
				closeButtons[i].addEventListener('click', startFunc, false);
			}
		}
	}
});

function start(canvas: HTMLCanvasElement, oldBrowserModalElem: Element) {
	oldBrowserModalElem.parentElement.removeChild(oldBrowserModalElem);
	main.init(canvas);
}

function getWebGlVersion(canvas: HTMLCanvasElement): number {
	let gl: any;

	// Any browser that supports WebGL 2
	try {
		gl = canvas.getContext("webgl2") || canvas.getContext("experimental-webgl2");
		if (gl !== null && gl !== undefined) {
			return 2;
		}
	} catch (e) {}

	// Specific browsers that support WebGL 1
	if (gl === null || gl === undefined) {
		gl = canvas.getContext("webgl") || canvas.getContext("experimental-webgl");
		if (gl !== null && gl !== undefined) {
			return 1;
		}
	}

	return 0;
}
