import * as BABYLON from 'babylonjs';
import {Manager} from '../Manager';
import * as Structure from '../Structure';
import {ViewElement} from '../ViewElement';

export class Layout extends ViewElement {
	constructor(manager: Manager, element: HTMLElement) {
		super(manager, element);
	}
	
	isIncludeNav(): boolean {
		return this.element.dataset.includenav === 'true';
	}
}
