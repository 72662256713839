import {Manager} from '../Manager';
import {AbstractViewWithModal} from './AbstractViewWithModal';

/**
 * Product data
 */
export class Product extends AbstractViewWithModal {
	constructor(protected manager: Manager, element: HTMLElement) {
		super(manager, element);
	}
}