import * as BABYLON from 'babylonjs';
import * as ViewElement from './ViewElement';
import * as Structure from './Structure';

export class Manager {
	private observers: ViewObserver[] = [];

	private views: {[key: string]: ViewElement.ViewElement} = {}; // TODO Another way to specify the type is using an interface (search for "Index types and string index signatures"): https://www.typescriptlang.org/docs/handbook/advanced-types.html

	private nodes: {[key: string]: Structure.Node} = {};
	public universeNode: Structure.Node;
	private currentNode: Structure.Node = null;
	
	constructor(private scene: BABYLON.Scene, universeMesh: BABYLON.Mesh) {
		this.universeNode  = new Structure.Node(this, universeMesh, universeMesh.name);
		//this.universeNode.name = universeMesh.name;
		this.addNode(this.universeNode);
	}
	
	getScene(): BABYLON.Scene {
		return this.scene;
	}

	getView(id: string): ViewElement.ViewElement {
		if (!this.views.hasOwnProperty(id))
			return null;
		return this.views[id];
	}

	addView(view: ViewElement.ViewElement): void {
		let id: string = view.getId();
		this.views[id] = view;
} 
	getStructureNodes(): {[key: string]: Structure.Node} {
		return this.nodes;
	}

	getStructureNode(id: string): Structure.Node {
		if (!this.nodes.hasOwnProperty(id))
			return null;
		return this.nodes[id];
	}

	addStructureNode(mesh: BABYLON.AbstractMesh, fallbackType: any = undefined): Structure.Node {
		let name = mesh.name;

		// Create new node if not already exist
		let node: Structure.Node = this.getStructureNode(name);
		if (node === null) {
			let index = name.indexOf(':');

			if (fallbackType === undefined) {
				let isEmpty: boolean = mesh.isVisible === false && mesh.getTotalVertices() === 0;
				if (isEmpty) {
					fallbackType = Structure.Node;
				}
			}

			if (index === -1 && fallbackType === undefined) {
				// We include all empties, ones with name containing a ':', or if a fallbackType is specified (which we do so we create all ancestors)
				return undefined;
			}

			let type: string;
			if (index !== -1) {
				type = name.substr(0, index);
			}

			let classToUse;
			if (type !== undefined && Structure[type] !== undefined) {
				classToUse = Structure[type];
			} else if (fallbackType !== undefined) {
				classToUse = fallbackType;
			} else {
				// No type specified and no fallback type
				return undefined;
			}

			node = new classToUse(this, mesh, name);

			if (node.getView() === null) {

				// TODO: Find better approach to determining what to include in structure? Currently we require
				//		getView() to return something and the mesh must ben an Empty

				// No data matches up with the mesh so do not include
				node = null;
			} else {
				node.setEnabled(false);

				this.addNode(node);
			}
		}

		return node;
	}
	private addNode(node: Structure.Node) {
		this.nodes[node.name] = node;
		this.registerObserver(node);
	}

	getCurrentNode(): Structure.Node {
		return this.currentNode;
	}

	enter(node: Structure.Node, followLink: boolean = false): void {
		if (node === null)
			return;

		if (followLink && node instanceof Structure.Link) {
			node = (node as Structure.Link).getLinkTo();
		}

		if (this.currentNode === node) {
			// Nothing to do since location did not change.
			return;
		}
		
		// Notify observers
		let event = new ViewChangeEvent(this, this.currentNode, node);
		this.notifyObservers(event);

		this.currentNode = node;
	}
	
	registerObserver<T extends ViewObserver>(observer: T) : T {
		if (this.observers.indexOf(observer) === -1) {
			// (NOTE: See comments inside notifyObservers(..) for
			// explanation of why we store in reverse order.)
			this.observers.unshift(observer);
		}
		return observer;
	}

	unregisterObserver(observer: ViewObserver) : void {
		let index:number = this.observers.indexOf(observer);
		if (index !== -1) {
			this.observers.splice(index, 1);
		}
	}
	
	notifyObservers(event: ViewEvent) : void {
		/*
		console.log('Notifying of', event, this.observers);
		if (event instanceof ViewChangeEvent) {
			console.log('	View change: ', event.leaving !== null ? event.leaving.name : '--', ' => ', event.entering !== null ? event.entering.name : '--');
		}
		*/

		// Note that observers are stored in reverse order. Doing
		// this allows for observers to unregister themself without
		// it messing up this loop. (If weren't in reverse and one
		// unregistered we'd end up skipping the next index because
		// the index of the next element would suddenly decrase by one.)
		for (let i = this.observers.length - 1; i >= 0; i--) {
			let observer = this.observers[i];
			observer.notify(event);
		}
	}

	/**
	 * TODO: Our model is really really small. I believe it is that way in blender?... i don't know... Our scale is set so when you are RIGHT up next to it, it is very close to the size defined.
	 * Modify mesh to be scaled to our model from Blender, and bake those values in.
	 * This method will likely be a temporary solution. I have it here so we can easily
	 * use it in many spots, and then find those spots later if we find a different solution.
	 */
	static bakeToSceneScale(mesh: BABYLON.Mesh) {
		let scale: number = 0.004;
		mesh.bakeTransformIntoVertices(BABYLON.Matrix.Scaling(scale, scale, scale));
	}
}

export interface ViewObserver {
	notify(event: ViewEvent) : void;
}
export interface ViewEvent {
	manager: Manager;
}
export class ViewChangeEvent implements ViewEvent {
	constructor(public manager: Manager, public leaving: Structure.Node, public entering: Structure.Node) {
	}
}
export class DataLoadedEvent implements ViewEvent {
	constructor(public manager: Manager) {
	}
}