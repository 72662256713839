import * as BABYLON from 'babylonjs';
import * as Structure from './Structure';
import {ViewElement, TwoWorld, World, Place, Product} from './ViewElement';

export interface MeshNameParts {
	type: string,
	path: string[],
	worldName?: string,
	orginalMeshName?: string
}

/** 
 * Helper class for nodes
 */
export default class NodeStructureHelper {
	public static WorldNames: {telecom: string, cable: string} = {
		telecom: "telecom",
		cable: "cable"
	};

	/**
	 * 
	 */
	public static GetMesh(scene: BABYLON.Scene, worldName: string, orginalMeshName: string, fallbackToOrginalMeshName: boolean = false): BABYLON.AbstractMesh {
		const colonIndex: number = orginalMeshName.lastIndexOf(":");
		const type: string = colonIndex !== -1 ? orginalMeshName.substr(0, colonIndex + 1) : undefined;
		let actualMeshName: string = type !== undefined ? type + ":" : "";
		actualMeshName += worldName !== undefined ? worldName + "." + orginalMeshName : orginalMeshName;

		let mesh = scene.getMeshByName(actualMeshName);
		if (fallbackToOrginalMeshName === true) {
			let mesh = scene.getMeshByName(orginalMeshName);
		}
		return mesh === null ? undefined : mesh;
	}

	/**
	 * 
	public static ParseDepth(meshFullName: string): number {
		return NodeStructureHelper.ParseMeshNameParts(meshFullName, false).path.length;
	}
	 */

	/**
	 * Converts a full mesh name into various pieces of data. Returned contains:
	 * 	<li>type - e.g. "link"
	 * 	<li>path - An array of the path split at each ".". This will include the worldName in the first
	 * 			slot if the full name passed contained the name (unless parseWorldInfoOut === true)
	 * 
	 * If parseWorldInfoOut is true the returned object will also contain:
	 * 	<li>worldName - If the world name is the first entry in the path, this property will be set to the value.
	 * 	<li>originalMeshName - A reconstructed mesh name after extracting the worldname (if in the passed name). If the worldName is the only name in meshFullname, then it will be kept (so "telecom" is "telecom")
	 *  <li>path - The first entry will not be the worldName.
	 * 	
	 * Works on names before and after modified when setting up the universe.
	 */
	public static ParseMeshNameParts(meshFullName: string, parseWorldInfoOut: boolean = false): MeshNameParts {
		// Determine type and path[]
		const colonIndex = meshFullName.lastIndexOf(":"); // TODO What if the name contains "link:0:asdf". Isn't that being done?
		const pathAsString: string = meshFullName.substr(colonIndex + 1);
		let parts: MeshNameParts = {
			type: colonIndex !== -1 ? meshFullName.substr(0, colonIndex + 1) : "",
			path: pathAsString.split(".")
		};

		if (parseWorldInfoOut === true) {
			// Determine worldName
			const path0 = parts.path[0];
			if (path0 !== undefined) {
				for (let key in NodeStructureHelper.WorldNames) {
					if (NodeStructureHelper.WorldNames.hasOwnProperty(key)) {
						let worldName = NodeStructureHelper.WorldNames[key];
						if (path0 === worldName) {
							parts.worldName = parts.path.shift();
							break;
						}
					}
				}
			}

			// Determine meshBaseName
			const partsLen = parts.path.length;
			if (parts.worldName !== undefined && partsLen === 0) {
				parts.orginalMeshName = parts.type + parts.worldName;
			} else {
				parts.orginalMeshName = parts.type;
				for (let i = 0; i < partsLen; i++) {
					parts.orginalMeshName += (i !== 0) ? "." + parts.path[i] : parts.path[i];
				}
			}
		}

		return parts;
	}

	/**
	 * Rename function used by Main.ts when universe is being created.
	 */
	public static Rename(sourceName: string, prefixPath: string): string {
		const {type, path} = NodeStructureHelper.ParseMeshNameParts(sourceName);
		let newName;
		if (path[0] === prefixPath) {
			return type + path.join(".");
		}
		return type + prefixPath + "." + path.join(".");
	}

	/**
	 * Given a node returns an object that specifies what should be considered previous and next.
	 * 
	 * @param node 
	 */
	public static DetermineNavigation(node: Structure.Node): {previous: Structure.Node, next: Structure.Node} {
		let previousNode: Structure.Node;
		let nextNode: Structure.Node;

		// TODO: could optimize creation of notSelfFuncCreator below
		// TOOD: optimize where node has children just made up of Link

		let check: Structure.Node;

		// Create filter used when looking up next/previous Link that will exclude node and any
		// node that links to node
		let notSelfFuncCreator = function(self: Structure.Node): (node:Structure.Node) => boolean {
			return (child) => {
				if (child === self)
					return false;
				if (child instanceof Structure.Link && child.getLinkTo() !== self)
					return true;
				return false;
			};
		};

		// Previous
		if (node.parent !== undefined) {
			previousNode = node.parent.getPreviousNode(node, notSelfFuncCreator(node));
			if (previousNode === undefined) {
				// No previous sibling so if exist use parent (or the node that links to the parent)
				if (node.parent.parent !== undefined) {
					previousNode = node.parent.parent.findLinkToNode(node.parent);
					if (previousNode === undefined) {
						previousNode = node.parent;
					}
				}
			} else {
				// Found previous sibling Link but to have previous be the opposite of 'next' we must
				// now find the last greatest descendant of that sibling
				check = previousNode instanceof Structure.Link ? previousNode.getLinkTo() : previousNode;
				while (check.children.length > 0) {
					let potential = check.getPreviousNode(undefined, notSelfFuncCreator(undefined));
					if (potential === undefined) {
						break;
					} else {
						previousNode = potential;
						check = previousNode instanceof Structure.Link ? previousNode.getLinkTo() : previousNode;
					}
				}
			}
		}

		// Next
		check = node instanceof Structure.Link ? node.getLinkTo() : node;
		nextNode = check.getNextNode(undefined, (child) => {
			return child instanceof Structure.Link;
		});
		while (nextNode === undefined && check !== undefined && check.parent !== undefined) {
			nextNode = check.parent.getNextNode(check, notSelfFuncCreator(check) );
			if (nextNode === undefined) {
				check = check.parent;
			}
		}

		return {previous: previousNode, next: nextNode};
	}
}