export default class GoogleAnalytics {
	private static gaLastUrl: string;

	private static _baseHref: string;
	private static get baseHref() {
		if (this._baseHref === undefined) {
			this._baseHref = (<HTMLBaseElement> document.getElementsByTagName("base")[0]).href;
		}
		return this._baseHref;
	}

	private static _gaTrackingId: string;
	private static get gaTrackingId() {
		if (this._gaTrackingId === undefined) {
			this._gaTrackingId = window["GA_TRACKING_ID"];
		}
		return this._gaTrackingId;
	}

	private static _gtag: (methodName: string, param: string, parameters?: object) => void;
	private static get gtag() {
		if (this._gtag === undefined) {
			this._gtag = window["gtag"];
		}
		return this._gtag;
	}

	public static onClickTrackLink(event: MouseEvent) {
		// TODO Should have a callback option because we shouldn't proceed with alink until the callback is returned (if that link is not opening a new window)
		const url = event.currentTarget["href"];
		this.trackEvent("onClickTrackLink", "Link", "Click", url);
	}


	public static trackEvent(eventName: string, category?: string, action?: string, label?: string, value?: number): void {
		const parameters = {"transport_type": "beacon"};
		if (category !== undefined) {
			parameters["event_category"] = category;
		}
		if (action !== undefined) {
			parameters["event_action"] = action;
		}
		if (label !== undefined) {
			parameters["event_label"] = label;
		}
		if (value !== undefined) {
			parameters["value"] = value;
		}

		if (this.gtag !== undefined) {
			this.gtag("event", eventName, parameters);
		} else {
			console.log("GoogleAnalytics.trackEvent:", eventName, parameters);
		}
	}

	public static trackPage(url: string, title?: string): void {
		// Clean URL so it starts with "/" and excludes the baseHref
		if (this.baseHref !== undefined && url.indexOf(this.baseHref) === 0) {
			url = url.substring(this.baseHref.length);
		}
		if (url.charAt(0) !== "/") {
			url = "/" + url;
		}
		
		// Report the URL if different from the last one reported
		if (url !== this.gaLastUrl) {
			const parameters = {"page_path": url};
			if (title !== undefined) {
				parameters["page_title"] = title;
			}
			this.gaLastUrl = url;

			if (this.gtag !== undefined) {
				this.gtag("config", this.gaTrackingId, parameters);
			} else {
				console.log("GoogleAnalytics.trackPage:", this.gaTrackingId, parameters);
			}
		}
	}
}

// Bind method so it can be used directly with addEventListener.
GoogleAnalytics.onClickTrackLink = GoogleAnalytics.onClickTrackLink.bind(GoogleAnalytics);
