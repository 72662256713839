import * as BABYLON from 'babylonjs';
import {Manager} from '../Manager';
import * as Structure from '../Structure';
import {ViewElement} from '../ViewElement';
import BabylonHelper from '../../lib/BabylonHelper';
import ElementTools from '../../lib/ElementTools';
import * as NumberUtil from '../../lib/NumberUtil';
import debounce from '../../lib/debounce';

export abstract class AbstractWorld extends ViewElement {
	private isSetup: boolean;
	private isVisible: boolean;
	private worldInfoElement: HTMLElement;
	private _baselinePosition: {x: number, y: number};
	private targetCamera: BABYLON.TargetCamera;

	constructor(manager: Manager, element: HTMLElement) {
		super(manager, element);

		const scene = manager.getScene();

		this.worldInfoElement = this.element.getElementsByClassName("worldInfo")[0] as HTMLElement;
		this.targetCamera = scene.getCameraByName(element.id + ".camera") as BABYLON.TargetCamera;
	}

	protected abstract getMeshMarker(): BABYLON.AbstractMesh;

	public display(node: Structure.Node, manager: Manager, activeStyleClass: string) {
		super.display(node, manager, activeStyleClass);
		
		if (this.isSetup !== true) {
			const scene = manager.getScene();

			// Setup new targetCamera with correct rotation and position but using same as activeCamera
			const cameraRotation = this.targetCamera.rotation;
			const cameraPosition = this.targetCamera.position;
			this.targetCamera = scene.activeCamera.clone(this.targetCamera.name) as BABYLON.TargetCamera;
			this.targetCamera.rotation = cameraRotation;
			this.targetCamera.position = cameraPosition;

			// Clear baseline cache on resize
			window.addEventListener("resize", () => {
				this._baselinePosition = undefined;
			});

			// Update position on render
			//const positionFunc = debounce(this.position.bind(this), 250);
			const positionFunc = this.position.bind(this);
			scene.onAfterRenderObservable.add(positionFunc);

			this.isSetup = true;
		}
		
		this.isVisible = true;
		this.position();
	}
	
	public hide(node: Structure.Node, manager: Manager, activeStyleClass: string) {
		super.hide(node, manager, activeStyleClass);
		this.isVisible = false;
	}
	
	private getBaselinePosition(): {x: number, y: number} {
		if (this._baselinePosition === undefined) {
			const mesh = this.getMeshMarker();
			const camera = this.targetCamera;
			this._baselinePosition = BabylonHelper.MeshPositionToViewPortSpace(
				mesh,
				false,
				this.manager.getScene(),
				camera,
			);
		}
		return this._baselinePosition;
	}

	private position() {
		if (this.isVisible === true) {
			const pos = BabylonHelper.MeshPositionToViewPortSpace(
				this.getMeshMarker(),
				false,
				this.manager.getScene()
			);
			if (pos.isBehind === true) {
				this.worldInfoElement.style.visibility = "hidden";
			} else {
				const visibility = this.worldInfoElement.style.visibility;
				if (visibility !== undefined && visibility.length !== 0) {
					this.worldInfoElement.style.visibility = "";
				}
				const baselinePosition = this.getBaselinePosition();
				const x = NumberUtil.DecimalPoint(pos.x - baselinePosition.x, 2);
				const y = NumberUtil.DecimalPoint(pos.y - baselinePosition.y, 2);
				ElementTools.setTransform(this.worldInfoElement, `translate(${x}px, ${y}px)`);
			}
		}
	}
}
