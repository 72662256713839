import * as BABYLON from 'babylonjs';
import * as View from './view/View';
import {AutoTour, AutoTourConfig} from './view/AutoTour';
import EnhancedFreeCamera from './babylon/EnhancedFreeCamera';
import ActiveMeshCandidateProvider from './babylon/ActiveMeshCandidateProvider';
import ViewControl from './view/VisualControl';
import StateControl from './view/StateControl';
import LocalNavigation from './view/LocalNavigation';
import {default as BabylonHelper, DuplicateNodeMode as DuplicateNodeMode} from './lib/BabylonHelper';
import NodeStructureHelper from './view/NodeStructureHelper';
import {default as ModelLoader, LoadInfo} from './ModelLoader';
import AirplaneControl from './view/AirplaneControl';
import Clouds from './view/Clouds';
import JobQueue from './lib/JobQueue';
import GoogleAnalytics from './lib/GoogleAnalytics';

let rootUrl = 'assets/target/models/';

export interface OfflineConfig {
	enabled: boolean;
}
export interface ToolbarConfig {
	enabled: boolean;
}
export interface MainConfig {
	autoTour: AutoTourConfig,
	offline: OfflineConfig,
	toolbar: ToolbarConfig
}
export interface RenderNeeded {
	prerenderUpdate: () => void,
	isRenderNeeded: () => boolean
}
export interface OnNoRenders {
	onNoRender: () => void
}
export class Main {
	// TODO: Move to wherever we want to store config
	private static screenWidth_linear: number = 600; // NOTE: Needs to match value inside global.css

	private renderNeeded: RenderNeeded[]; // TODO Not a good name..
	private onNoRenders: OnNoRenders[];
	
	private config: MainConfig;

	private autoTour: AutoTour;

	private canvas: HTMLCanvasElement;
	private engine: BABYLON.Engine;
	private scene: BABYLON.Scene;

	private forceRerender: boolean = true;
	private lastPosition: BABYLON.Vector3 = null;
	private lastRotation: BABYLON.Vector3 = null;

	private activeMeshCandidateProvider:ActiveMeshCandidateProvider;

	constructor(config: MainConfig) {
		// TODO, remove this line. For debugging: BABYLON.Tools.PerformanceLogLevel = 2;
		this.config = config;

		if (config.offline.enabled) {
			this.setupOffline();
		}
	}

	private setupOffline() {
		// TODO: I had to setup gulpfile.js to create offline.js at root of project. I couldn't get it working without doing that and I THINK i remember long ago reading about the script having to be at that level... look into
		const serviceWorkerScript = 'offline.js';
		const scope = './';

		// Class on body that denotes in offline mode
		document.body.classList.add('isOffline');

		navigator.serviceWorker.addEventListener('message', function (event) {
			if (event.data.action === 'new-version-ready') {
				if (confirm('A new version was just downloaded. Click OK to load the new version.')) {
					location.reload();
				}
			}
		});

		navigator.serviceWorker.register(serviceWorkerScript, { scope: scope }).then(function (reg) {
			if (reg.installing) {
				console.log('Service worker installing');
			} else if (reg.waiting) {
				console.log('Service worker installed');
			} else if (reg.active) {
				// Installed previously
				console.log('Service worker active');

				if (navigator.onLine) {
					console.log('	Online so check for update');
					reg.update(); // Update service worker script
					reg.active.postMessage({ action: 'check-for-updates' }); // Tell service worker to check server for update in cache
				}
			}
		}).catch(function (error) {
			// Registration failed
			console.error('Registration failed with ' + error);
		});
	}

	init(canvasElement: HTMLCanvasElement) {
		this.canvas = canvasElement;

		this.onRender = this.onRender.bind(this);
		this.onWinResize = this.onWinResize.bind(this);

		const loadingScreenElem = document.getElementById("LoadingScreen");
		loadingScreenElem.classList.add("isLoading");

		document.title = "Loading...";

		// Create engine and scene
		this.engine = new BABYLON.Engine(
			this.canvas,
			true,	// antialias
			{},		// options
			true	// adaptToDeviceRatio
		);
		this.scene = new BABYLON.Scene(this.engine);
		this.scene.clearColor = new BABYLON.Color4(0, 0, 0, 0);

		// Simple performance items
		this.scene.shadowsEnabled  = false;
		this.scene.fogEnabled  = false;
		this.scene.texturesEnabled  = false;
		this.scene.particlesEnabled = false;
		this.scene.spritesEnabled = false;
		this.scene.skeletonsEnabled = false;
		this.scene.lensFlaresEnabled = false;
		this.scene.collisionsEnabled = false;
		this.scene.postProcessesEnabled = false;
		this.scene.renderTargetsEnabled = false;
		this.scene.probesEnabled = false;
		this.scene.proceduralTexturesEnabled = false;
		this.scene.dispatchAllSubMeshesOfActiveMeshes = true; // I added this option to avoid checking isInFrustum(...) on all submeshes of active meshes. Due to the number of meshes and submeshes we have this actually addes time so I added an option to avoid it.
		BABYLON.PerfCounter.Enabled = false;
		this.scene.pointerMovePredicate = function(Mesh: BABYLON.AbstractMesh): boolean {
			// If pointerMovePredicate is not set, Babylon uses a default one. We are not using
			// the picker so we can cut out quite a bit of work by setting this and returning false.
			return false;
		};

		this.activeMeshCandidateProvider = ActiveMeshCandidateProvider.getInstance();
		this.scene.getActiveMeshCandidates = () => {
			return this.activeMeshCandidateProvider.getMeshes(this.scene);
		};

		BABYLON.StandardMaterial.FresnelEnabled = false;
		BABYLON.StandardMaterial.AmbientTextureEnabled = false;
		BABYLON.StandardMaterial.BumpTextureEnabled = false;
		BABYLON.StandardMaterial.ColorGradingTextureEnabled = false;
		BABYLON.StandardMaterial.DiffuseTextureEnabled = false;
		BABYLON.StandardMaterial.EmissiveTextureEnabled = false;
		BABYLON.StandardMaterial.LightmapTextureEnabled = false;
		BABYLON.StandardMaterial.OpacityTextureEnabled = false;
		BABYLON.StandardMaterial.ReflectionTextureEnabled = false;
		BABYLON.StandardMaterial.RefractionTextureEnabled = false;
		BABYLON.StandardMaterial.SpecularTextureEnabled = false;

		// Loading screen - Remove the default.
		this.engine.loadingScreen = {
			displayLoadingUI: function(){},
			hideLoadingUI: function(){},
			loadingUIBackgroundColor: "", // Really stupid interface.
			loadingUIText: ""
		};

		// Camera
		const camera: EnhancedFreeCamera = new EnhancedFreeCamera(
			'mainCamera',
			new BABYLON.Vector3(-18,  10, -18),
			this.scene
		);
		camera.speed = 0.05;
		camera.inertia = 0.9;
		camera.minZ = 0.01; // By default this is 1 which causes inside of things to be seen when shouldn't. Can't do 0 because their code actually changes that to 0.1 which is too big for our world's scale.
		camera.maxZ = 50; // Bring it down. Our world is pretty small.
		camera.setTarget(new BABYLON.Vector3(6, 0, 6));
		camera.attachControl(this.canvas, false);

		// Stop user from being able to use their keyboard to move around
		camera.inputs.removeByType("FreeCameraKeyboardMoveInput");

		// Hemi light for bottom
		const hemiLight = new BABYLON.HemisphericLight('hemiLight', new BABYLON.Vector3(-0.5, -1, -0.5), this.scene);
		hemiLight.intensity = 0.2;

		/// Two point lights
		const pointLight = new BABYLON.PointLight('pointLight', new BABYLON.Vector3(-14, 35, -18), this.scene);
		pointLight.intensity = 0.50;

		const pointLight_2 = new BABYLON.PointLight('pointLight_2', new BABYLON.Vector3(8, 35, 22), this.scene);
		pointLight_2.intensity = 0.50;

		/* TODO Temporary
		const pointLight_marker = BABYLON.MeshBuilder.CreateSphere('pointLight_marker', {diameter: 3}, this.scene);
		const pointLight_2_marker = BABYLON.MeshBuilder.CreateSphere('pointLight_marker_2', {diameter: 3}, this.scene);
		
		const gui = new window["dat"].GUI();
		let controls = [];
		
		var folder_1 = gui.addFolder('pointLight');
		controls.push(folder_1.add(pointLight, 'intensity', -1, 3));
		controls.push(folder_1.add(pointLight.position, 'x', -100, 100));
		controls.push(folder_1.add(pointLight.position, 'y', -100, 100));
		controls.push(folder_1.add(pointLight.position, 'z', -100, 100));
		
		var folder_2 = gui.addFolder('pointLight_2');
		controls.push(folder_2.add(pointLight_2, 'intensity', -1, 3));
		controls.push(folder_2.add(pointLight_2.position, 'x', -100, 100));
		controls.push(folder_2.add(pointLight_2.position, 'y', -100, 100));
		controls.push(folder_2.add(pointLight_2.position, 'z', -100, 100));
		
		const updateLightMarkerPosition = () => {
			pointLight_marker.position.copyFrom(pointLight.position);
			pointLight_2_marker.position.copyFrom(pointLight_2.position);
			pointLight_marker.position.y = 1;
			pointLight_2_marker.position.y = 1;
			this.forceRerender = true;
		};
		controls.forEach((control) => {
			control.onChange(updateLightMarkerPosition);
		});
		updateLightMarkerPosition();
		*/
		

		// Load Models
		this.onModelsLoaded = this.onModelsLoaded.bind(this);
		const modelLoader = new ModelLoader(
			this.scene,
			this.onModelsLoaded,
			(loadInfo: LoadInfo, message: string, exception: any) => {
				console.error('Error when loading model', loadInfo, message, exception);
			}
		);
		
		modelLoader.add(rootUrl, 'ground.babylon');
		modelLoader.add(rootUrl, 'sfh.babylon');
		modelLoader.add(rootUrl, 'mdu.babylon');
		modelLoader.add(rootUrl, 'co.babylon');
		modelLoader.add(rootUrl, 'ct.babylon');
		modelLoader.add(rootUrl, 'hfc.babylon');
		modelLoader.add(rootUrl, 'bc.babylon');
		modelLoader.add(rootUrl, 'all-interiors.babylon');
		modelLoader.add(rootUrl, 'airplane.babylon');

		modelLoader.load();
	}

	private onModelsLoaded(): void {
		// Track click of all links in data
		const linkAnchors = document.querySelectorAll('#data .modal__linkAnchor');
		for (let i=0; i < linkAnchors.length; i++) {
			linkAnchors[i].addEventListener('click', GoogleAnalytics.onClickTrackLink);
		}

		// The setup work can take quite a bit of time so we break it up
		// 	into a few jobs that provide a small pause between each so the browser
		// 	can do its thing and so the browse is not completely locked up during loading.
		const setupJobQueue = new JobQueue();

		// Convert meshes to flat shading
		// Make meshes flatshaded. By not exporting flatshaded meshes but doing in code we save 120mb of file size (before gzip)
		setupJobQueue.addForEach(
			() => { return this.scene.meshes; },
			50,
			(mesh: BABYLON.AbstractMesh) => {
				if (mesh instanceof BABYLON.Mesh && !(mesh instanceof BABYLON.InstancedMesh) && mesh.subMeshes !== undefined) {
					mesh.convertToFlatShadedMesh();
				}
			}
		);

		// Create worlds, and prepare meshes
		let universeMesh: BABYLON.Mesh;
		setupJobQueue.add((done: () => void) => {
			universeMesh = this.createUniverse();
			done();
		});
		
		// Optimization - Freeze meshes
		const meshesNotToFreeze: string[] =	[
			AirplaneControl.AIRPLANE_MESH_NAME,
			AirplaneControl.PROPELLER_MESH_NAME
		];
		setupJobQueue.addForEach(
			() => { return [universeMesh]; },
			50,
			(node: BABYLON.Node) => {
				if (meshesNotToFreeze.indexOf(node.name) === -1) {
					if (node instanceof BABYLON.AbstractMesh) {
						node.freezeWorldMatrix();
					}
					return node.getChildren();
				}
				return undefined;
			}
		);

		// Airplane and cloud animations
		let clouds: Clouds;
		setupJobQueue.add((done: () => void) => {
			const airplaneControl = new AirplaneControl(this.scene);
			this.addRenderNeeded(airplaneControl);
			const cloudsElement = document.querySelector(".clouds");
			if (cloudsElement !== null) {
				clouds = new Clouds(cloudsElement);
				this.addOnNoRender(clouds);
			}
			done();
		});

		// Setup framework, Notify of DataLoadedEvent, and Enter the first scene
		let manager: View.Manager;
		let stateControl: StateControl;
		setupJobQueue.add((done: () => void) => {
			const setupFramework = this.setupFramework(universeMesh);
			manager = setupFramework.manager;
			stateControl = setupFramework.stateControl;
			manager.notifyObservers(new View.DataLoadedEvent(manager));
			done();
		});
		
		// Load any previous state
		setupJobQueue.add((done: () => void) => {
			stateControl.loadState();
			done();
		});
		
		// Start rendering
		setupJobQueue.add((done: () => void) => {
			this.activeMeshCandidateProvider.setRootNode(universeMesh);
			this.startRendering();
			done();
		});

		// Create toolbar and register auto progress
		setupJobQueue.add((done: () => void) => {
			// Register auto progress
			this.autoTour = new AutoTour(manager, this.config.autoTour);

			// Toolbar in lower right
			if (this.config.toolbar.enabled) {
				this.createToolbarUI();
			}

			done();
		});
		
		// Remove loading screen.
		setupJobQueue.add((done: () => void) => {
			const loadingScreenElem = document.getElementById("LoadingScreen");
			let isRemovedFromDom = 
			loadingScreenElem.addEventListener('transitionend', (event: TransitionEvent) => {
				if (event.target === loadingScreenElem && loadingScreenElem.parentNode !== null) {
					loadingScreenElem.parentElement.removeChild(loadingScreenElem);
				}
			});
			loadingScreenElem.classList.add("isLoaded");
			done();
		});

		// Diable autoclear
		setupJobQueue.add((done: () => void) => {
			this.scene.autoClear = false; // Color buffer
			this.scene.autoClearDepthAndStencil = false; // Depth and stencil, obviously
		});

		// Start the work when scene ready
		this.scene.executeWhenReady(setupJobQueue.run);
	}


	/**
	 * Create worlds and prepare mesh heirarchy
	 * This includes adding cameras and labels for each world.
	 */
	private createUniverse(): BABYLON.Mesh {
		const telecomWorldName: string = NodeStructureHelper.WorldNames.telecom;
		const cableWorldName: string = NodeStructureHelper.WorldNames.cable;
		const telecomWorldLinkName: string = "Link:" + telecomWorldName;
		const cableWorldLinkName: string = "Link:" + cableWorldName;
		const universeName: string = "universe";
		const universeCameraName: string = universeName + ".camera";

		const keepAsOrphanNodes: string[] =	[
			AirplaneControl.AIRPLANE_MESH_NAME
		];

		const cableWorldOnlyNodes: string[] =	[
			cableWorldName + ".camera",
			"Link:hfc",
			"hfc-roads",
			"hfc",
			"hfc-internal",
		];
		const telecomWorldOnlyNodes: string[] = [
			telecomWorldName + ".camera",
			"Link:ct",
			"ct",
			"ct-internal",
			"cell-tower-roads",
		];
		const universeNodes: string[] = [
			AirplaneControl.AIRPLANE_MESH_NAME,
			telecomWorldLinkName,
			cableWorldLinkName
		];

		const nodesToForceCloneOn = [ // vs createInstance() when avaliable
			"sfh.house.cutaway",
			"sfh.house.cutaway.001",
			"apartment.cutaway",
			"co.cutaway",
			"business.cutaway",
			"business.cutaway2",
			"hut001.cutaway",
			"hut.cutaway",
			"sfh-ground-areas",
			"ct-ground-areas",
			"bc-ground-areas",
			"ct-ground-cutaway",
			"ct-ground-cutaway-2",
			"MDU.apartment.wall-wire.cutaway",
		];



		// Telecom World
		const telecomWorld: BABYLON.Mesh = BabylonHelper.CreateEmpty(telecomWorldName, this.scene);
		BabylonHelper.ParentOrphans(telecomWorld, this.scene.meshes,
			// predicate
			(node: BABYLON.Node) => {
				return keepAsOrphanNodes.indexOf(node.name) === -1;
			}
		);
		BabylonHelper.ParentOrphans(telecomWorld, this.scene.cameras,
			// predicate
			(camera: BABYLON.Node) => {
				return camera !== this.scene.activeCamera;
			}
		);

		// Cable World
		const duplicateNodeRenamingFunc = (sourceName: string, isSourceNode: boolean) => {
					if (isSourceNode === true) {
						return NodeStructureHelper.Rename(sourceName, telecomWorldName);
					}
					return NodeStructureHelper.Rename(sourceName, cableWorldName);
				};
		const duplicateNodePredicateFunc = (sourceNode: BABYLON.Node) => {
					return cableWorldOnlyNodes.indexOf(sourceNode.name) === -1
						&& telecomWorldOnlyNodes.indexOf(sourceNode.name) === -1
						&& universeNodes.indexOf(sourceNode.name) === -1
						&& !(sourceNode instanceof BABYLON.Light)
						&& sourceNode.name.indexOf("pointLight_marker") === -1; // TODO This is temporary
				};
		
		const duplicateNodeModeFunc = (sourceNode: BABYLON.Node) => {
					if (nodesToForceCloneOn.indexOf(sourceNode.name) !== -1) {
						return {
							self: DuplicateNodeMode.ForceClone,
							descendants: DuplicateNodeMode.ForceClone
						}
					}
					return DuplicateNodeMode.PreferInstance;
				};
				
		const cableWorld: BABYLON.Mesh = BabylonHelper.DuplicateNode(
				telecomWorld,
				{
					mode: duplicateNodeModeFunc,
					rename: duplicateNodeRenamingFunc,
					renameSource: true,
					predicate: duplicateNodePredicateFunc
				}
			) as BABYLON.Mesh;

		cableWorld.name = cableWorldName;

		// Manually "move" the nodes from telecom to cable that are listed in cableWorldOnlyNodes
		BabylonHelper.SwitchMeshesParent(
			cableWorldOnlyNodes, this.scene, cableWorld,
			// renamingFunction
			(sourceName: string) => {
				return NodeStructureHelper.Rename(sourceName, cableWorldName);
			}
		);

		// Rename all the renaming telecom nodes to they start with the prefix
		telecomWorldOnlyNodes.forEach((name: string) => {
			let node = this.scene.getNodeByName(name);
				if (node !== undefined && node !== null) {
				BabylonHelper.RenameNode(
					node,
					// renamingFunction
					(sourceName: string) => {
						return NodeStructureHelper.Rename(sourceName, telecomWorldName);
					}
				);
			}
		});
		

		// Setup universe
		const universeMesh: BABYLON.Mesh = BabylonHelper.CreateEmpty(universeName, this.scene);
		universeNodes.push(telecomWorld.name);
		universeNodes.push(cableWorld.name);
		BabylonHelper.SwitchMeshesParent(universeNodes, this.scene, universeMesh);

		// Position worlds
		let moveAmount = new BABYLON.Vector3(3.5, 0, -3.5);
		BabylonHelper.MoveMeshesAndCameras(
			[telecomWorld, this.scene.getMeshByName(telecomWorldLinkName)],
			moveAmount
		);
		moveAmount.multiplyInPlace(new BABYLON.Vector3(-1, 0, -1));
		BabylonHelper.MoveMeshesAndCameras(
			[cableWorld, this.scene.getMeshByName(cableWorldLinkName)],
			moveAmount
		);

		// Create camera for universe
		const universeCameraPosition: BABYLON.TargetCamera = new BABYLON.TargetCamera(
			universeCameraName,
			new BABYLON.Vector3(-10,  4, -7),
			this.scene
		);
		let universeCameraTarget = new BABYLON.Vector3(6, 0, 6);
		universeCameraPosition.setTarget(universeCameraTarget);
		universeCameraPosition.parent = universeMesh;

		return universeMesh;
	}

	/**
	 * Setup our framework: View Manager, View Loader, Action / State Controls
	 */
	private setupFramework(universeMesh: BABYLON.Mesh): {manager: View.Manager, stateControl: StateControl} {
		// View Manager and Loader
		const manager: View.Manager = new View.Manager(this.scene, universeMesh);
		const viewLoader = new View.Loader();
		viewLoader.load(manager);

		// Action and state controls
		const stateControl: StateControl = manager.registerObserver(new StateControl(manager));
		manager.registerObserver(new ViewControl(manager, 'isActive'));
		manager.registerObserver(new LocalNavigation(manager, stateControl, 'node--isActive', 'node--isAncestorOfActive', Main.screenWidth_linear));

		return {manager: manager, stateControl: stateControl};
	}

	/**
	 * Start scene rendering 
	 */
	private startRendering() : void {
		// Notify engine when resize
		this.onWinResize(); // Call once since between initial loading of BABYLON and the everything ready the window may have resized.
		window.addEventListener('resize', this.onWinResize);

		// Run render loop
		this.engine.runRenderLoop(this.onRender);
	}

	private onWinResize(): void {
		this.engine.resize();
		BabylonHelper.UpdateCachedViewPort(this.scene);
		this.forceRerender = true;
	}
	
	private onRender(): void {
		const isRenderNeeded = this.isRenderNeeded();
		// TODOisRenderNeeded = true;  remove this. and change line above to const
		if (isRenderNeeded === true) {
			this.scene.render();
		} else if (this.onNoRenders !== undefined) {
			for (var i = 0; i < this.onNoRenders.length; i++) {
				this.onNoRenders[i].onNoRender();
			}
		}
	}


	/**
	 * Only render if needed.
	 */
	private isRenderNeeded(): boolean {
		let rerender: boolean = false;

		// Update camera. Needed to determine if camera moved.
		let activeCamera: BABYLON.TargetCamera = this.scene.activeCamera as BABYLON.TargetCamera;
		activeCamera.update();

		// Check forceRerender
		if (this.forceRerender === true) {
			rerender = true;
		}

		// Update and check instances to see if they need rendering
		if (this.renderNeeded !== undefined) {
			for (let inst, i = 0, ln = this.renderNeeded.length; i < ln; i++) {
				inst = this.renderNeeded[i];
				inst.prerenderUpdate();
				if (rerender !== true && inst.isRenderNeeded() === true) {
					rerender = true;
				}
			}
		}

		// Determine if rerender needed based on camera move or scene's animatables
		if (rerender !== true) {
			rerender =
				this.scene.animatables.length > 0
				|| !this.lastPosition.equals(activeCamera.position)
				|| !this.lastRotation.equals(activeCamera.rotation);
		}

		// Update info used to determine if rerender needed
		if (rerender === true) {
			// Only update records if there is a chance it changed
			this.lastPosition = activeCamera.position.clone();
			this.lastRotation = activeCamera.rotation.clone();
		}
		this.forceRerender = false;

		return rerender;
	}

	public addRenderNeeded(renderNeeded :RenderNeeded): void {
		if (this.renderNeeded == undefined) {
			this.renderNeeded = [renderNeeded];
		} else {
			this.renderNeeded.push(renderNeeded);
		}
	}

	public addOnNoRender(onNoRenders :OnNoRenders): void {
		if (this.onNoRenders == undefined) {
			this.onNoRenders = [onNoRenders];
		} else {
			this.onNoRenders.push(onNoRenders);
		}
	}

	private createToolbarLink(container: HTMLDivElement, name: string, label: string, onclick: (event: MouseEvent) => void): HTMLAnchorElement {
		const link: HTMLAnchorElement = document.createElement('a');
		link.classList.add('toolbar__link');
		link.classList.add('toolbar__link--' + name);
		link.innerText = label;
		link.onclick = onclick;
		container.appendChild(link);

		return link;
	}

	private createToolbarUI() {
		// Debug link container
		const container: HTMLDivElement = document.body.appendChild(document.createElement('div'));
		container.classList.add('toolbar');
		
		// Auto Progress
		const autoTourLink: HTMLAnchorElement = this.createToolbarLink(container, 'autoTour', 'Auto Tour', (event) => {
			const newOn = !this.autoTour.isOn();
			this.autoTour.setOn(newOn);
			autoTourLink.classList.toggle('toolbar__link--active', newOn);

			if (newOn) {
				// When turn on we want to start quicker than normal.
				if (this.autoTour.getIdleDuration() > 5000) {
					this.autoTour.resetIdleTimer(5000);
				}
			}
		});
		if (this.autoTour.isOn()) {
			autoTourLink.classList.add('toolbar__link--active');
		}

		// Fullscreen
		const fullscreenLink: HTMLAnchorElement = this.createToolbarLink(container, 'fullscreen', 'Fullscreen', (event) => {
			if (document.fullscreenElement || document.webkitIsFullScreen) {
				if ('exitFullscreen' in document) {
					document.exitFullscreen();
				} else if ('webkitExitFullscreen' in document) {
					(document as any).webkitExitFullscreen();
				} else {
					console.error('Fullscreen api not available.');
				}
				fullscreenLink.classList.remove('toolbar__link--active');
			} else {
				if ('requestFullscreen' in document.documentElement) {
					document.documentElement.requestFullscreen();
				} else if ('webkitRequestFullscreen' in document.documentElement) {
					(document.documentElement as any).webkitRequestFullscreen();
				} else {
					console.error('Fullscreen api not available.');
				}
			}
			fullscreenLink.classList.add('toolbar__link--active');
		});

		// Displaye entire local nav
		let entireNavToggle: boolean = false;
		const entireNavLink: HTMLAnchorElement = this.createToolbarLink(container, 'sitemap', 'Sitemap', (event) => {
			if (entireNavToggle) {
				document.getElementsByClassName('localNavigation')[0].classList.remove('localNavigation--everything');
				entireNavLink.classList.remove('toolbar__link--active');
			} else {
				document.getElementsByClassName('localNavigation')[0].classList.add('localNavigation--everything');
				entireNavLink.classList.add('toolbar__link--active');
			}
			entireNavToggle = !entireNavToggle;
		});

		// Babylon debug
		if (BABYLON.DebugLayer !== undefined) {
			// If the loaded version of BABYLON includes the DebugLayer then add a link for debugging.
			const debugLink: HTMLAnchorElement = document.createElement('a');
			debugLink.classList.add('toolbar__link');
			debugLink.innerText = 'Babylon';
			let debugOpen: boolean = false;
			debugLink.onclick = (event) => {
				if (debugOpen) {
					this.scene.debugLayer.hide();
					debugLink.classList.remove('toolbar__link--active');
				} else {
					this.scene.debugLayer.show();
					debugLink.classList.add('toolbar__link--active');
				}
				debugOpen = !debugOpen;
			};
			container.appendChild(debugLink);
		}
	}
}

export default Main;