import * as BABYLON from 'babylonjs';
import {ValueKeys} from '../AnimationEntry';
import * as AbstractNodeProperties from './AbstractNodeProperties';
import {MeshTarget} from '../targets/MeshTarget';


/**
 * MeshProperties
 */

 /* ===================== Rotation ===================== */
class Rotation extends AbstractNodeProperties.AbstractRotation  {
	public init(valueKeys: ValueKeys, target: MeshTarget): void {
		super.init(valueKeys, target);
	}
}
class RotationQuaternion extends AbstractNodeProperties.AbstractRotationQuaternion  {
	public init(valueKeys: ValueKeys, target: MeshTarget): void {
		super.init(valueKeys, target);
	}
}


 /* ===================== Position ===================== */
 class Position extends AbstractNodeProperties.AbstractPosition  {
	public init(valueKeys: ValueKeys, target: MeshTarget): void {
		super.init(valueKeys, target);
	}
}
 
 /* ===================== Visibility ===================== */
 class Visibility extends AbstractNodeProperties.AbstractVisibility  {
	public init(valueKeys: ValueKeys, target: MeshTarget): void {
		super.init(valueKeys, target);
	}
}
 

/* ===================== Export instances ===================== */
export const rotation = new Rotation();
export const rotationQuaternion = new RotationQuaternion();
export const position = new Position();
export const visibility = new Visibility();

