import * as BABYLON from 'babylonjs';

export const enum LoadInfoState {
	Waiting,
	Loading,
	Loaded,
	Errored
}

export interface LoadInfo {
	rootURL: string,
	filename: string,
	state: LoadInfoState
}

export default class ModelLoader {
	private _isLoadStarted: boolean = false; // Accessible to public via .isLoadStarted property
	private list: {	[key:string]: LoadInfo } = {};

	constructor(
		private scene: BABYLON.Scene,
		private onLoadComplete: () => void,
		private onError?: (loadInfo: LoadInfo, message: string, exception?: any) => void
	) {}

	/**
	 * Add a file to the list ot load.
	 * @returns {LoadInfo|null} The LoadInfo for the addition. Or null if loaded already started.
	 */
	add(rootUrl: string, sceneFilename: string): LoadInfo | null {
		if (this._isLoadStarted === true) {
			return null;
		}

		const loadInfo = {
			rootURL: rootUrl,
			filename: sceneFilename,
			state: LoadInfoState.Waiting
		};
		const key = loadInfo.rootURL + loadInfo.filename;
		this.list[key] = loadInfo;

		return loadInfo;
	}

	/**
	 * Remove file from list to load.
	 * @returns {boolean} Returns boolean indicating if successful. False will be returned if not found or if loading has already started.
	 */
	remove(loadInfo: LoadInfo): boolean;
	remove(rootUrl: string, sceneFilename: string): boolean;
	remove(loadinfoOrRoot: LoadInfo | string, sceneFilename?: string): boolean {
		if (this._isLoadStarted === true) {
			return false;
		}

		let key = (typeof loadinfoOrRoot === "string")? 
			loadinfoOrRoot + sceneFilename:
			(loadinfoOrRoot as LoadInfo).rootURL + (loadinfoOrRoot as LoadInfo).filename;
		let value = this.list[key];

		if (value !== undefined && value !== null) {
			delete this.list[key];
			return true;
		}
		return false;
	}

	/**
	 * Start load process
	 */
	load(): void {
		this._isLoadStarted = true;
		const keys = Object.getOwnPropertyNames(this.list);

		for (let i=0, len=keys.length; i < len; i++) {
			let loadInfo: LoadInfo = this.list[keys[i]];
			loadInfo.state = LoadInfoState.Loading;

			BABYLON.SceneLoader.Append(loadInfo.rootURL, loadInfo.filename, this.scene,
				// onsuccess
				(scene: BABYLON.Scene) => {
					loadInfo.state = LoadInfoState.Loaded;
					if (this.isLoadComplete()) {
						this.onLoadComplete();
					}
				},
				// onProgress
				(event: ProgressEvent) => {},
				// onError
				(scene: BABYLON.Scene, message?: string, exception?: any) => {
					loadInfo.state = LoadInfoState.Errored;
					this.onError(loadInfo, message, exception);
				}
			);

		}
	}

	/**
	 * Property indicates if loading has been started.
	 */
	get isLoadStarted(): boolean {
		return this._isLoadStarted;
	}

	private isLoadComplete() {
		for (let key in this.list) {
			if (this.list.hasOwnProperty(key)) {
				let loadInfo: LoadInfo = this.list[key];
				if (loadInfo.state < LoadInfoState.Loaded) {
					return false;
				}
			}
		}
		return true;
	}

}
