import * as Main from '../Main';
import * as BABYLON from 'babylonjs';


export default class AirplaneControl implements Main.RenderNeeded {
	private airplane: BABYLON.AbstractMesh;
	private propeller: BABYLON.AbstractMesh;

	private points: BABYLON.Vector3[];
	private animData: {points: BABYLON.Vector3[], tangents: BABYLON.Vector3[], normals: BABYLON.Vector3[], binormals: BABYLON.Vector3[], totalPoints: number};

	private start: number;
	private loopDuration = 19000;
	private numberOfLoops = 3; // number of unique loops to generate
	private i: number;
	private j: number;
	private iNotFloored: number;
	private percentAroundLoop: number;
	private percentBetweenPoints: number;
	private timeThroughLoop: number;
	private deltaDistance = BABYLON.Vector3.Zero();

	private propellerRotation = new BABYLON.Vector3(-1.5, 0, 0);

	public static readonly AIRPLANE_MESH_NAME = "airplane";
	public static readonly PROPELLER_MESH_NAME = "airplane.propeller";

	constructor(private scene: BABYLON.Scene) {
		this.airplane = scene.getMeshByName(AirplaneControl.AIRPLANE_MESH_NAME);
		this.propeller = scene.getMeshByName(AirplaneControl.PROPELLER_MESH_NAME);

		/*
		// Render a visible track
		const points = this.getPoints();
		var lines = BABYLON.Mesh.CreateLines("airplane__path", points, scene);
		lines.color = new BABYLON.Color3(1, 0, 0);
		*/
	}

	public prerenderUpdate(): void {
		// Update plain's position
		const animData = this.getAnimData();

		if (this.start === undefined) {
			this.start = performance.now();
		}

		this.timeThroughLoop = (performance.now() - this.start + this.loopDuration * 0.75) % (this.loopDuration * this.numberOfLoops);
		
		this.percentAroundLoop = this.timeThroughLoop / (this.loopDuration * this.numberOfLoops);
		this.iNotFloored = this.percentAroundLoop * animData.totalPoints;
		this.i = Math.floor(this.iNotFloored);
		this.j = (this.i + 1) % animData.totalPoints;
		this.percentBetweenPoints = this.iNotFloored - this.i;

		this.animData.points[this.j].subtractToRef(animData.points[this.i], this.deltaDistance);
		this.deltaDistance.scaleInPlace(this.percentBetweenPoints);
		animData.points[this.i].addToRef(this.deltaDistance, this.airplane.position);

		this.airplane.rotation = BABYLON.Vector3.RotationFromAxis(animData.tangents[this.i], animData.normals[this.i], animData.binormals[this.i]);

		// Rotate the propeller
		this.propeller.rotation.addInPlace(this.propellerRotation);
	}

	private isInView: boolean;
	public isRenderNeeded(): boolean {
		if (this.scene.frustumPlanes !== undefined) {
			this.airplane.computeWorldMatrix();
			let isInView = this.airplane.isInFrustum(this.scene.frustumPlanes);
			const isRenderNeeded = isInView || this.isInView;
			this.isInView = isInView;
			return isRenderNeeded;
		}
		return false;
	}

	private getAnimData(): {points: BABYLON.Vector3[], tangents: BABYLON.Vector3[], normals: BABYLON.Vector3[], binormals: BABYLON.Vector3[], totalPoints: number} {
		if (this.animData === undefined) {
			const points = this.getPoints();
			const path3d = new BABYLON.Path3D(points);
			this.animData = {
				points : points,
				tangents : path3d.getTangents(),
				normals : path3d.getNormals(),
				binormals : path3d.getBinormals(),
				totalPoints : points.length
			};
		}
		return this.animData;
	}

	private getPoints(): BABYLON.Vector3[] {
		if (this.points === undefined) {
			this.points = new Array();
			const pointsPerLoop = 160;
			const r = 12; //radius
			let iPi;
			for (let iLoop = 0; iLoop < this.numberOfLoops; iLoop++) {
				for (let i = 0; i < pointsPerLoop; i++) {
					iPi = ((iLoop * pointsPerLoop) + i) * Math.PI / pointsPerLoop;
					this.points.push(
						new BABYLON.Vector3(
							(r + (r / (4 + iLoop)) * Math.sin(5 * iPi))		* Math.sin(2 * iPi) - 3,
							((r / (4 + iLoop)) * Math.sin(2 * iPi))			* Math.sin(2 * iPi)	+ 2, // Add to increase altitude of the path.
							(r + (r / 10) * Math.sin(8 * iPi))		* Math.cos(2 * iPi) - 3
						)
					);
				}
			}
		}
		return this.points;
	}
}