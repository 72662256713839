import * as BABYLON from 'babylonjs';
import {Manager} from './Manager';
import * as Structure from './Structure';

export class ViewElement {
	constructor(protected manager: Manager, public element: HTMLElement) {
		manager.addView(this);
	}
	
	getId(): string {
		return this.element.id;
	}
	
	getName(): string {
		return this.element.dataset.name;
	}

	getCategory(): string {
		var value = this.element.dataset.category;
		if (value == null || value === undefined || value.length === 0)
			return undefined;
		return value;
	}

	/**
	 * Determines if full or partial view. (ie. a modal is a partial view because it exists within its parent)
	 */
	isPartial(): boolean {
		return false;
	}

	private getElementAddActiveTo(): Element {
		return this.element;
	}

	display(node: Structure.Node, manager: Manager, activeStyleClass: string) {
		let element: Element = this.getElementAddActiveTo();
		if (element !== null) {
			this.prepareToDisplay();
			element.classList.add(activeStyleClass);
		}
	}
	/**
	 * Loads any lazy loaded images
	 */
	prepareToDisplay() {
		let element: Element = this.getElementAddActiveTo();
		if (element !== null) {

			// Find any lazy loaded images
			let images: NodeListOf<Element> = element.querySelectorAll('img[data-original]');
			for (var index = 0; index < images.length; index++) {
				let image = images[index];
				let original = image.getAttribute('data-original');
				if (original !== null) {
					image.setAttribute('src', original);
					image.removeAttribute('data-original');
				}
				
			}
		}
	}
	hide(node: Structure.Node, manager: Manager, activeStyleClass: string) {
		let element: Element = this.getElementAddActiveTo();
		if (element !== null) {
			element.classList.remove(activeStyleClass);
		}
	}
}

export {Layout} from './ViewElement/Layout';
export {TwoWorld} from './ViewElement/TwoWorld';
export {World} from './ViewElement/World';
export {Place} from './ViewElement/Place';
export {Product} from './ViewElement/Product';
export {Modal} from './ViewElement/Modal'; 
