export default class JobQueue {
	private queue: ((doneCallback: () => void) => void)[];

	public constructor() {
		this.run = this.run.bind(this);
		this.nextTick = this.nextTick.bind(this);
		this.jobDoneCallback = this.jobDoneCallback.bind(this);
		this.queue = new Array();
	}

	public add(job: ((doneCallback: () => void) => void)): void {
		this.queue.push(job);
	}

	public addForEach<T>(items: T[] | (() => T[]), maxPer: number, job: (item: T) => T[] | void): void {
		const forEachJobStarter = (doneCallback: () => void) => {
			const actualItems = (Array.isArray(items) ? items : items()).slice(0); // .slice(0) to clone the array
			this.forEachGroup(maxPer, actualItems, job, doneCallback);
		};
		this.queue.push(forEachJobStarter)
	}
	private forEachGroup<T>(maxPer: number, items: T[], job: (item: T) => T[] | void, doneCallback: () => void): void {
		let newItems: T[] | void,
			item: T,
			count = 0;
		while (item = items.pop()) {
			newItems = job(item);
			if (newItems !== undefined) {
				items.push.apply(items, newItems);
			}
			if (count++ === maxPer) {
				break;
			}
		}
		if (items.length !== 0) {
			this.pauseThenRun(() => {
				this.forEachGroup(maxPer, items, job, doneCallback);
			});
		} else {
			doneCallback();
		}
	}

	public run() {
		if (this.queue.length !== 0) {
			this.nextTick();
		}
	}
	private nextTick() {
		const job = this.queue.shift();
		job(this.jobDoneCallback);
	}
	private jobDoneCallback() {
		if (this.queue.length !== 0) {
			this.pauseThenRun(this.nextTick);
		}
	}
	private pauseThenRun(func: () => any) {
		// If the document is visible (e.g. the tab i active) give control to the browser for a split second before continuing.
		if (document.hidden === undefined || document.hidden === false) {
			if (window.setImmediate !== undefined) {
				setImmediate(func);
			} else {
				setTimeout(func, 0);
			}
		} else {
			func();
		}
	}
}