import {ValueKeys, Target} from '../AnimationEntry';


/**
 * Property
 */
export interface Property {
	key: string;
	dataType: any;
	calcDistance(start: any, end: any): number; // Should return a non-negative number.
	init(valueKeys: ValueKeys, target: Target): void;
}

export function propertyInitHelper(
	property: Property, valueKeys: ValueKeys,
	target: Target, setKeys: (currentValue: any, delta: any) => void
): void {
	if (valueKeys.start === undefined || valueKeys.end === undefined) {
		const currentValue = target.getPropertyValue(property);
		if (currentValue !== undefined && currentValue !== null) {
			const delta = valueKeys["delta"];
			setKeys(currentValue, delta); // Note: Delta can be undefined.
		} else {
			console.warn("Target does not contain value.", target, property.key);
		}
	}
}