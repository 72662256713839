import * as BABYLON from 'babylonjs';

export enum DuplicateNodeMode {
	StrictInstance,	// Will skip anything that must be cloned
	PreferInstance, // Falls back to cloning
	ForceClone		// Will not use CreateInstance for self or children
}

type DuplicateNodeModeParam = 
	DuplicateNodeMode
		| ((sourceNode: BABYLON.Node) => DuplicateNodeMode | {self: DuplicateNodeMode, descendants?: DuplicateNodeMode});


export default class BabylonHelper {

	private static _cachedViewport: BABYLON.Viewport;

	/**
	 * Create an mesh which has no geometry and is not visible.
	 */
	public static CreateEmpty(name: string, scene: BABYLON.Scene, position?: BABYLON.Vector3): BABYLON.Mesh {
		const emptyMesh = new BABYLON.Mesh(name, scene);
		emptyMesh.isVisible = false;
		if (position !== undefined) {
			emptyMesh.position.copyFrom(position);
		}
		return emptyMesh;
	}

	/**
	 * Parents all meshes that do not have a parent.
	 */
	public static ParentOrphans(parentMesh: BABYLON.Mesh, nodes: BABYLON.Node[], predicate?: (node: BABYLON.Node) => boolean): void {
		for (let i=0, len=nodes.length; i < len; i++) {
			let node = nodes[i];
			if (
				node !== parentMesh && (node.parent === undefined || node.parent === null)
				&& (predicate === undefined || predicate(node) !== false)
			) {
				node.parent = parentMesh;
			}
		}
	}

	/**
	 * Freeze or unfreeze a mesh and all children of mesh.
	 */
	public static SetFreezeWorldMatrix(node: BABYLON.Node, isFreeze: boolean, predicate?: (node: BABYLON.Node) => boolean): void {
		if (predicate === undefined || predicate(node) !== false) {
			// Freeze / unfreeze if mesh
			if (node instanceof BABYLON.AbstractMesh) {
				if (isFreeze === true) {
					node.freezeWorldMatrix();
				} else {
					node.unfreezeWorldMatrix();
				}
			}

			// Process children
			const children = node.getChildren();
			for (var i = 0, len = children.length; i < len; i ++) {
				BabylonHelper.SetFreezeWorldMatrix(children[i], isFreeze, predicate);
			}
		}
	}

	/**
	 * 
	 */
	public static SwitchMeshesParent(
		meshNames: string[], scene: BABYLON.Scene, newParent:BABYLON.Node,
		renamingFunction?: (sourceName: string) => string,
		renameChildren: boolean = true
	): void {
		for (let i=0, len=meshNames.length; i < len; i++) {
			let node: BABYLON.Node = scene.getNodeByName(meshNames[i]);
			if (node !== undefined && node !== null) {
				node.parent = newParent;
				if (renamingFunction !== undefined) {
					BabylonHelper.RenameNode(node, renamingFunction, renameChildren);
				}
			}
		}
	}

	/**
	 * 
	 */
	public static RenameNode(node: BABYLON.Node, renamingFunction: (sourceName: string) => string, renameChildren: boolean = true): void {
		node.name = renamingFunction(node.name);
		if (renameChildren === true) {
			const children: BABYLON.Node[] = node.getChildren();
			if (children !== undefined && children !== null) {
				for (let i = 0, len = children.length; i < len; i++) {
					BabylonHelper.RenameNode(children[i], renamingFunction);
				}
			}
		}
	}

	/**
	 * 
	 */
	public static MoveMeshesAndCameras(meshes: BABYLON.AbstractMesh[], moveAmount: BABYLON.Vector3) {
		meshes.forEach((mesh: BABYLON.AbstractMesh) => {
			if (mesh !== null) {
				// Move the meshes
				mesh.position.addInPlace(moveAmount);

				// Unlike meshes cameras are not effected by parents being moved.. I know, sounds weird. So, to have the cameras pointing
				// at the right things we need to adjust them too.
				/* TODO That does not appear to be true ... So why is this needed?
					Here is a mockup showing it should not be needed: https://www.babylonjs-playground.com/#1RRYJR#6
					It is not the type of camera. It is not because the parent is a mesh without geometry.
					If I parent the activeCamera to a mesh I create via Helper.CreateEmpty() and move that empty, the camear moves so ...
						I also tried creatomg two levels of emptys, and everything worked right.
						I odn't know.
				*/
				const cameras = mesh.getDescendants(false, (node) => {
					return node instanceof BABYLON.Camera;
				}) as BABYLON.Camera[];

				cameras.forEach((camera: BABYLON.Camera) => {
					camera.position.addInPlace(moveAmount);
				});
			}
		});
	}

	public static UpdateCachedViewPort(scene: BABYLON.Scene) {
		const engine: BABYLON.Engine = scene.getEngine();
		const scaling: number = engine.getHardwareScalingLevel();
		const width: number = engine.getRenderWidth() * scaling;
		const height: number = engine.getRenderHeight() * scaling;

		if (BabylonHelper._cachedViewport === undefined) {
			BabylonHelper._cachedViewport = scene.activeCamera.viewport.toGlobal(
				width,
				height
			);
		} else {
			BabylonHelper._cachedViewport.width = width;
			BabylonHelper._cachedViewport.height = height;
		}
	}
	
	/**
	 * Convert a vector in 3D space into the 2D space of the viewport
	 */
	public static MeshPositionToViewPortSpace(
		mesh: BABYLON.AbstractMesh,
		roundNumber: boolean = false,
		scene: BABYLON.Scene,
		camera?: BABYLON.Camera
	): {x: number, y: number, isBehind: boolean} {
		let viewport: BABYLON.Viewport;
		let transformMatrix: BABYLON.Matrix;
		if (camera === undefined) {
			viewport = BabylonHelper._cachedViewport;
			transformMatrix = scene.getTransformMatrix();
		} else {
			const engine: BABYLON.Engine = scene.getEngine();
			const scaling: number = engine.getHardwareScalingLevel();
			const width: number = engine.getRenderWidth() * scaling;
			const height: number = engine.getRenderHeight() * scaling;
			viewport = camera.viewport.toGlobal(
				width,
				height
			);

			const viewMatrix = camera.getViewMatrix();
			const projectionMatrix = camera.getProjectionMatrix();
			transformMatrix = viewMatrix.multiply(projectionMatrix);
		}

		
		const pos = BABYLON.Vector3.Project(
			mesh.getAbsolutePosition(),
			BABYLON.Matrix.Identity(),
			transformMatrix,
			viewport
		);

		const isBehind: boolean = pos.z > 1;
		
		return (roundNumber === true) ? {
			x: Math.round(pos.x),
			y: Math.round(pos.y),
			isBehind: isBehind
		} : {
			x: pos.x,
			y: pos.y,
			isBehind: isBehind
		};
	}

	/**
	 * Modify rotation so we take shortest path (e.g. if at 5deg and moving to 355deg then let's move -10deg instead of 350deg)
	 */
	// TODO Delete this. This doesn't work. Must use Quaternion rotation. Euler has limitations which are not easy to solve.
	public static ModifyRotationToShortestPath(to: BABYLON.Vector3, from: BABYLON.Vector3): BABYLON.Vector3 {
		let halfRotation = Math.PI;
		let fullRotation = halfRotation * 2;

		["x", "y", "z"].forEach((axis: string) => {
			// Get "to" to be within the same range as the "from"
			let revCountDiff = (to[axis] - from[axis]) / fullRotation;
			revCountDiff = revCountDiff > 0 ? Math.floor(revCountDiff) : Math.ceil(revCountDiff);
			to[axis] = to[axis] - (revCountDiff * fullRotation);
		

			// Make sure we do not rotate more than 180 deg
			const diff = to[axis] - from[axis];
			if (Math.abs(diff) > halfRotation) {
				if (diff < 0) {
					to[axis] = to[axis] + fullRotation;
				} else {
					to[axis] = to[axis] - fullRotation;
				}
			}
		});

		return to;
	}


	/**
	 * Creates a copy of passed node and all descendant nodes. Copies are instances unless an instances can not be created then a clone is made.
	 * Options allow you to turn off falling back to cloning and make this direct descendants only (i.e. children only).
	 */
	public static DuplicateNode(
			sourceNode: BABYLON.Node,
			options: {
				mode?: DuplicateNodeModeParam,		// Default DuplicateNodeMode.PreferInstance. Function can return the type or an object with type ot use for self and descendants. If returns just the value, or "descendants" is undefined, then the method will be used for children too.
				directDescendantsOnly?: boolean, // Default false.
				rename?: (sourceName: string, isSourceNode?: boolean) => string,
				renameSource?: boolean,			// Default false.
				predicate?: (sourceNode: BABYLON.Node) => boolean,
				newParent?: BABYLON.Node,
			} = {}
	): BABYLON.Node {
		// TODO Should I also be updating IDs?

		// Set defaults
		
		if (options.directDescendantsOnly === undefined)
				options.directDescendantsOnly = false;
		if (options.renameSource === undefined)
				options.renameSource = false;

		// Mode info
		let mode: DuplicateNodeMode;
		let descendantsMode: DuplicateNodeModeParam = options.mode;
		if (typeof options.mode === "function") {
			const returnedMode = options.mode(sourceNode);
			if (returnedMode["self"] !== undefined) {
				mode = returnedMode["self"];
				if (returnedMode["descendants"] !== undefined) {
					descendantsMode = returnedMode["descendants"];
				}
			} else {
				mode = returnedMode as DuplicateNodeMode;
			}
		} else {
			mode = options.mode as DuplicateNodeMode;
		}
		if (mode === undefined) {
			mode = DuplicateNodeMode.PreferInstance;
		}

		const instancingOnly: boolean = mode === DuplicateNodeMode.StrictInstance;
		const cloningOnly: boolean = mode === DuplicateNodeMode.ForceClone;
		const instancingAllowed: boolean = mode === DuplicateNodeMode.PreferInstance || mode === DuplicateNodeMode.StrictInstance;
		const cloningAllowed: boolean = mode === DuplicateNodeMode.PreferInstance || mode === DuplicateNodeMode.ForceClone;

		// Walk the tree, and create instances / clone
		let newNode: BABYLON.Node;
		if (options.predicate === undefined || options.predicate(sourceNode) !== false) {
			// Init a vew vars
			let newName: string = options.rename(sourceNode.name, false);
			let isVisible: boolean;
			let isSourceInstancedMesh: boolean = false;

			// Rename source
			if (options.renameSource === true) {
				sourceNode.name = options.rename(sourceNode.name, true);
			}

			// Clone or copy depending on the 
			if (sourceNode instanceof BABYLON.LinesMesh) {

				// LinesMesh
				if (instancingOnly) {
					console.warn("Skipping duplicating of node because BABYLON.LinesMesh has no creatInstance().", sourceNode);
				} else if (cloningAllowed) {
					newNode = sourceNode.clone(newName, undefined, true); // We set parent and copy children ourselves
					isVisible = sourceNode.isVisible;
				}

			} else if (sourceNode instanceof BABYLON.Mesh) {

				// Mesh, but not LinesMesh (because it doesn't have )
				if (cloningOnly) {
					newNode = sourceNode.clone(newName, undefined, true); // We set parent and copy children ourselves
					isVisible = sourceNode.isVisible;
				} else if (instancingAllowed) {
					newNode = sourceNode.createInstance(newName);
					isVisible = sourceNode.isVisible;
				}

			} else if (sourceNode instanceof BABYLON.InstancedMesh) {

				// InstancedMesh
				if (cloningOnly) {
					// To clone we need to clone the source mesh.
					newNode = sourceNode.sourceMesh.clone(newName, undefined, true); // We set parent and copy children ourselves
					isVisible = sourceNode.isVisible;
					isSourceInstancedMesh = true;
				} else if (instancingAllowed) {
					newNode = sourceNode.sourceMesh.createInstance(newName);
					isVisible = sourceNode.isVisible;
					isSourceInstancedMesh = true;
				}

			} else if (sourceNode instanceof BABYLON.AbstractMesh) {

				console.warn("Create instance of this type AbstractMesh is not implemented.", sourceNode);

			} else if (sourceNode instanceof BABYLON.Camera) {

				// Camera
				if (instancingOnly) {
					console.warn("Skipping duplicating of node because BABYLON.Camera has no creatInstance().", sourceNode);
				} else if (cloningAllowed) {
					newNode = sourceNode.clone(newName);
					newNode.name = newName; // Need to do this because .clone() does not set the name correctly. Bug in BABYLON.
				}

			} else if (sourceNode instanceof BABYLON.Light) {

				// Light
				if (instancingOnly) {
					console.warn("Skipping duplicating of node because BABYLON.Light has no creatInstance().", sourceNode);
				} else if (cloningAllowed) {
					newNode = sourceNode.clone(newName);
				}

			} else if (BABYLON.Bone !== undefined && sourceNode instanceof BABYLON.Bone) {

				// Bone
				console.error("Duplicating bones is not currently impelemented.", sourceNode);

			} else {

				// Unkown
				console.warn("Create instance of this type of node is not implemented.", sourceNode);

			}

			if (newNode !== undefined) {
				// Set parent
				if (options.newParent !== undefined) {
					newNode.parent = options.newParent;
				}

				// Mesh specific settings
				if (newNode instanceof BABYLON.AbstractMesh) {
					// Set enabled and isVisible since values are not copied during createInstance
					newNode.setEnabled(sourceNode.isEnabled(false));
					if (isVisible !== undefined) {
						newNode.isVisible = isVisible;
					}

					// Set position, rotation, and scaling if we are copying an InstancedMesh
					if (isSourceInstancedMesh === true) {
						let sourceInstance = sourceNode as BABYLON.InstancedMesh;
						newNode.position.copyFrom(sourceInstance.position);
						newNode.rotation.copyFrom(sourceInstance.rotation);
						newNode.scaling.copyFrom(sourceInstance.scaling);
						// TODO See constructor of InstancedMesh... There may be more to add here .... Wait why don't I use InstancedMesh() and pass the instance being copied... Wait, that won't work...
					}
				}

				// Create instances / clone children
				if (options.directDescendantsOnly !== true) {
					let children = sourceNode.getDescendants(true);
					let newOptions = {
						mode: descendantsMode,
						directDescendantsOnly: options.directDescendantsOnly,
						rename: options.rename,
						renameSource: options.renameSource,
						predicate: options.predicate,
						newParent: newNode, // Changed
					}
					for (let i=0, len=children.length; i < len; i++) {
						BabylonHelper.DuplicateNode(children[i], newOptions);
					}
				}
			}

			/* TODO FIXME. 
				Cameras and lights (and maybe other things we are not using) are not being given
				a uniqueId (some stuff has uid; e.g. textures) when they are cloned.
				Also they are looping through the array in a number of places, but using indexOf() is much much faster now.
			if (sourceNode.uniqueId === newNode.uniqueId)
				console.log("Same unqiue ID!", sourceNode.uniqueId, sourceNode.name);
			*/
		}

		return newNode;
	}

}