import * as Main from '../Main';
import ElementTools from '../lib/ElementTools';
import * as NumberUtil from '../lib/NumberUtil';

export default class Clouds implements Main.OnNoRenders {

	private clouds: Element;
	private actualX: number = 0;
	private renderedX: number = 0;
	private minX: number;

	constructor(clouds: Element) {
		this.clouds = clouds;

		this.onWinResize = this.onWinResize.bind(this);
		this.onWinResize();
		window.addEventListener("resize", this.onWinResize);
	}

	private onWinResize(): void {
		this.minX = -1 * (this.clouds.getBoundingClientRect().width / 2);
	}

	public onNoRender(): void {
		// TODO Clamp the x. and consider using Window.devicePixelRatio ... TODO We should be taking time into account because..
		
		this.actualX = this.actualX - (1 / 20);
		if (this.actualX <= this.minX) {
			this.actualX = this.actualX % this.minX;
		}

		const x = NumberUtil.DecimalPoint(this.actualX, 1);
		if (x !== this.renderedX) {
			this.renderedX = x;
			ElementTools.setTransform(this.clouds, `translateX(${this.renderedX}px)`);
		}
	}

}