import * as BABYLON from 'babylonjs';
import {ValueKeys} from '../AnimationEntry';
import {Property, propertyInitHelper} from './Property';
import {Target} from '../targets/Target';


/**
 * Abstract node properties
 */

 /* ===================== Rotation ===================== */
export abstract class AbstractRotation implements Property  {
	public readonly key: string = "rotation";
	private readonly keyFallback: string = "rotationQuaternion";
	public readonly dataType = BABYLON.Animation.ANIMATIONTYPE_VECTOR3;

	public calcDistance(start: BABYLON.Vector3, end: BABYLON.Vector3): number {
		if (start !== undefined && end !== undefined) {
			return Math.abs(start.subtract(end).length()) / (Math.PI * 2);
		} else {
			return undefined;
		}
	}

	public init(valueKeys: ValueKeys, target: Target): void {
		propertyInitHelper(this, valueKeys, target,
			(currentValue: BABYLON.Vector3, delta: BABYLON.Vector3) => {
				valueKeys.start = currentValue.clone();
				if (delta !== undefined) {
					valueKeys.end =  currentValue.add(delta);
				}
		});
	}
}
export abstract class AbstractRotationQuaternion implements Property  {
	public readonly key: string = "rotationQuaternion";
	public readonly dataType = BABYLON.Animation.ANIMATIONTYPE_QUATERNION;

	public calcDistance(start: BABYLON.Quaternion, end: BABYLON.Quaternion): number {
		return Math.abs(start.subtract(end).length()) / Math.PI; // TODO I don't actually know what a full rotation is equal to in when using quaternion for rotation. The Math.PI just seemed to give about the right answer.
	}

	public init(valueKeys: ValueKeys, target: Target): void {
		propertyInitHelper(this, valueKeys, target,
			(currentValue: BABYLON.Quaternion, delta: BABYLON.Quaternion) => {
				valueKeys.start = currentValue.clone();
				if (delta !== undefined) {
					valueKeys.end =  currentValue.add(delta);
				}
		});
	}
}


 /* ===================== Position ===================== */
 export abstract class AbstractPosition implements Property  {
	public key: string = "position";
	public readonly dataType = BABYLON.Animation.ANIMATIONTYPE_VECTOR3;

	public calcDistance(start: BABYLON.Vector3, end: BABYLON.Vector3): number {
		return Math.abs(start.subtract(end).length());
	}

	public init(valueKeys: ValueKeys, target: Target): void {
		propertyInitHelper(this, valueKeys, target,
			(currentValue: BABYLON.Vector3, delta: BABYLON.Vector3) => {
				valueKeys.start = currentValue.clone();
				if (delta !== undefined) {
					valueKeys.end =  currentValue.add(delta);
				}
		});
	}
}
 
 /* ===================== Visibility ===================== */
 export abstract class AbstractVisibility implements Property  {
	public readonly key: string = "visibility";
	public readonly dataType = BABYLON.Animation.ANIMATIONTYPE_FLOAT;

	public calcDistance(start: number, end: number): number {
		return Math.abs(end - start);
	}

	public init(valueKeys: ValueKeys, target: Target): void {
		propertyInitHelper(this, valueKeys, target,
			(currentValue: number, delta: number) => {
				valueKeys.start = currentValue;
				if (delta !== undefined) {
					valueKeys.end =  currentValue + delta;
				}
		});
	}
}
