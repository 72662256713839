import * as BABYLON from 'babylonjs';
import {Manager, ViewObserver, ViewEvent, ViewChangeEvent} from './Manager';
import * as Structure from './Structure';
import {ViewElement, World, Place, Product, Modal} from './ViewElement';
import BabylonHelper from '../lib/BabylonHelper';
import {Clamp, ProportionalClamp} from '../lib/NumberUtil';
import WorldAnimationData from './WorldAnimationData';

import AnimationTimeline from '../animation/AnimationTimeline';
import * as AnimationEntry from '../animation/AnimationEntry';
import AnimationEasing from '../animation/AnimationEasing';
import * as TargetCameraProperties from '../animation/properties/TargetCameraProperties';
import {TargetCameraTarget} from '../animation/targets/TargetCameraTarget';


/**
 * Helper method to add node to array only if not already in the excludeNodes list, if that argument is provided.
 */
function nodeListConditionalPushToRef(node: Structure.Node, refList: Structure.Node[], excludeNodes?: Structure.Node[]) {
	if (excludeNodes === undefined || excludeNodes.indexOf(node) === -1) {
		refList.push(node);
	}
}

/**
 * In charge of visual effects. This includes:
 * - Hover on links (aka annoations)
 * - Enabling/disabling nodes when user moves around (ie. Hide links/annoations when we do not want them visible)
 * - Animating to a new part of the model when a new node is selected
 */
export default class VisualControl implements ViewObserver {
	private animationTimeline: AnimationTimeline;

	public static readonly CameraPositionAnimName = "activeCamera.position";
	public static readonly CameraRotationAnimName = "activeCamera.rotation";

    constructor(private manager: Manager, private elementActiveStyleClass) {
		this.animationTimeline = new AnimationTimeline(this.manager.getScene());
	}
	
	/* Cheat sheet notes:

		The following is a generalization but helps clarify what each thing does:
			* setEnabled()	1.-> node.setEnabled		-> Shows/hide the annotations (adds "isVisible" styleClass)
			* setViewDisplay()	1.-> node.setActive		-> Marks annotation as active or not (adds "isActive" styleClass; highlights active annotation)
								2.-> view.display/hide	-> Show/hide the modals

	*/

	notify(event: ViewEvent): void {
		if (event instanceof ViewChangeEvent) {
			// Build lists of nodes to enable and disable
			const enableNodes = this.buildNodeList(event.entering);
			const disableNodes = this.buildNodeList(event.leaving, true, enableNodes);

			// Inform view it is about to display. This allows us to start the loading
			// of images that will be visible once the animation is done
			if (event.entering !== undefined) {
				let enteringView = event.entering.getView();
				if (enteringView !== null) {
					enteringView.prepareToDisplay();
				}
			}
				
            // Animate view
			this.animateMove(event.leaving, disableNodes, event.entering, enableNodes);
		}
    }
	
	/**
	 * Takes a node leaving or entering, and returns an arrays of nodes to update the enable value of.
	 */
    private buildNodeList(node: Structure.Node, isLeaving: boolean = false, excludeNodes?: Structure.Node[]): Structure.Node[] {
		if (node === null || node === undefined){
			return undefined;
		}

		const nodeList: Structure.Node[] = []
		const view: ViewElement = node.getView();
		let nodeToEnable = node;

		// Enable parent if partial view (e.g. modal)
		if (node instanceof Structure.Link || (view !== null && view.isPartial())) {
			nodeToEnable = node.parent;
		}
		
		// Children
		nodeListConditionalPushToRef(nodeToEnable, nodeList, excludeNodes);
        for (let child of nodeToEnable.children) {
			nodeListConditionalPushToRef(child, nodeList, excludeNodes);
		}

		// Siblings (under some specific situations)
		if (
			isLeaving === true
			|| view instanceof World
			|| (nodeToEnable.parent !== undefined && nodeToEnable.parent.getView() instanceof Place)
		) {
			let parent = nodeToEnable;
			if (parent !== undefined && parent.parent !== undefined) {
				for (let i=0; i < parent.parent.children.length; i++) {
					let child = parent.parent.children[i];
					if (
						child !== parent
						&& (
							!(child instanceof Structure.Link)
							|| child.getLinkTo() !== parent
						)
					) {
						nodeListConditionalPushToRef(child, nodeList, excludeNodes);
					}
				}
			}
		}

		return nodeList;
	}
    
    private setNodesEnabled(nodes: Structure.Node[], isEnabled: boolean) {
		if (nodes !== undefined) {
			for (let i = 0, len = nodes.length; i < len; i++) {
				nodes[i].setEnabled(isEnabled);
			}
		}
	}
	
	private setActive(node: Structure.Node, isDisplay: boolean) {
		if (node !== null && node !== undefined) {
			// Mark/unmark active
			node.setActive(isDisplay);

			// Show/hide view
			let view: ViewElement = node.getView();
			if (view !== null) {
				if (isDisplay === true) {
					view.display(node, this.manager, this.elementActiveStyleClass);
				} else {
					view.hide(node, this.manager, this.elementActiveStyleClass);
				}
			}
		}
	}
	
	private animateMove(leavingNode: Structure.Node, disableNodes: Structure.Node[], enteringNode: Structure.Node, enableNodes: Structure.Node[]): void {
		// Deactive what we are leaving, and disable nodes
		this.setActive(leavingNode, false);
		this.setNodesEnabled(disableNodes, false);

		// Stop timline
		const animationTimeline = this.animationTimeline;
		animationTimeline.stop();

		// Animate camera to location
		const targetCamera: BABYLON.TargetCamera = VisualControl.getTargetCamera(enteringNode, true);
		if (targetCamera !== undefined) {
			this.addCameraMoveAnimation(animationTimeline, targetCamera);
		} else {
			console.error("Target camera not found for node.", enteringNode.name, enteringNode);
		}

		// Add events to show/hide nodes/modals
		animationTimeline.add(new AnimationEntry.EventEntry(
			"visualControl.enableNodes",
			() => { // Show nodes
				this.setNodesEnabled(enableNodes, true);
			},
			{position: {
				relativeToName: VisualControl.CameraPositionAnimName,
				percent: 1,
				offset: -300
			}}
		));

		animationTimeline.add(new AnimationEntry.EventEntry(
			"visualControl.activateNodes",
			() => { // Mark active (and show view; aka modals)
				this.setActive(enteringNode, true);
			},
			{position: {
				relativeToName: VisualControl.CameraPositionAnimName,
				percent: 1,
				offset: 0
			}}
		));

		// Add animations related to moving in and out of areas (e.g. wall fades away and a cabinet door opens)
		WorldAnimationData.AddAnimations(enteringNode, animationTimeline, this.manager.getScene());

		// Run animations
		const scene: BABYLON.Scene = this.manager.getScene();
		animationTimeline.run(() => { // on complete
			WorldAnimationData.OnAnimationsPlayedListComplete();
		});
	}

	private addCameraMoveAnimation(timeline: AnimationTimeline, targetCamera: BABYLON.TargetCamera) {
		const scene: BABYLON.Scene = this.manager.getScene();
		const activeCamera: BABYLON.TargetCamera = scene.activeCamera as BABYLON.TargetCamera;
		const cameraAnimTarget: AnimationEntry.Target = new TargetCameraTarget(activeCamera);

		// === Position ===
		timeline.add(new AnimationEntry.Entry(
			VisualControl.CameraPositionAnimName,
			cameraAnimTarget, TargetCameraProperties.position,
			{end: targetCamera.position},
			{
				speed: (distance: number): number => {
					// console.log("camera move distance", distance);
					if (distance <= 0.55) {
						return 1 / 5000;
					}
					if (distance <= 3) {
						return 1 / ProportionalClamp(distance, 0.55, 3, 1200, 600);
					}
					return 1 / ProportionalClamp(distance, 3, 8, 600, 400);
				},
				minDuration: 0,
				maxDuration: 3000,
				easing: AnimationEasing.Out
			}
		));

		// === Rotation ===
		// Setup camera to use quaternion rotation if it is not already.
		let targetRotation: BABYLON.Quaternion;
		if (targetCamera.rotationQuaternion !== undefined) {
			targetRotation = targetCamera.rotationQuaternion;
		} else {
			targetRotation = BABYLON.Quaternion.RotationYawPitchRoll(
				targetCamera.rotation.y, targetCamera.rotation.x, targetCamera.rotation.z,
			);
		}

		// Add rotation to timeline
		timeline.add(new AnimationEntry.Entry(
			VisualControl.CameraRotationAnimName,
			cameraAnimTarget,
			TargetCameraProperties.rotationQuaternion,
			{end: targetRotation},
			new AnimationEntry.DynamicVersion(
				{
					speed: (distance: number): number => {
						return 1 / ProportionalClamp(distance, 0.25, 0.5, 10000, 5500);
					},
					position: {
						relativeToName: VisualControl.CameraPositionAnimName,
						percent: 0
					}
				},
				(data: AnimationEntry.AnimationUpdateData): boolean => {
					if (data.duration === 0) {
						// Cancel rotation animation
						return false;
					}

					// Duration
					const durationMin = 0;
					const durationMax = 3000;
					const positionDuration = data.timelineContext.duration;
					if (positionDuration  > 200 && data.duration > 0) {
						const positionHalfDuration = positionDuration * 0.5;
						// If short, make same
						if (positionDuration <= 1200) {
							data.duration = positionDuration;
						}
						// min
						else if (data.duration < positionHalfDuration) {
							data.duration = positionHalfDuration;
						}
						// If durations are close or rotation duration is longer than position duration, make them the same
						else if (data.duration / positionDuration >= 0.80) {
							data.duration = positionDuration;
						}
						else {
							data.duration = Clamp(data.duration, durationMin, durationMax);
						}
					} else {
						data.duration = Clamp(data.duration, durationMin, durationMax);
					}

					// Set position mode
					data.position.mode = data.duration < positionDuration
						? AnimationEntry.PositionMode.endOrigin : AnimationEntry.PositionMode.startOrigin;
						// TODO if going form a section out to a world, we should maybe use "startOrigin" always

					// Set easing
					data.easing = data.duration <= positionDuration
						? AnimationEasing.InOut : AnimationEasing.Out;
					
					return true;
				}
			)
		));
		
	}


	static getTargetCamera(node: Structure.Node, createCameraIfNecessary: boolean = false, debug: boolean = false): BABYLON.TargetCamera {
		let targetCamera: BABYLON.TargetCamera = null;

		let scene = node.mesh.getScene();

		// Camera by '.camera' name
		const namedCamera = node.name + '.camera';
		targetCamera = scene.getCameraByName(namedCamera) as BABYLON.TargetCamera;
		if (debug)
			console.group('Looked up camera for, first trying by name ', node.name + '.camera', targetCamera);

		// By child camera
		if (targetCamera === null && node.mesh !== undefined) {
			let cameras: BABYLON.Node[] = node.mesh.getChildren(node => node instanceof BABYLON.TargetCamera);
			if (cameras.length > 0) {
				targetCamera = cameras[0] as BABYLON.TargetCamera;
			}
			if (debug)
				console.log('cameras: ', cameras.length, node.mesh.name, targetCamera);
		}

		// By parent camera if node is a partial view (aka modal)
		if (targetCamera === null) {
			// If partial view then fallback to camera of parent
			let view: ViewElement = node.getView();
			if (node instanceof Structure.Link || (view !== null && view.isPartial())) {
				if (debug)
					console.log('Looking to parent camera:');
				targetCamera = VisualControl.getTargetCamera(node.parent, false, debug);
				
				/*
				// TODO: Instead of using parent camera, we want to determine a location relative to it
				//			 that brings node.mesh into some defined viewable area
				if (node.mesh !== null && node.mesh !== undefined) {
					// TODO: Instead of using the camera's frustum we should have a method that provides us
					// 		viewable area. It takes into account what the modal is covering and potentially if
					//		the local nav is open or not
					if (targetCamera !== undefined) {
						let inView: boolean = targetCamera.isInFrustum(node.mesh);
						console.log('	In view?', inView, targetCamera.getScene().activeCamera.isCompletelyInFrustum(node.mesh), node.name);
					}
				}
				*/
			}
		}

		// Create a camera
		if (targetCamera === null && createCameraIfNecessary) {
			// let meshPosition = node.mesh.getAbsolutePosition();
			// let cameraPosition = meshPosition.add(new BABYLON.Vector3(-.3, .3, -.3));

			// targetCamera = new BABYLON.TargetCamera(namedCamera, cameraPosition, scene);
			// targetCamera.setTarget(meshPosition);

			// TODO: do we want to leave creating cameras??
			console.error('Created a camera for', namedCamera);
		}

		if (targetCamera === null)
			targetCamera = undefined;

		if (debug)
			console.log('Camera ended with:', targetCamera);

		if (debug)
			console.groupEnd();

		return targetCamera;
	}

}